// collection of all views displaying this apps header.
YUI.add('header-views', function (Y) {
    "use strict";

    Y.HeaderStandardPageView = Y.Base.create('HeaderStandardPageView', Y.HandlebarsView, [], {

        // Provide a template that will be used to render the view. The template can
        // be anything we want.
        template: 'header-standard',
        partials : ['header-year'],

        // handle dom events on this header
        events : {
            '#ui-year-selector' : {change:function(e){
                e.halt();
                this.fire('askToLeave');
            }},
            '#ui-app-toggle-debug' : {click:function(e){
                e.halt();
                this.fire('openDebug');
            }},
			'#ui-app-toggle-debug-js' : {click:function(e){
				e.halt();
				if(window.location.href.indexOf('smst_debug') > 0) {
					window.location.href = window.location.href.replace(/\?smst_debug=true/,'');
				}
				else {
					window.location.href = window.location.href.replace(/app#/,'app?smst_debug=true#');
				}
			}},
            '#ui-app-broadcast' : {click:function(e){
                e.halt();
                if( confirm("Bis du sicher, dass die Update-Warnung angezeigt werden soll?") ) {
	                Y.io('api/broadcast', {
		                method: 'POST',
		                headers:{
			                'Accept'      : 'application/json',
			                'Content-Type': 'application/json'
		                },
		                data: Y.JSON.stringify({message:"Lieber Kunde, vielen Dank für die Nutzung unserer Anwendung, "+
		                "die wir kontinuierlich weiterentwickeln und verbessern. "+
		                "Bitte melden Sie sich jetzt für ein Update ab. "+
		                "Haben Sie noch kein Benutzerkonto, legen Sie bitte unverbindlich ein Benutzerkonto an."})
	                });
                }
            }},
            '#ui-app-toggle-case-reset' : {click:function(e){
                e.halt();
                Y.io('elster/reset', {on : {success : function() {
                	//this.fire('open', {link:Y.smst.CaseEditor.SECTIONS.getByName('filing2-process').get('link')});
					window.location.reload();
                }}, context: this});
            }},
            '#ui-app-toggle-add-credit' : {click:function(e){
                e.halt();
				Y._currentState.maySeeDecreeData = true;
                Y.io('elster/addCredit', {on : {success : function() {
                	//this.fire('open', {link:Y.smst.CaseEditor.SECTIONS.getByName('filing2-process').get('link')});
                    window.location.reload();
                }}, context: this});
            }},
            '#ui-app-toggle-remove-credit' : {click:function(e){
                e.halt();
				Y._currentState.maySeeDecreeData = false;
                Y.io('elster/removeCredit', {on : {success : function() {
                	//this.fire('open', {link:Y.smst.CaseEditor.SECTIONS.getByName('filing2-process').get('link')});
                    window.location.reload();
                }}, context: this});
            }},
            '#ui-app-trigger-vast' : {click:function(e){
                e.halt();

                var tcs = "";

                for(var k in Y._config.vastCaseMapping) {
                	tcs += k+") "+Y._config.vastCaseMapping[k]+"\n";
				}

                var scenario = prompt(
                	"Bitte Szenario wählen:\n\n"+
					"--------------------TESTCASES------------------\n\n"+
					tcs+
					"\n"+
                	"--------------------LEGACY------------------\n\n"+
                    "1) RUE, RIE, Pers1+2, LStB\n\n"+
                    "3) KRV\n\n"+
                    "5) Sozialversicherungspflichtiger Arbeitnehmer mit 6 Lohnsteuerbescheinigungen (Stpfl)\n\n"+
                    "5a) Sozialversicherungspflichtiger Arbeitnehmer mit 6 Lohnsteuerbescheinigungen (Ehefrau)\n\n"+
                    "5b) Sozialversicherungspflichtiger Arbeitnehmer mit 6 Lohnsteuerbescheinigungen (Beide)\n\n"+
                    "7) Empfänger Versorgungsbezüge (inkl. einmaliger Versorgungsbezug) Rente aus einer Basisrentenversicherung\n\n"+
                    "10) Einfache Beispiel-LSK (default)\n\n"+
                    "11) Pensionsfonds / Versorgungsbezüge\n\n"+
                    "13) Beamter+Beamtin (Sz. 14) (+KRV+RIE bei 13)\n\n"+
                    "16) RBM\n\n"+
                    "17) RBM m. bes. Zuordnung\n\n"+
                    "18) Testfälle LStB zur Prüfung besonderer Zuordnungen/Validierungsbedingungen\n\n"
				);

                Y.io('interview/vast/importVast?scenario='+scenario, {on : {success : function(id, o) {

                    this.fire('alert', {
                        msg:('<div style="font-size: 10pt; overflow:auto; height:600px; margin-bottom: 20px;"><pre>'+o.responseText+'</pre></div>'+
							'<input type="button" onclick="window.location.reload();" value="Seite neu laden" style="color:red; background-color: beige; padding: 5px; margin-right: 10px;"/>'
                		)
                    });

                    Y.one('.yui3-overlay').setStyle('width', '1080px');

                }}, context: this});
            }}
        },

        initializer : function(){
            Y.publish('openDebug');


            var runningInNativeApp = (window.nativeBridge && window.nativeBridge.isReady());
            if(Y.one('#ui-is-mobile').get('value') == 'true' && !runningInNativeApp) {
                this.template = Y.HandlebarsTemplates.lookup('header-standard_mobile');
            }
        }


    }, {
        // Specify attributes and static properties for your View here.
        ATTRS: {

            // Override the default container attribute.
            container: {
                valueFn: function() {
                    return Y.Node.create('<div id="app-header" class="l-banner" />');
                }
			}
        }
    });

    Y.HeaderAnonymousInlineRegPageView = Y.Base.create('HeaderAnonymousInlineRegPageView',
		    Y.HandlebarsView, [Y.AppOverlays], {

        template: 'header-anonymous',
		partials : ['header-year'],

	    events : {
            '#ui-year-selector' : {change:function(e){
                e.halt();
                this.fire('askToLeave');
            }},
		    '.ui-close-registration' : {click: '_hideRegistration'}
	    },

		initializer : function() {
            var runningInNativeApp = (window.nativeBridge && window.nativeBridge.isReady());
			if(Y.one('#ui-is-mobile').get('value') == 'true' && !runningInNativeApp) {
                this.template = Y.HandlebarsTemplates.lookup('header-anonymous_mobile');
			}
		},

	    _swapHeaders: function (headerId1, headerId2, callback) {
		    var container = this.get('container'),
				    header1 = container.one(headerId1),
				    header2 = container.one(headerId2);

		    var data = {'isMobile' : Y.one('#ui-is-mobile').get('value') == 'true'};

			container.one('#ui-app-header-inline-reg-form-container').setHTML(Y.HandlebarsTemplates.lookup('header-_registration')(data));

		    var duration = 0.8;
		    container.transition({
					    duration: duration,
					    maxHeight: 0,
					    easing: 'ease-out'
				    }, function () {
					    header1.addClass('ui-display-none');
					    header2.removeClass('ui-display-none');
					    // we only have to remove the style and with that the current transition settings from the
					    // element and it will automatically animate back to the max-height value which is defined
					    // by the class off the container.
					    container.removeAttribute('style');

						if(Y.one('#ui-is-mobile').get('value') == 'true') {
							Y.one('#app-header').setStyle(
								'position',
								headerId2 == '#ui-app-header-inline-reg-default' ? 'sticky' : 'relative'
							);
						}
					    callback && callback();
				    }
		    );
	    },

	    _hideForm: function (e, formId) {
		    e.halt();
		    Y.one('body').removeClass('is-open-inline-registration');
		    this._swapHeaders(formId, '#ui-app-header-inline-reg-default');
	    },
	    _hideRegistration: function(e) {
		    this._hideForm(e, '#ui-app-header-inline-reg-form-container');
	    },

	    _showForm: function(formId) {

			function _scrollTop( callback ) {

				var anim = new Y.Anim({
					duration: 0.5,
					node: 'win',
					easing: 'easeBoth',
					to: {
						scroll: [0, 0]
					}
				});
				anim.on('end', callback);
				anim.run();
			}

			function _showForm(callback) {
				Y.one('body').addClass('is-open-inline-registration');
				this._swapHeaders('#ui-app-header-inline-reg-default', formId, callback);
			}

			function _focusFirst() {
				if(Y.UA.touchEnabled <= 0) {
					var firstField = Y.one('#ui-reg-email');
					if(firstField) {
						firstField.focus();
					}
				}
			}

		    // before we swap the header we might have to scroll up first.
		    var callback = Y.bind(_showForm, this, _focusFirst);
		    if( 20 < window.scrollY ) {
			    _scrollTop(callback);
		    } else {
			    callback();
		    }

		},
	    showRegistration: function() {
		    this._showForm('#ui-app-header-inline-reg-form-container');
	    }

    }, {
        ATTRS: {
            container: {
                valueFn: function() {
                    return Y.Node.create('<div id="app-header" class="l-banner" />');
                }
            }
        }
    });

}, '1.0.0', {
    requires:[
	    'anim',
	    'app-overlays',
	    'base-build',
	    'handlebars-view',
	    'transition',
		'smart-handlebars-loader'
    ]
});
