// This is a ES2015 script which get browserified and bundled into the main smst-min.js file

// keep it simple for now (use es5)
var isBrowser=new Function("try {return this===window;}catch(e){ return false;}");
var SST;

if( isBrowser ) {
	SST = window.SST || {};
	window.SST = SST;
} else {
	console.log("WARNING: not running in a browser - we won't create a global namespace!")
}

module.exports = SST;