YUI.add('spreadsheet', function (Y, NAME) {

 "use strict";

	var Spreadsheet,
		COL_OPERATIONS  = "col-operations",
		MAIN_RESULT_KEY = 'main-result-key',

		isString = Y.Lang.isString,
		isNumber = Y.Lang.isNumber,
		isObject = Y.Lang.isObject,
		toArray  = Y.Array,

		FORMAT_INT      = new Y.interview.FormatN(),
		FORMAT_FLOAT    = new Y.interview.FormatOD(),
		FORMAT_DATE     = new Y.interview.FormatD(),
		FORMAT_CURRENCY = new Y.interview.FormatCUR(),
		FORMAT_EMPTY    = new Y.interview.EmptyFormat();

// --------------------------------------------------------------------------------------------------------------------
// -----   spreadsheet widget   ---------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------------------


	Spreadsheet = Y.Base.create('spreadsheet', Y.DataTable.Base,
			[ Y.DataTable.Mutable, Y.DataTable.ColumnWidths,
			Y.spreadsheet.Editor, Y.spreadsheet.Help, Y.spreadsheet.SpreadsheetScroll, Y.DataTable.Paginator], {

		_handlerCache : [],

		_editable: false,

		/**
		 * This will indicate if this spreadsheet is in the process of loading additional data. This is needed if some
		 * columns use lists as their potential values.
		 */
		_loadingData: 0,

		// cols: array of col keys to use
		// o: the official formatter arguments (see http://yuilibrary.com/yui/docs/datatable/#formatter-props)
		_rowOps: {
			plus: function(cols,o){
				var result,
					data = o.data,
					i = 1;
				result = data[cols[0]];
				for( ; i<cols.length; i++){
					result += data[cols[i]];
				}
				return result;
			},
			minus: function(cols,o){
				var result,
					data = o.data,
					i = 1;
				result = data[cols[0]];
				for( ; i<cols.length; i++){
					result -= data[cols[i]];
				}
				return result;
			},
			mal: function(cols,o){
				var result,
					data = o.data,
					i = 1;
				result = data[cols[0]];
				for( ; i<cols.length; i++){
					result *= data[cols[i]];  // TODO: transform data.value into a number. (?)
				}
				return result;
			},
			geteilt: function(cols,o){
				var result,
					data = o.data,
					i = 1;
				result = data[cols[0]];
				for( ; i<cols.length; i++){
					result /= data[cols[i]];
				}
				return result;
			},
			prozent: function(cols, factor,o){
				var data = o.data,
					result = data[cols[0]] * factor / 100;
				return result;
			}
		},

		_colOps : {
			sum : function(values, format) {
				var i,
					j=values.length,
					sum = 0,
					value;

				for(i = 0; i<j; i++ ) {
					value = values[i];
					if( format && format.parse ) {
						value = format.parse(value);
					}
					sum += value;
				}
				return sum;
			}
		},

		// -----   initializing   -------------------------------------------------------------------------------------

		getClassName: function () {
			var host = this.host,
					NAME = (host && host.constructor.NAME) ||
							this.constructor.NAME;

			if (host && host.getClassName) {
				return host.getClassName.apply(host, arguments);
			} else {
				return Y.ClassNameManager.getClassName
						.apply(Y.ClassNameManager,
								[NAME].concat(toArray(arguments, 0, true)));
			}
		},

		/**
		 * Overwritten widget method to control the timing of rendering.
		 *
		 * @returns {boolean}
		 * @private
		 */
		_defRenderFn: function() {
			var that = this;

			if(0 !== that._loadingData) {
				Y.later(30,that,that._defRenderFn, arguments);
			} else {
                Y.later(100,that,function() {Spreadsheet.superclass._defRenderFn.apply(that, arguments)}, arguments);

			}
		},


		/**
		 * In case there are columns with a listId as datasource configured, we will load those lists here.
		 *
		 * @param coldefs
		 * @private
		 */
		_initListData: function(coldefs) {
			var that = this,
				i, coldef, countDown;

			countDown = Y.bind(function(){
				this._loadingData--;
			}, that);

			for (i = 0; i < coldefs.length; i++) {
				coldef = coldefs[i];

				if(coldef.listId) {
					coldef.listConfig = new Y.ChoiceField.Config({id:coldef.listId});
					that._loadingData++;
					coldef.listConfig.load(countDown);
				}
			}
			return 0 !== that._loadingData;
		},


		initializer : function() {
			var that = this,
				coldefs = that.get('coldefs'),
				colops;

			if (undefined === coldefs) {
				throw new Error("Spreadsheet configuration error: At least one column definition has to be set.");
			}

			// here we have to pre process the coldefs configuration parameter to bring it into a yui-understandable
			// form and to add some magic for our spreadsheet
			coldefs = that._processColDefs(coldefs);

			// If there are column operations configured, we have to add a footer view to host their results.
			colops = that.get(COL_OPERATIONS);
			if(colops && 0 < colops.length) {
				that.set('footerView', Y.spreadsheet.FooterView);
			}

			// now we will set the parents columnset
			// we have to double this here as setting columns in the next line will only store half of the data
			// which we need for our job. (see datatable-core._setColumns)
			that.set('coldefs', coldefs );
			that.set('columns', coldefs );


			// ATTENTION! we have to load the data after the columns were set as attribute to prevent a circular
			// ref err.
			// If some of the columns use lists as data provider the lists have to be loaded now. If this is the
			// case we need to throttle the rendering until every list was loaded.
			that._initListData(coldefs);

			Y._currentSpreadSheet = this;
		},

		// -----   rendering   ----------------------------------------------------------------------------------------

		renderUI : function() {
			var that = this;
			Spreadsheet.superclass.renderUI.apply(that,arguments);

			// we will set the yui-skin-sam class here to get some of the default yui behaviour running.
			// CAUTION: removing this line will break the appearance (see ONSE-9749)
			that.get('boundingBox').addClass('yui3-skin-sam');

			if(Y.one('.yui3-datatable-paginator-control-first')) {

				Y.all('.yui3-datatable-paginator-control').addClass('btn btn-s');
				Y.one('.yui3-datatable-paginator-control-first')
					.set('innerHTML', '<i class="fa fa-fast-backward"></i><span class="app-a11y-label">Erste Seite</span>')
					.setAttribute('title', 'Erste Seite');
				Y.one('.yui3-datatable-paginator-control-prev')
					.set('innerHTML', '<i class="fa fa-backward"></i><span class="app-a11y-label">Vorherige Seite</span>')
					.setAttribute('title', 'Vorherige Seite');
				Y.one('.yui3-datatable-paginator-control-next')
					.set('innerHTML', '<i class="fa fa-forward"></i><span class="app-a11y-label">Nächste Seite</span>')
					.setAttribute('title', 'Nächste Seite');
				Y.one('.yui3-datatable-paginator-control-last')
					.set('innerHTML', '<i class="fa fa-fast-forward"></i><span class="app-a11y-label">Letzte Seite</span>')
					.setAttribute('title', 'Letzte Seite');

				Y.one('.yui3-datatable-paginator').one('form').one('label').get('firstChild')
					.set('textContent', 'Seite');
				Y.one('.yui3-datatable-paginator').one('form').one('label').get('lastChild')
					.set('innerHTML', '<i class="fa fa-play"></i><span class="app-a11y-label">Gehe zu Seite</span>')
					.setAttribute('title', 'Gehe zu Seite')
					.addClass('btn btn-s');

				Y.one('.yui3-datatable-paginator-per-page').one('label').get('firstChild')
					.set('textContent', 'Zeilen: ');
				Y.one('.yui3-datatable-paginator').one('select').get('lastChild')
					.set('textContent', 'Alle anzeigen');
		   }
		},

		// -----   binding   ------------------------------------------------------------------------------------------

		_removeRow : function(e) {
			var that = this,
				clientId = e.target.ancestor('tr').getData('yui3-record');

			that.fire('confirmDeleteRow', {
				callback : function(ok) {
					if( ok ) {

						//ONSE-9768 exception removing last entry occurs here
						try {
							that.removeRow(clientId);
						}
						catch(ex) {

						}

						//ONSE-10532 don't recalculate here because it will be opened again anyway.
						Y._spreadsheetIsReloading = true;

						Y._currentSpreadsheetOverlay.set('ok', true);
						Y._currentSpreadsheetOverlay.hide();

						that.fire('openSheet', {forceEvent : Y._currentSpreadsheetEvent});

						Y._spreadsheetIsReloading = false;

					}
				}
			});

		},

		bindUI : function() {
			var that = this;
			Spreadsheet.superclass.bindUI.apply(that,arguments);

			if( this._editable ) {
				that._handlerCache.push(
					that.get('boundingBox').delegate('click', that._removeRow, '.ui-spreadsheet-delete-row', that ));
			}
		},


		// -----   public API   ---------------------------------------------------------------------------------------

		getMainResult: function(format) {
			var key = this.get("main-result-key"),
				val;

			// main-results are optional
			if( key ) {
				val = this.get(key);
				if( format ) {
					val = format.prettify(val);
				}
			}

			return val;
		},

		getResult: function(colKey, opKey, format) {
			var key = this._getResultKey(colKey,opKey),
				val = this.get(key);

			if( format ) {
				val = format.prettify(val);
			}
			return val;
		},

		isEmpty : function() {
			return !this.data || 0 === this.data.size();
		},

		// -----   destruction   --------------------------------------------------------------------------------------

		destructor: function() {
			Y.Array.each( this._handlerCache, function(it) {it.detach();});
		},

		// -----   attributes   ---------------------------------------------------------------------------------------

		_getResultKey:function() {
			var array = Y.Array(arguments);
			return 'results.'+array.join('.');
		},

		_parseColOperations: function(col) {
			// does this column has some operations defined?
			if( Y.Object.hasKey(col, 'colOps')) {
				var opts = col.colOps,
					allColOpts = this.get(COL_OPERATIONS),
					resultKey = 'results.'+col.key;

				Y.Array.each(opts, function(colOp){
					// add the key of this column.
					colOp.key = col.key;
					// add the format as well
					colOp.format = col.format;
					// add the actual function to this operation
					colOp.fkt = this._colOps[colOp.op];
					// and add it to the list of all colOpts of this spreadsheet.
					allColOpts.push(colOp);
					// check if this is the main result of this sheet.
					if( colOp.mainResult ) {
						this.set(MAIN_RESULT_KEY, colOp.key + '.' + colOp.op);
					}
				}, this);

				// prepare a namespace for the results of this column
				if( !this.get(resultKey)) {
					this.set(resultKey, {});
				}

				this.set(COL_OPERATIONS, allColOpts);
			}
		},

		_addCountColumn:function ( coldefs ) {
			var first = this.get('countColumn');

			if ( first && null !== first) {
				coldefs = [first].concat(coldefs);
			}
			return coldefs;
		},

		_addActionColumn:function (coldefs) {
			var last = this.get('actionColumn'),
				ro = this.get('readOnly');

			if (!ro && last ) {
				coldefs.push(last);
			}
			return coldefs;
		},

		/**
		 * Creates a function to format a cells data.
		 *
		 * @param o Information about the data, the row and the table (see yui3 doc about formatter functions for more
		 *          information)
		 * @param col smart.js column configuration with additional information.
		 * @private
		 */
		_createFormatter : function(o, col) {
			var that = this,
				result = o.value,
				width = col.width,
				args, fkt, config, factor, parsed;

			// here we are going to calculate the value of this cell.
			if( col.fkt) {
				args = [];
				fkt = col.fkt;
				args.push(fkt.keys);

				if( fkt.att ) {
					args.push(fkt.att);
				}
				args.push(o);

				result = that._rowOps[fkt.op].apply(that, args);
			}

			// Calculation is over. Now we will round the result.
			// If not otherwise declared columns formated as currency will get rounded automagically.
			if( col.format === FORMAT_CURRENCY && !col.rounding ) {
				col.rounding = {places:2};
			}
			if( col.rounding ) {
				config = col.rounding;
				if(isObject(config)) {
					factor = config.places ? Math.max(config.places,0) : 0;
					factor = Math.pow(10, factor);
					result *= factor;
					switch(config.type) {
						case 'up' :
							result = Math.ceil(result);
							break;
						case 'down' :
							result = Math.floor(result);
							break;
						default:
							result = Math.round(result);
					}
					result /=factor;
				} else {
					result = Math.round(result);
				}
			}

			if( col.fkt) {
				// We have to store the raw result in the dataset of this spreadsheet.
				// This has to be done before the value is formatted and set into the cell.
				// This addition has to be done silently. Otherwise the table would be re-rendered again
				// which would be to often and early.
				o.record.set(col.key,result, {silent:true});
			}

			// format the output
			if( col.format ) {

				// when the data is brought in from the server - everything will be a string.
				// this special case is handled here.
				//ONSE-7177, ONSE-8035
				if(typeof result === 'string' && !col.enforceStringFormat) {

					// first we have to check if this is a date.
					parsed = new Date(result);

					if(!Y.Lang.isDate(parsed)) {
						// if it was not a date - try as float.
						parsed = parseFloat(result);
						if(!isNaN(parsed)) {
							result = parsed;
						}
					}
				}

				result = col.format.parse(result);
				result = col.format.prettify(result);
			}

			this._createDataCell(result, width, o);
		},

		_createDataCell: function (result, width, o) {
			// make it save!
			result = Y.Escape.html(result);
			// the result has to be wrapped in an div which will prevent the data to expand the table cell.
			if (width) {
				if (isNumber(width)) {
					width += 'px';
				}
				result = '<div class="notranslate case-interview-spreadsheet-data-cell"' +
						'style="width:' + width + ' ">' + result + '</div>';
			}
			// we are setting o.value directly so we _must not_ return anything!
			o.value = result;
		},

		/**
		 * This is our fallback formatter for everything that would have gone without one otherwise.
		 *
		 * @param o
		 * @param col
		 * @private
		 */
		_defaultFormatter : function(o,col) {
			var  result = o.value,
					width = col.width;

			this._createDataCell(result, width, o);
		},

		/**
		 * This will remove all hidden columns.
		 *
		 * @param coldefs
		 * @private
		 */
		_removeHiddenColumns: function(coldefs) {
			var visibleCols = [],
					i, coldef,
					isUndefined = Y.Lang.isUndefined;

			for (i = 0; i < coldefs.length; i++) {
				coldef = coldefs[i];
				if( isUndefined(coldef.hidden) || false === coldef.hidden ) {
					visibleCols.push(coldef);
				}
			}
			return visibleCols;
		},

		_processColDefs : function(coldefs) {
			var that = this,
				col,
				// check if we are in the global "readonly" mode.
				ro = that.get('readOnly'),
				i,type, config;

			coldefs = that._removeHiddenColumns(coldefs);

			function createListDisplayValueFormatter() {
				return function (o) {
					var listConfig = this._colDefsHash[o.column.key].listConfig;
					return listConfig.getDisplayValue(o.value);
				};
			}

			for (i = 0; i < coldefs.length; i++) {
				col = coldefs[i];
				col.className = '';
				// we need to wrap every data value in a div to prevent the table to expand to much.
				col.allowHTML = true;
				// mark this spreadsheet as editable - if this col has an appropriate key
				// and the global readonly switch is turned off.
				if( !ro ) {
					this._editable = this._editable || col.editable;
				}

				if( !ro && col.editable && col.mandatory ) {
					col.className += that.getClassName('mandatory');
				}

				// check for any row operation for this cell
				if( col.fkt || col.rounding || col.format ) {

					// configure the format of this column.
					if( col.format ) {

						if(isString(col.format)) {
							type = col.format;
						} else {
							type = col.format.type;
							config = col.format.config;
							if(config) {
								config = {prettifyerConfig:config};
							}
						}

						switch(type) {
							case 'int' :
								col.format = config ? Y.mix(Y.clone(FORMAT_INT), config, true) : FORMAT_INT;
								break;
							case 'float':
								col.format = config ? Y.mix(Y.clone(FORMAT_FLOAT), config, true) : FORMAT_FLOAT;
								break;
							case 'date' :
								col.format = config ? Y.mix(Y.clone(FORMAT_DATE), config, true) : FORMAT_DATE;
								break;
							case 'currency' :
								col.format = FORMAT_CURRENCY;
								break;
						}
					} else {
						// funktions and rounding only work with numbers. So we have to set a default format if non
						// was defined.
						col.format = FORMAT_FLOAT;
					}
					// add the css-class of the used format to the classnames of this column
					col.className += ' ' + col.format.className;


					// create the formater function
					col.formatter = Y.rbind( that._createFormatter, that, col);
				}

				// here we will check if this column has a list configured as data source.
				// If so, we have to create another formatter to get the display value for each entry.
				if(col.listId) {
					col.formatter = createListDisplayValueFormatter();
				}

				// if there still is no format set, we will set an empty one.
				if( !col.format ) {

                    col.format = FORMAT_EMPTY;

					if( !col.formatter) {
						col.formatter = Y.rbind( that._defaultFormatter, that, col);
					}
				}

				// look for column operations in this column.
				that._parseColOperations(col);
			}


			coldefs = that._addCountColumn(coldefs);

			coldefs = that._addActionColumn(coldefs);

			return coldefs;
		}


	}, {

		ATTRS : {
			/**
			 * @attribute coldefs
			 * @description Definitions of the rendered columns. The definitions are objects stored in an array. The
			 *              object has two required and additional optional keys:
			 *              <dl>
			 *                  <dt><strong>key</strong>(required)</dt>
			 *                  <dd><The key is needed for all other functions as a reference to this column.</dd>
			 *                  <dt><strong>label</strong>(required)</dt>
			 *                  <dd>A string which is used as column heading.</dd>
			 *                  <dt>sortable</dt>
			 *                  <dd>true or false - indicates if the table should be sortable by this column</dd>
			 *                  <dt>editable</dt>
			 *                  <dd>true or false - indicates if this column can be edited.</dd>
			 *                  <dt>field-info</dt>
			 *                  <dd>Object which contains information needed for editing and validating values</dd>
			 *                  <dt>...</dt>
			 *              </dl>
			 * @type Array
			 */
			coldefs : {
				/**
				 * When setting the coldefs we will create a hash table to have a fast and easy access to each entry
				 * later on.
				 */
				setter : function(val) {
					var hash = {},
						colDef, i;
					if(val) {
						for(i=0;i < val.length;i++) {
							colDef = val[i];
							hash[colDef.key] = colDef;
						}
					}
					this._colDefsHash = hash;
					return val;
				}
			},
			'col-operations' : {
				value : [],
				validator : Y.Lang.isArray
			},
			'caption' : {
				setter: function(val) {
					if(!val) {
						return "";
					}

					return Y.smst.Utils.textAreaEscape(val);
				}
			},
			'headline' : { },
			'results' : {
				value : {}
			},
			'main-result-key' : {
				setter : function(val) {
					return 'results.'+val;
				}
			},
			'recalculate' : {
				value: false,
				validator: Y.Lang.isBoolean
			},
			// This will automatically count the rows. When set to a "falsy" value this column will not be rendered
			countColumn : {
				value : {
					key:"_a",
					label:"Nr.",
					width: '50px',
					sortable:true,
					formatter : function(o) {
						// the o.rowIndex can't be used here as it get mixed up when editing a row.
						// to fix this we need to read the current index of the current record.
						var idx = this.data.indexOf(o.record)+1;
						o.value = idx + '.';
						o.className += ' ' + FORMAT_INT.className;
					}
				}
			},

			rowActionTemplate : {
				value : '<button type="button" title="Diese Zeile entfernen" ' +
						'class="btn btn-s btn-destructive ui-spreadsheet-delete-row">' +
						'<i class="fa fa-trash-o"></i><span class="app-a11y-label">Entfernen</span></button>'
			},

			// This will add a delete button to the end of each row.
			// When set to a falsy value this column will not be rendered
			actionColumn : {
				value : {
					key:"_z",
					label:" ",
					width: '100px',
					allowHTML: true,
					formatter : function() {
						var template = this.get('rowActionTemplate');
						return Y.Lang.sub( template );
					},
					className:'case-interview-spreadsheet-actions',
					help:"<strong>Hinzuf\u00FCgen oder L\u00F6schen</strong><br>Mit Klick auf die entsprechende" +
							" Schaltfl\u00E4che k\u00F6nnen Sie eine Zeile zu dieser Tabelle hinzuf\u00FCgen oder eine Zeile " +
							"l\u00F6schen."
				}
			},
			readOnly : {
				value : false,
				validator : Y.Lang.isBoolean
			}
		}

	});

	Y.namespace('spreadsheet').Spreadsheet = Spreadsheet;


}, '1.0.0', {
	"requires": [
		"datatable-base",
		"datatable-column-widths",
		"datatable-mutable",
		"datatable-scroll",
		"datatable-paginator",
		"datatype-date",
		"datatype-number",
		"smart-formats",
		"node",
		"recordset-base",
		"smart-form-choice-config",
		"spreadsheet-editor",
		"spreadsheet-footer",
		"spreadsheet-help",
		"spreadsheet-scroll",
		"smst-utils"
	]
});
