YUI.add('registration', function(Y) {

	"use strict";

	// handy constants and shortcuts used in the module 
	var proto, statics,
		NAME = 'Registration';


	// Static members here, specially:
	statics = {
		NAME: NAME,
		ATTRS: {

		}
	};

	proto = {
		
		handler: [],
		_confirmationTemplate: null,

		initializer: function() {
            var instance = this;

			// reg form handling
			var formId = '#ui-app-header-inline-reg-form-container';
			instance.handler.push(Y.delegate('click', instance.register, formId, '#ui-reg-button', instance));
            instance.handler.push(Y.delegate('change', instance.togglePasswordVisibility, formId, '#ui-reg-show-password', instance));
            instance.handler.push(Y.delegate('change', instance._validateAGB, formId, '#ui-reg-agree', instance));
            instance.handler.push(Y.delegate('keyup', instance.checkPassStrength, formId, '#ui-reg-password', instance));
            instance.handler.push(Y.delegate('keyup', instance.validate, formId, '#ui-reg-email', instance));

			this._confirmationTemplate = Y.HandlebarsTemplates.lookup('header-registration_confirmation');

		},

		destructor: function() {
			Y.Array.each(this.handler, function(h){h.detach()});
		},

        getParameterByName: function(name) {
            name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
            var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
                results = regex.exec(location.search);
            return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
        },


		_showConfirmation: function (redirectURL, container) {
			function jump(){
				window.location.href = redirectURL;
			}

			container.delegate('click', jump, '#ui-inline-registration-confirmation-jump', this);
			container.setHTML(this._confirmationTemplate() );
			// if the user does not jump on his own - we will gently push him
			Y.later(6 * 1000, this, jump);
		},

		register : function(e) {
            var that = this,
	            inlineRegFormContainer = Y.one('#ui-app-header-inline-reg-form');

            e.halt();

            var password = Y.one('#ui-reg-password').get('value');

            if(password.indexOf("<") >= 0 || password.indexOf(">") >= 0 || password.indexOf("\"") >= 0 ||
                password.indexOf("\'") >= 0 || password.indexOf("&") >= 0) {

                that.fire('alert', {'msg': 'Bitte vermeiden Sie die Zeichen < > " \' und &'});

                return;
            }

			// set the form in waiting mode
			inlineRegFormContainer.addClass('app-header-inline-reg-form-waiting');

			Y.io("api/registration/register",
                {
                    on: {
                        success: function(id, o) {
                            var data = JSON.parse(o.responseText),
	                            noErrors = true;

                            if(data.errorMessage ) {
	                            noErrors = false;
                                Y.one('#ui-reg-error-message').setStyle('display', 'inline');

                                var msg = data.errorMessage;

                                if(msg == 'Diese Email-Adresse existiert bereits.') {
                                    msg += ' <a href="javascript:void(null)" class="ui-open-login">Hier können Sie sich anmelden.</a>';
                                }

                                Y.one('#ui-reg-error-message').setContent(msg);
                            }

                            if(noErrors) {
                                //TODO: change for keycloak
	                            that._showConfirmation(data.redirectURL+ "/j_spring_cas_security_check", inlineRegFormContainer);
                            }
                        },
                        failure: function(id, o) {
                            alert("Es ist ein Fehler aufgetreten!");
                        },
	                    end: function() {
		                    inlineRegFormContainer.removeClass('app-header-inline-reg-form-waiting');
	                    }
                    },
                    context: that,
                    method: 'POST',
                    data: {
                        email: Y.one('#ui-reg-email').get('value'),
                        password : Y.one('#ui-reg-password').get('value'),
                        newsletter: Y.one('#ui-reg-newsletter').get('checked') ? "true" : "false",
                        myExternal: that.getParameterByName('myExternal'), //origin1, compatibility for portal reg
                        s_id: that.getParameterByName('s_id'), //origin2 compatibility for MRK-448 / financeads
                        cid: that._getCid() // see: BV-256
                    }
                }
            );
        },

        _getCid: function () {
		    // BV-256
		    if (window.ga) {
		        try {
                    return window.ga.getAll()[0].get('clientId');
                } catch(e) {
		            console.log(e);
                }
            }
        },

        validate : function() {
            var that = this;

            if(Y.one('#ui-reg-error-message').getStyle('display') == 'inline') {

                Y.io("api/registration/validate",
                    {
                        on: {
                            success: function(id, o) {
                                var data = JSON.parse(o.responseText);

                                if(data.errorMessage) {
                                    Y.one('#ui-reg-error-message').setContent(data.errorMessage);
                                }
                                else {
                                    Y.one('#ui-reg-error-message').setStyle('display', null);
                                    Y.one('#ui-reg-error-message').setContent('');
                                }
                            },
                            failure: function(id, o) {
                                alert("Es ist ein Fehler aufgetreten!");
                            }
                        },
                        context: that,
                        method: 'POST',
                        data: {
                            email: Y.one('#ui-reg-email').get('value'),
                            password : Y.one('#ui-reg-password').get('value')
                        }
                    }
                );
            }
        },

        togglePasswordVisibility : function(e) {

            if(Y.one('#ui-reg-show-password').get('checked')) {
                Y.one('#ui-reg-password').setAttribute('type', 'text');
            }
            else {
                Y.one('#ui-reg-password').setAttribute('type', 'password');
            }
        },

        scorePassword : function(pass) {
            var score = 0;
            if (!pass || pass.length < 8)
                return score;

            // award every unique letter until 5 repetitions
            var letters = new Object();
            for (var i=0; i<pass.length; i++) {
                letters[pass[i]] = (letters[pass[i]] || 0) + 1;
                score += 5.0 / letters[pass[i]];
            }

            // bonus points for mixing it up
            var variations = {
                digits: /\d/.test(pass),
                lower: /[a-z]/.test(pass),
                upper: /[A-Z]/.test(pass),
                nonWords: /\W/.test(pass)
            }

            var variationCount = 0;
            for (var check in variations) {
                variationCount += (variations[check] == true) ? 1 : 0;
            }
            score += (variationCount - 1) * 10;

            return parseInt(score);
        },

        checkPassStrength : function(e) {

            this.validate();

            var score = this.scorePassword(Y.one('#ui-reg-password').get('value')),
                node = Y.one('#ui-reg-password-strength');

            if(node.hasClass('is-active')) {
                node.removeClass('is-active')
            }
            if(node.hasClass('is-safe')) {
                node.removeClass('is-safe')
            }
            if(node.hasClass('is-unsafe')) {
                node.removeClass('is-unsafe')
            }
            if(node.hasClass('is-good')) {
                node.removeClass('is-good')
            }

            Y.log("Password score: "+score);

            if(score <= 0) {
                return;
            }

            if (score > 80) {
                node.addClass('is-safe');
                Y.one('#ui-reg-password-strength-tooltip').setContent('Sehr sicheres Passwort');
            }
            else if (score > 60) {
                node.addClass("is-good");
                Y.one('#ui-reg-password-strength-tooltip').setContent('Ausreichendes Passwort');
            }
            else if (score >= 30) {
                node.addClass("is-unsafe");
                Y.one('#ui-reg-password-strength-tooltip').setContent('Unsicheres Passwort');
            }
            else if (score < 30) {
                node.addClass("is-unsafe");
                Y.one('#ui-reg-password-strength-tooltip').setContent('Sehr unsicheres Passwort!');
            }

            node.addClass('is-active');
        }


	};

    Y.Registration = Y.Base.create(NAME, Y.Base, [], proto, statics);


}, '1.0.0', {
		requires:[
            'base-build',
            'node'
		]
	}
);

