YUI.add('smart-handlebars-templates', function (Y, NAME) {

"use strict";
function HandlebarsTemplates() {
	// empty constructor
}

Y.mix(HandlebarsTemplates.prototype, {

	_cache : {},

	_mutex : {},

	_loader : new Y.HandlebarsLoader(),

	/**
	 * This will start heavy lifting of compiling and caching a template.
	 *
	 * @param source The raw template source
	 * @param name The name under which the template will be referred to.
	 * @private
	 */
	_compile: function(source, name) {
		this._cache[name] = Y.Handlebars.compile(source);
		this._mutex[name] = undefined;
	},

	/**
	 * This will look up the template in question. The lookup sequence is as follows:
	 * 1. local cache
	 * 2. local script tag
	 * 3. load via Y.HandlebarsLoader
	 * @param name The name of the template
	 * @param isPartial Tell if this template should get registered as a partial.
	 */
	lookup: function(name, isPartial) {
		var node;

		// check if a lookup is running for this template
		function nap() {
		}
		while(this._mutex[name]) {
			Y.later(60, this, nap);
		}
		// 1. look into the cache...
		if(undefined === this._cache[name]) {
			this._mutex[name] = true;

			// 2. try to find it on this page...
			node = Y.one('#hbs-'+name);
			if( node ) {
				this._compile(node.getHTML(), name);
			}
			// 3. finally invoke the loader and get the template from the server.
			else {
				this._loader.load(name, Y.rbind(this._compile, this, name));
			}
		}
		// check if we have to register this template as a partial
		if( isPartial && !Y.Handlebars.partials[name] ) {
			Y.Handlebars.registerPartial(name,this._cache[name]);
		}
		return this._cache[name];
	},

	precompile: function( names ) {
		if( !Y.Lang.isArray(names) ) {
			Y.error('Please provide an array of template names to get those precompiled');
		}
		var queue = new Y.AsyncQueue();
		Y.Array.each( names, function(it){
			queue.add( Y.bind( this.lookup, this, it ));
		}, this);
		queue.run();
	},

	append: function( templateName, node, args ) {
		var template = this.lookup(templateName);
		node.append(args ? template(args) : template());
	}

});

Y.HandlebarsTemplates = new HandlebarsTemplates();

	// register common helpers
	Y.Handlebars.registerHelper('json', function(obj) {
		return JSON.stringify(obj, null, 2);
	});

    Y.Handlebars.registerHelper('with', function(context, options) {
        return options.fn(context);
    });

    Y.Handlebars.registerHelper('toJSON', function(object){
        return new Y.Handlebars.SafeString(JSON.stringify(object));
    });

	// remove any headlines, tables or lists
	Y.Handlebars.registerHelper('chopHtml', function(passedString, maxIndex) {
		var cleanString = passedString
				.replace(/\n/g, ' ')
				.replace(/<table[\s\S]*<\/table>/g, '@@')
				.replace(/<ul[\s\S]*<\/ul>/g, '@@')
				.replace(/<ol[\s\S]*<\/ol>/g, '@@')
				.replace(/<h\d>.*<\/h\d>/g, ' ')
				.replace(/<[^>]*>/g, ' ');
		var firstIndexOfRemovedElement = cleanString.indexOf('@@');
		var lastIndexOfSpace = cleanString.lastIndexOf(' ', maxIndex);
		var usedIndex = Math.min(firstIndexOfRemovedElement, lastIndexOfSpace);
		var toIndex = usedIndex == -1 ? maxIndex : usedIndex;
		var theString = cleanString.substring(0,toIndex);
		return new Y.Handlebars.SafeString(theString + "…")
	});


}, '1.0.0', {"requires": ["base-build", "smart-handlebars-loader", "async-queue"]});
