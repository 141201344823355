YUI.add('case-editor-app-analysis', function (Y) {
	"use strict";

	var RENDERER_URI_ID = 'reports/render.html?useSessionCase=true&id=',
		ASSESSMENT_TYPE = 'type';


	Y.CaseEditorAppAnalysis = Y.Base.create('CaseEditorAppAnalysis', Y.smst.CaseEditorAppBase,
			[Y.AppOverlays], {

		_handler:[],

		// --- navigation handling

		// this is our new 'openArea'
		handleNavigation:function (navInfo) {
			var that = this,
					cfg;

			Y.log('handleNavigation', 'DEBUG', 'CaseEditorAppAnalysis');

			that.fire('waiting', {msg:'Bitte warten…'});
			cfg = {
				on:{
					success:function (id, o) {
						that.get('container').setHTML(o.responseText);
						that.fire('removeWaiting');
						that.start();

                        if(Y.one("#case-editor-main-controls")) {
                            Y.one("#case-editor-main-controls").setStyle('display', 'none');
                        }
					},
					failure:function () {
						Y.log("Request failed or was aborted", 'warn', 'Analysis');
					}
				},
				context:that
			};

			Y.io("evaluation/home", cfg);
		},

		/**
		 * Initialisiert die Berichtsuebersicht.
		 */
		start:function () {
			var module = this;

			Y.log("Starting analysis...");

			Y.CaseEditorAppAnalysis.firstReportRendered = false;

			Y.one('#case-editor-analysis').setStyle('opacity', '0.2');
			Y.one('#case-editor-analysis').setStyle('pointer-events', 'none');

			module.renderUI();
			module.bindUI();
			module.syncUI();
		},


		renderUI:function () {
			var that = this,
				tabview, preview;

            if(!Y.smartCheckOk) {
                if(Y.hasGvErrors) {
                    Y.one('#ui-analysis-smartcheck-warning-gv').setStyle('display', 'block');

                    Y.Array.each(Y.gvErrors, function(it){
                        Y.one('#ui-analysis-smartcheck-warning-gv').one('ul').append(Y.Node.create('<li>'+it.message+'</li>'));
                    });
                }
                else {
                    Y.one('#ui-analysis-smartcheck-warning').setStyle('display', 'block');
                }
            }

			//create the tabs
			tabview = new Y.TabView({
				boundingBox:'#case-editor-analysis-report-selector'
			});
			tabview.render();
			that.set('tabView', tabview);

			// create the preview area
			preview = new Y.analysis.AnalysisPreview({
				contentBox:'#case-editor-analysis-report-preview-body',
				controlBox:'#case-editor-analysis-report-preview-header',
				boundingBox:'#case-editor-analysis-report-preview',
				onse:that.onse

			});
			preview.render();
			that.set('preview', preview);
		},

		bindUI:function () {
			var ctx = this,
					tabview = ctx.get('tabView'),
					preview = ctx.get('preview'),
					boundingBox = tabview.get('boundingBox');

			ctx._handler.push(tabview.after('selectionChange', function (e) {

				Y.log("tab select: "+Y.CaseEditorAppAnalysis.firstReportRendered+":"+Y.CaseEditorAppAnalysis.allowOneSimulatedClick);

				//this whole tabview thing was a horrible mistake... but since we've already worked around issues using simulated event we can now use temporary locks (to only use these, not clicks), too
				if(!Y.CaseEditorAppAnalysis.firstReportRendered && !Y.CaseEditorAppAnalysis.allowOneSimulatedClick) {
					return;
				}
				Y.CaseEditorAppAnalysis.allowOneSimulatedClick = false;

				var tab = e.target.get('selection');
				// if this tab has data plugin update the data
				tab.data && tab.data.update();
			}, ctx));

			// listen for clicks on reports
			ctx._handler.push(boundingBox.delegate('click', ctx._renderReport, ".case-editor-analysis-report-selector-reports-list li", ctx));
			// sub categories
			ctx._handler.push(boundingBox.delegate('click', ctx._categoryClicked, ".case-editor-analysis-report-selector-categories-list li", ctx));
			// appendix
			ctx._handler.push(boundingBox.delegate('click', ctx._appendixClicked, ".case-editor-analysis-report-selector-appendix-list li a", ctx));
		},

		syncUI:function () {
			var module = this,
					src = module.get('datasource_cat');

			src.once("response", function (e) {
				var ctx = this,
						tabview = ctx.get('tabView'),
						schema = ctx.get('schema_reports'),
						i = 0,
						data = e.response.results;

				for (; i < data.length; i++) {
					var cfg = data[i];
					tabview.add(
							new Y.analysis.CategoryTab({label:cfg.label})
									.plug(Y.analysis.CategoryTabDataPlugin, {
										datasource:src,
										catId:cfg.id,
										schema_reports:schema
									})
					);
				}
				// add the appendix tab
				tabview.add(new Y.analysis.AppendixTab());

				Y.CaseEditorAppAnalysis.allowOneSimulatedClick = true;

				tabview.selectChild(0);

			}, this);
			src.sendRequest();
		},

		destructor:function () {
			var instance = this;

			while (0 < instance._handler.length) {
				instance._handler.pop().detach();
			}

			if (null != instance.get('tabView')) {
				instance.get('tabView').destroy();
				instance.get('preview').destroy();
			}
		},

		clearField:function (name) {
			var cfg = {
				on:{
					success:function (id, o, name) {
						// ONSE-1984: check if there are any errors left:
						var fragment = Y.Node.create(o.responseText);
						// FIXME: We don't set has-errors anywhere now -- check if this has been edited out from some template HTML
						if (null == fragment.one('#fieldBox.has-errors')) {
							var box = Y.one('#content-analysis');
							box.empty(true);
							box.setContent(fragment);
							this.fire('analysis:start');
						} else {
							// remvoe the fragment - we don't need it.
							fragment.destroy();
							//delete fragment;
							// remove the table row.
							var row = Y.one('#fieldBox #row_' + name);
							if (row) {
								row.transition({
									easing:'ease-in',
									duration:0.3,
									opacity:0,
									on:{
										end:function (e) {
											var row = this;
											row.remove().destroy();
											//delete row;
										}
									}
								});

							}
						}
					}
				},
				context:this,
				arguments:name
			};
			Y.io("evaluation/home.html?clearField=" + name, cfg);
		},

		_renderReport:function (e) {
			var ctx = this,
					activeEntry = e.currentTarget,
					reportId = activeEntry.getAttribute('data-id'),
					assessmentType = activeEntry.getAttribute('data-type'),
                    sessionId = activeEntry.getAttribute('data-sessionId'),
					src = new Y.DataSource.IO({source:RENDERER_URI_ID.replace(/id=/g, "") + "use" +
							assessmentType.toUpperCase() + "=true&sessionId=" + sessionId + "&id="});


			if (e.target.hasClass('external-link')) {
				return;
			}

			// stop bubbling and default action
			e.halt();

			// If active link is clicked, no Request is necessary
			if (activeEntry.hasClass('active') || !reportId || reportId == '') {
				Y.log('active or empty entry was clicked - stopping my work.');
				return;
			}

            Y.CaseEditorAppAnalysis.firstReportRendered = true;

			Y.one('#case-editor-analysis').setStyle('opacity', '1.0');
			Y.one('#case-editor-analysis').setStyle('pointer-events', 'auto');

			ctx._updateActiveReportState(activeEntry);

			src.once('response', function (e, rid) {
				var node = Y.Node.create(e.data.responseText),
				trimmedResponse = node.one('.case-editor-report-table-container');

				this.get('preview').update(rid, trimmedResponse, assessmentType, sessionId);
			}, this, reportId);

			// set the preview in waiting mode...
			this.get('preview').wait();
			// send the request.
			src.sendRequest({ request:reportId });
		},


		_categoryClicked:function (e) {
			var ctx = this,
					catEl = e.currentTarget,
					catId = catEl.getAttribute('data-id'),
					tabview = ctx.get('tabView'),
					tab;

			Y.log("category select: "+Y.CaseEditorAppAnalysis.firstReportRendered+":"+Y.CaseEditorAppAnalysis.allowOneSimulatedClick);

			//this whole tabview thing was a horrible mistake... but since we've already worked around issues using simulated event we can now use temporary locks (to only use these, not clicks), too
			if(!Y.CaseEditorAppAnalysis.firstReportRendered && !Y.CaseEditorAppAnalysis.allowOneSimulatedClick) {
				return;
			}
			Y.CaseEditorAppAnalysis.allowOneSimulatedClick = false;

			// get the active tab as this holds its categories
			tab = tabview.get('selection');
			// trigger an update
			tab.data.update(catId);
		},


		_appendixClicked:function (e) {
			Y.log('Appendix clicked', null, 'Analysis');
			var sheetId = e.target.getAttribute('data-id'),
					sheetDefinitionId = sheetId.substring(0, sheetId.indexOf('-')),
					data = Y.UserCase.getSpreadsheet(sheetId);

			//euer sheets are stored in the usercase separately
            if(!data) {
                Y.Array.each(Y.UserCase.getEUERSpreadsheets(), function(sheet){
                    if(sheetId == sheet.id) {
                        data = sheet;
                    }
                },this);
            }

            Y.all('.case-editor-analysis-report-selector-appendix-list a').each(function(it) {
                it.removeClass('active');
            });

            e.target.addClass('active');

			var definition = Y.Spreadsheets.get('definitions').getDefinition(sheetDefinitionId, data.caption),
				sheet;

			definition.readOnly = true;

			var tmp;

			if(sheetId.indexOf("[") >= 0 && sheetId.indexOf("]") >= 0) {
				tmp = sheetId.substring(sheetId.indexOf("[")+1, sheetId.indexOf("]"));
			}

			//ONSE-11585
			Y.log("Overriding sheet mfaIndex: "+tmp);

			Y._overrideSheetMultiFieldIndex = tmp;

			sheet = Y.Spreadsheets.createSpreadsheet(definition, data.data, data);

            Y._overrideSheetMultiFieldIndex = null;

			this.get('preview').update(sheetId, sheet);
		},


		_updateActiveReportState:function (activeEntry) {
			Y.all(".case-editor-analysis-report-selector-reports-list li").removeClass('active');
			activeEntry.addClass('active');
		}
	}, {
		ATTRS:{

			container:{
				valueFn:function () {
					return Y.Node.create('<div id="case-editor-analysis-details"/>');
				}
			},
			globalContentBox:{
				value:null
			},
			printLink:{
				value:null
			},
			datasource_cat:{
				valueFn:function () {
					var ds = new Y.DataSource.IO({source:"reports/categories?"});
					ds.plug(Y.Plugin.DataSourceJSONSchema, {
						schema:{
							metaFields:{categoryId:'categoryId'},
							resultListLocator:'categories',
							resultFields:['id', 'label']
						}
					});
					return ds;
				}
			},
			datasource_render:{
				value:new Y.DataSource.IO({source:RENDERER_URI_ID})
			},
			schema_reports:{
				value:{
					metaFields:{categoryId:'categoryId'},
					resultListLocator:'reports',
					resultFields:['id', 'label', ASSESSMENT_TYPE, 'mandatory', 'sessionId']
				}
			},
			tabView:{
				value:null
			},
			preview:{
				value:null
			}

		}
	});


}, '1.0.0', {
	requires:[
		'app',
		'base-build',
		'case-editor-app-base',
		'io-base',
		'node',
		'smst-lang',
		'analysis-preview',
		'datasource-io',
		'datasource-jsonschema',
		'tabview',
		'substitute',
		'analysis-appendix-tab',
		'analysis-category-tab',
        'node-event-simulate',
		'print-preview',
		'spreadsheets',
		'spreadsheet'
	]});