"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

Object.defineProperty(exports, "__esModule", {
    value: true
});

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Result = function () {
    function Result(experimentIn) {
        _classCallCheck(this, Result);

        this._experimentIn = experimentIn || null;
        this._observations = [];
    }

    _createClass(Result, [{
        key: "observations",
        get: function get() {
            return this._observations;
        },
        set: function set(value) {
            this._observations = value;
        }
    }, {
        key: "experimentIn",
        get: function get() {
            return this._experimentIn;
        },
        set: function set(value) {
            this._experimentIn = value;
        }
    }]);

    return Result;
}();

exports.default = Result;