YUI.add('print-preview', function(Y) {

	"use strict";

	var protos, statics,
		NAME = 'PrintPreview',
		APPENDIX_URI = 'reports/appendix.html',
		REPORT_URI_TEMPLATE = 'reports/render.html?useSessionCase=true&fullScreen=true',
		WINDOW_NAME = 'printPreview',
		CONTENT_ID = '#appendix-print-preview-content',
		OPEN_APPENDIX_CLASS = '.open-print-preview-appendix',
		OPEN_REPORT_CLASS = '.open-print-preview-report',
        APPENDIX_PDF_DOWNLOAD_CLASS = '.appendix-pdf-download',
        REPORT_PDF_DOWNLOAD_CLASS = '.report-pdf-download',
        ALL_PRINTOUNTS_PDF_DOWNLOAD_CLASS = '.ui-all-printouts-pdf-download',
		ONLY_PDF_PRINTOUTS_PDF_DOWNLOAD_CLASS = '.ui-pdf-printouts-pdf-download',
		REDUCED_PRINTOUTS_PDF_DOWNLOAD_CLASS = '.ui-reduced-printouts-pdf-download';


	statics = {
		NAME: NAME,
		ATTRS: {

		}
	};

	protos = {

		_popup : null,
        config: null,

		initializer : function() {
            Y.once('smartsteuer:ready', function(config){this.config = config}, this);

			Y.delegate('click', this.openAppendix, '#app-body', OPEN_APPENDIX_CLASS, this);
			Y.delegate('click', this.openReport, '#app-body', OPEN_REPORT_CLASS, this);
            Y.delegate('click', this.pdfDownloadReport, '#app-body', REPORT_PDF_DOWNLOAD_CLASS, this);
            Y.delegate('click', this.pdfDownloadAppendix, '#app-body', APPENDIX_PDF_DOWNLOAD_CLASS, this);
            Y.delegate('click', this.pdfDownloadAll, '#app-body', ALL_PRINTOUNTS_PDF_DOWNLOAD_CLASS, this);
			Y.delegate('click', this.pdfDownloadOnly, '#app-body', ONLY_PDF_PRINTOUTS_PDF_DOWNLOAD_CLASS, this);
			Y.delegate('click', this.pdfDownloadReduced, '#app-body', REDUCED_PRINTOUTS_PDF_DOWNLOAD_CLASS, this);
		},

		openAppendix : function(e) {
			e.halt();

			// get the popup
			var that = this,
				renderFunction = Y.rbind( function(e, sheets, definitions, userCase) {
					that._popup.focus();
					YUI({win: that._popup, doc: that._popup.document}).use('node', 'spreadsheets', function(Y) {

						var that = this,
							target = Y.one(CONTENT_ID);

						// we have to overwrite this scopes userCase
						Y.UserCase = userCase;

						Y.Array.each(sheets, function(sheetData){
							var sheetId = sheetData.id,
									sheetDefinitionId = sheetId.substring(0, sheetId.indexOf('-')),
									definition = definitions.getDefinition(sheetDefinitionId, sheetData.caption),
									sheet;

							definition.readOnly = true;
							sheet = Y.Spreadsheets.createSpreadsheet(definition, sheetData.data);

							sheet.render(target);
						}, CONTENT_ID, that);

					});
				}, that,
						Y.UserCase.getSpreadsheets().concat(Y.UserCase.getEUERSpreadsheets()),
						Y.Spreadsheets.get('definitions'),
						Y.UserCase );

			that._getPopUp(this.config.secureURI(APPENDIX_URI), renderFunction);
		},

		openReport : function(e) {
			e.halt();

			var uri = REPORT_URI_TEMPLATE,
				id = e.currentTarget.getData('id'),
                assmt = e.currentTarget.getData('assessment-type'),
                sessionId = e.currentTarget.getData('sessionId');

				if(assmt != null) {
				    uri += "&use"+assmt.toUpperCase() +"=true";
			    }
			this._getPopUp( this.config.secureURI( uri , {id:id,sessionId:sessionId}) );

		},

        pdfDownloadReport : function (e) {
            e.halt();
            var id,assmt,
                uri='reports/render.html',
                assmtParam,
                sessionId = e.currentTarget.getData('sessionId');

            id = e.currentTarget.getData('id');

            var params = {
                id:id,
                sessionId:sessionId,
                deliverPdf:'true',
                useSessionCase: true,
                nocalc: true,
                fullScreen: true
            };

            assmt = e.currentTarget.getData('assessment-type');
            if (assmt != null) {
                assmtParam = 'use' + assmt.toUpperCase();
                params[assmtParam] = true;
            }

            var url = this.config.secureURI(uri,params);
            location.href = url;
        },

        pdfDownloadAppendix: function(e) {
            e.halt();
            var id,
                uri='appendixes/renderAppendix',
                params,
                url;
            id = e.currentTarget.getData('id');
            //var sheetDefinitionId = id.substring(0, id.indexOf('-'));
            params = {
                id:id
            };
            url = this.config.secureURI(uri,params);
            location.href = url;
        },

		pdfDownloadAll: function (e) {
			this.pdfDownload(e, false, false);
		},

		pdfDownloadOnly: function (e) {
			this.pdfDownload(e, false, true);
		},

		pdfDownloadReduced: function (e) {
			this.pdfDownload(e, true, false);
		},

        pdfDownload: function (e, skipElster2Pdfs, onlyElster2Pdfs) {
            e.halt();
            var downloadToken = new Date().getTime();
            var securl = this.config.secureURI("printouts/all");
            var securlWithToken = securl + "&downloadToken="+downloadToken;

			securlWithToken += "&skipElster2Pdfs="+skipElster2Pdfs;
			securlWithToken += "&onlyElster2Pdfs="+onlyElster2Pdfs;

            this.triggerDownload(securlWithToken, downloadToken);

            // some users on safari are having trouble downloading the pdf. (ONSE-9744)
            // We are unable to reproduce the bug so we will create a fallback.
            // Providing a Link as an alternative should do the trick.
            this._showAlternativeDownloadLink(securl);
        },

        // if used frequently, this should be refactored in a framework function
        // taken from:  http://stackoverflow.com/questions/1106377/detect-when-browser-receives-file-download?lq=1
        triggerDownload: function(url,downloadToken) {
            // disable the download button

            if(Y.UA.touchEnabled) {

                window.location.href = url;

            }
            else {

                Y.all('.case-editor-filing-module-waiting').removeClass('ui-display-none');
                Y.all(ALL_PRINTOUNTS_PDF_DOWNLOAD_CLASS).addClass('btn-disabled');
                Y.all(ALL_PRINTOUNTS_PDF_DOWNLOAD_CLASS).setAttribute('disabled', 'disabled');

                var iframe = Y.Node.create('<iframe>');
                iframe.set('src',url);
                iframe.setStyle('display','none');
                Y.one(document.body).append(iframe);

                // this is the alternative way to do it
                // problem compared to the iframe solution is: in case of a 500 the ui gets replaced by the 500
                //location.href = url;

                var attempts = 60;
                var downloadTimer = window.setInterval(function () {
                    var token = Y.Cookie.get("downloadToken");
                    if ((token == downloadToken) || (attempts == 0)) {
                        // either the cookie has been set (has to be done server-side on the download response)
                        // or 30 seconds are over
                        // we re-enable the download button
                        Y.all('.case-editor-filing-module-waiting').addClass('ui-display-none');
                        Y.all(ALL_PRINTOUNTS_PDF_DOWNLOAD_CLASS).removeClass('btn-disabled');
                        Y.all(ALL_PRINTOUNTS_PDF_DOWNLOAD_CLASS).removeAttribute('disabled');

                        // and clean up a little
                        window.clearInterval(downloadTimer);
                        Y.Cookie.remove('downloadToken');
                        iframe.remove();
                    }
                    attempts--;
                }, 1000);
            }
        },

        /**
         * This will display a text link beneath the download button.
         *
         * @param securl
         * @private
         */
        _showAlternativeDownloadLink: function (securl) {
	        var link = Y.one('#ui-case-editor-filing-alt-download');
	        if( link ) {
		        link.set('href', securl);
		        link.removeClass('ui-invisible');
	        }
        },


		_getPopUp : function(uri, onload) {
			// get the popup
			if( this._popup ) {
				this._popup.close();
				this._popup = null;
			}
			this._popup = window.open( uri, WINDOW_NAME,
					'width=1000, height=700, left=24, top=24, scrollbars, resizable', true);
			// check for popup blockers
			if (this._popup == null || typeof(this._popup)=='undefined') {
				alert('Bitte schalten Sie Ihren PopUp-Blocker aus und klicken den Link erneut!');
			} else {
				// do what we are here for...
				// ONSE-5433: Der IE braucht wieder eine Sonderloesung.
				this._popup[this._popup.addEventListener ? 'addEventListener' : 'attachEvent'](
						(this._popup.attachEvent ? 'on' : '') + 'load',onload, false
				);
				return this._popup;
			}
			return null;
		}
	};


	Y.PrintPreview = Y.Base.create(NAME, Y.Base, [], protos, statics);

}, '1.0.0' ,{
	requires: [
		'base-build',
		'event-base',
		'spreadsheets',
		'user-case',
        'cookie'
	]
});
