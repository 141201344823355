YUI.add('user-case', function(Y) {

	var caze, UserCase,
		FORMS        = 'forms',
		SHEETS       = 'sheets',
        NOTES       = 'notes';

// --------------------------------------------------------------------------------------------------------------------
// -----   user case   ------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------------------


    UserCase = new Y.Base.create('UserCase', Y.Base, [],{

        _sheetIdHash : null,

        initializer : function() {
            //Y.log('initializer', 'DEBUG', UserCase.NAME);

            var sheets = new Y.Recordset();
            sheets.plug(Y.Plugin.RecordsetSort);
            sheets.plug(Y.Plugin.RecordsetIndexer);
            this.set(SHEETS,sheets);
        },

        update : function(data) {

            var notes = new Y.Array([]);
            //Y.log(data);

            this.set(FORMS, data[FORMS]);

//            Y.Array.each(data[NOTES], function(it) {
//                    notes.push(new Y.konz.notes.Note(it));
//                }
//            );
//
            this.set(NOTES, notes);

            var sheets = this.get(SHEETS);
            sheets.empty();

            //ONSE-6594 encode securely
            var entries = Y.Object.values(data[SHEETS]);

            Y.Array.each(entries, function(it, key) {
                Y.Array.each(it.data, function(it2) {
                    for(key in it2) {
	                    if( it2[key] instanceof String ) {
		                    it2[key] = Y.Escape.html(it2[key]);
	                    }
                    }
                });
            });

            sheets.add( entries );
            sheets.indexer.createTable('id');
            this._sheetIdHash = sheets.indexer.get('hashTables.id');
        },


        //string zu javascript-object umwandeln
        realValue : function(value, format, noStringEscape) {
            var ints = "#A#I#G#M#N#O#U#H";
            var floats = "#F#P#POS#R#S#V#OD";

            if(format == "X") {
                if(value == 'X') return true;
                else return false;
            }
            else if(format == "Y" || format == "J") {
                if(value == "1") return true;
                else return false;
            }
            //achtung: single-letter zuerst prüfen, sonst P,O == floats
            else if(ints.indexOf("#"+format) >= 0) {

                if(format == "H" && (isNaN(value) || value == '')) {
                    return '"'+value+'"';
                }

                if(value) {
                    value = value.replace(/\./g, '');
                }
                return parseInt(value, 10);
            }
            else if(floats.indexOf("#"+format) >= 0) {
                if(value) {
                    value = value.replace(/\./g, '');
                    if(value.indexOf(",") >= 0) {
                        value = value.substring(0, value.indexOf(","));
                    }
                }
                return parseFloat(value);
            }
            else {
                if(noStringEscape) {
                    return value;
                }
                else {
                    return '"'+value+'"';
                }
            }
        },

        isInvalidNumber : function(value, format) {
            var ints = "#A#I#G#M#N#O#U";
            var floats = "#F#P#POS#R#S#V#OD";

            if(ints.indexOf("#"+format) >= 0 || floats.indexOf("#"+format) >= 0) {
                return isNaN(value);
            }

            return false;
        },

        hasFormIndex : function(form, index) {
            var forms = this.get('forms');
            for(var i=0; i<forms.length; i++) {

                if(forms[i].form == form && forms[i].idx == index) {
                    return true;
                }
            }

            return false;
        },

        hasValueSet: function(name, form, index, mfIndex) {
            var value = this.getMultiFieldValueFromCase(name, form, index, mfIndex);

            return value != null && value != "null";
        },

        searchRealValueFromCase : function(name, index, mfIndex, noStringEscape) {
            var that = this;
            var forms = that.get('forms');

            for(var i = 0; i < forms.length; i++) {
                var fixedIndex = that.fixFormIndex(forms[i].form, index);

                if(forms[i].idx == fixedIndex) {
                    for(var j = 0; j < forms[i].fields.length; j++) {
                        if (forms[i].fields[j].field == name) {

                            var result = that.realValue(that.getMultiFieldValueFromCase(forms[i].fields[j].field, forms[i].form, fixedIndex, mfIndex), forms[i].fields[j].format, noStringEscape);

                            Y.log('found value in case: '+forms[i].form+"."+forms[i].fields[j].field+"@"+fixedIndex+"["+mfIndex+"]: "+result);

                            return result;
                        }
                    }
                }
            }

            return null;
        },

        getMultiFieldValueFromCase : function(name, form, index, mfIndex) {
            var that = this;
            return that.getMultiFieldValueFromField(that.getValueFromCase(name, form, index), mfIndex);
        },

        //TODO refactor me, maybe merge into getValueFromCase
        getPotentialMultiFieldValueFromField : function(caseField, multiFieldIndex) {
            var that = this;

            if(caseField == null || caseField == undefined) {
                return null;
            }

            return Y.Array.test(caseField.value) ? that.getMultiFieldValueFromField(caseField, multiFieldIndex) : caseField.value;
        },

        getMultiFieldValueFromField : function(value, mfIndex) {
            if(value == null || value.value == null) return value;
            value = value.value;

            //Y.log("val: "+value+" / "+Y.Array.test(value));

            if(Y.Array.test(value)) {
                //Y.log("entry: "+value[mfIndex]);
                return value[mfIndex];
            }

            return value;
        },

        fixFormIndex : function(form, index) {

            //TODO: nicht hardcoden!
            if (!(form == 'N' || form == 'Kind' || form == 'R' || form == 'V' || form == 'Kinder'
                  || form == 'Kind_ONSE' || form == 'N_AUS_ONSE'
                  || form == 'G' || form == 'S' || form == 'KAP' || form == 'ESt1A_U' || form == 'N_AUS_EM'
                  || form == 'N_AUS_EF' || form == 'OF_U' || form == 'EUER'
                  || form == 'N_Student' || form == "AUS_ONSE" || form == "N_GRE_ONSE"
                 )) {
                return '1';
            }

            return index;
        },

        getValueFromCase : function(name, form, index) {
            var that = this;

            var forms = that.get('forms');

            index = that.fixFormIndex(form, index);

            for (var i = 0; i < forms.length; i++) {
//                Y.log("uc: "+forms[i]+":"+forms[i].form+"@"+forms[i].idx);

                if (forms[i].form == form && forms[i].idx == index) {
                    for (var j = 0; j < forms[i].fields.length; j++) {

                        //den feldnamen aus dem kommentar der rule im usercase suchen
                        if (forms[i].fields[j].field == name) {
//                            Y.log("GET_VALUE: MATCH: " + forms[i].fields[j].field + " -> " + form + "@" + index);

                            return forms[i].fields[j];
                        }
                    }
                }
            }

            return null;
        },

        getSpreadsheets: function() {
            var sheets = this.get(SHEETS);
            sheets.sort.sort('caption');
            // getValuesByKey without a key will return the whole list.
            return sheets.getValuesByKey();
        },

        getSpreadsheet: function(id) {
            var s = this._sheetIdHash[id];
            return s ? s.getValue() : s;
        },

	    removeSpreadsheetByField : function(field) {
		    var sheet = this.getSpreadsheetByField(field);

		    // removing a sheet is done by saving it with an empty data array.
		    sheet.data = [];
		    this.saveSheet && this.saveSheet.save(sheet);
	    },

        getSpreadsheetByField: function(field) {
            return this.getSpreadsheet(this._getDataKey(field));
        },

        getSpreadsheetDataByField: function(field) {
            return this.getSpreadsheetDataById(this._getDataKey(field));
        },

        getSpreadsheetDataById: function(id) {
            var spreadsheet = this.getSpreadsheet(id);
            return spreadsheet && spreadsheet.data || [];
        },

        setSpreadsheetData: function(sheet, field, refField) {
            var instance = this,
                    id = instance._getDataKey(field),
                    sheets = instance.get(SHEETS),
                    storedSheet = instance.getSpreadsheet(id),
                    sheetData = storedSheet || { id:id };


            sheetData.data = sheet.data.toJSON();

            sheetData.result = field.get('value');
            sheetData.caption = sheet.get('caption');

            if( refField ) {
                sheetData.referenceFieldName = refField.get('name');
                sheetData.referenceFieldValue = refField.get('value');
            }

            if( storedSheet ) {
                var index = sheets.indexOf(storedSheet);
                sheets.update(sheetData, index);
            } else {
                sheets.add(sheetData);
            }

            // nicht optimal, da wir jetzt auf unser plugin angewiesen sind - aber erstmal egal.
            instance.saveSheet && instance.saveSheet.save(sheetData);
            return sheetData.id;
        },

        _getDataKey : function(field) {
            var spreadsheedId = field.get('spreadsheetId'),
                    fieldName = field.get('node-name'),
                    formIdx =  field.get('mfIndex');

            if( Y.Lang.isUndefined(formIdx)) {
                formIdx = 1;
            }
            return spreadsheedId + '-' + fieldName + '-' + formIdx;
        },

        getEUERSpreadsheets: function() {
            return this.get('euerSheets');
        },

        setEUERSpreadsheets: function(sheets) {
            this.set('euerSheets', sheets);
        },

        getNote : function(config) {

            var filtered = Y.Array.filter(this.get(NOTES), function(it) {
                if(it.matches(config)) {
                    //Y.log("found note for area: "+it.text);

                    return true;
                }
            }, this);

            return filtered[0];
        },

        //use text == null to delete
        setNote : function(config, text) {
            var note;

            //delete current so we can do both operations using one loop
            var newArray = Y.Array.reject(this.get(NOTES), function(it) {
                if(it.matches(config)) {
                    //Y.log("removing note for area: "+it.text);
                    return true;
                }
            }, this);

            this.set(NOTES, newArray);

            if(!text || text == '') {
                return null;
            }
            else {
                config.text = text;
                note = new Y.konz.notes.Note(config);
                //Y.log("new/updated note: "+note);
                this.get(NOTES).push(note);

                return note;
            }
        },

        getNotes : function() {
            return this.get(NOTES);
        },

        dump : function( target ) {
            var instance = this,
                    info = Y.JSON.stringify(instance.get(FORMS), null, 4) +
                            '\n\n' +
                            Y.JSON.stringify(instance.get(SHEETS), null, 4);
            if( target ) {
                target.set('value', info );
            } else {
                return info;
            }
        }


    }, {

        ATTRS : {
			forms : {
				value : [],
				validator : Y.Lang.isArray
			},
			sheets : {
				writeOnce : true
			},
            euerSheets : {

            },
            notes : {
            }
		}
	});
	// this class has no public constructor we are creating an instance right away.
    caze = new UserCase();


// --------------------------------------------------------------------------------------------------------------------
// -----   save spreadsheet plugin   ----------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------------------

	function SaveSpreadsheetPlugin() {
		SaveSpreadsheetPlugin.superclass.constructor.apply(this, arguments);
	}

	Y.mix( SaveSpreadsheetPlugin, {
		NAME: 'SaveSpreadsheetPlugin',
		NS : 'saveSheet',
		ATTRS: {
			url: {
				value: 'spreadsheet/saveSheetData',
				writeOnce: true
			},
			cfg: {
				value: {
					on: {
						success: function() {
							Y.log('Sheet saved.');
						},
						failure: function() {
                            alert('Speichern fehlgeschlagen!');
							Y.error('Error saving sheet!');
						}
					},
					context: this,
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json; charset=UTF-8'
					}
				},
				writeOnce: 'initOnly'
			}
		}

	});


	Y.extend( SaveSpreadsheetPlugin, Y.Plugin.Base, {

		save :  function(sheetData){
			var url = this.get('url'),
				cfg = this.get('cfg');

			Y.log('saving sheet with id: ' + sheetData.id );
			cfg.data = Y.JSON.stringify(sheetData);

			Y.io(url, cfg);
		}
	});


    // plug in the plug right away.
	caze.plug(SaveSpreadsheetPlugin);

	Y.UserCase = caze;


}, '1.0.0' ,{
	requires:[
		'base-build',
        'io-base',
        'json',
        'node',
        'plugin',
        'recordset-base',
        'recordset-sort',
        'recordset-indexer'
	],
	skinnable : false
});
