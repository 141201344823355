YUI.add('spreadsheet-footer', function (Y, NAME) {

"use strict";
var Lang = Y.Lang,
	fromTemplate = Lang.sub,
	toArray = Y.Array,
	eachArrayItem = toArray.each;

Y.namespace('spreadsheet').FooterView = Y.Base.create('spreadsheetFooter', Y.View, [], {

	CELL_TEMPLATE: '<td colspan="{_colspan}" class="{className}" scope="col" >{content}</td>',

	ROW_TEMPLATE: '<tr>{content}</tr>',

	TFOOT_TEMPLATE: '<tfoot class="{className}"></tfoot>',

	_handlers: [],

	// -----------------------------------------------------------------------------------------------------------
	// --- Lifecycle methods ---

	// we have to prepare each row. We want to know where to place the result and if there is additional space for
	// a label.
	initializer: function () {
		var that = this,
				operations = that.get('col-operations'),
				columns = that.get('columns'),
				host = that.get('host'),
				key,
				updateResults = Y.bind(that._updateResults, that);

		eachArrayItem(operations, function (operation) {
			key = operation.key;
			eachArrayItem(columns, function (column, index) {
				if (column.key === key) {
					operation.index = index;
				}
			});
		});

		// listen for any changes to the model.
		// It's important to wait till the event has bubbled up to our parent. This way we can be sure that the
		// body view was updated already.
		that._handlers.push(host.after({
			'*:add': updateResults,
			'*:remove': updateResults,
			'*:change': updateResults
		}));
		// when the bodyView was rendered we have to update our results.
		that._handlers.push(host.after('*:renderBody', that._updateResults, that));

	},

	render: function () {
		var that = this,
			table = that.get('container'),
			tfooter = that._footerNode ||
					(that._footerNode = that._createTFooterNode());

		if (tfooter.get('parentNode') !== table) {
			table.append(tfooter);
		}
	},

	destructor: function () {
		eachArrayItem(this._handlers, function (handler) {
			handler.detach();
		});
	},

	getClassName: function () {
		var host = this.host,
				NAME = (host && host.constructor.NAME) ||
						this.constructor.NAME;

		if (host && host.getClassName) {
			return host.getClassName.apply(host, arguments);
		} else {
			return Y.ClassNameManager.getClassName
					.apply(Y.ClassNameManager,
							[NAME].concat(toArray(arguments, 0, true)));
		}
	},

	_createTFooterNode: function () {
		return Y.Node.create(fromTemplate(this.TFOOT_TEMPLATE, {
			className: this.getClassName('results')
		}));
	},

	_process: function (operation) {
		var that = this,
				values = that.get('data').get(operation.key),
				result;

		result = operation.fkt.call(that, values);

		// store the result
		// we have to store it at the host datatable to take effect.
		that.get('host').set('results.' + operation.key + '.' + operation.op, result);

		// now return a pretty printed version - if we have a number.
		return isNaN(result) ? result : operation.format.prettify(result);
	},

	_updateResults: function () {
		var that = this,
				operations = that.get('col-operations'),
				columnCount = that.get('columns').length,
				html = '', row = '', result,
				tfooter = that._footerNode;


		Y.Array.each(operations, function (operation) {
			// add a label if there is enough room
			if (1 < operation.index) {
				row += fromTemplate(that.CELL_TEMPLATE, {
					_colspan: operation.index-1,
					className: that.getClassName('result-label'),
					content: operation.label
				});
			}

			// here we want to add the cell for the result
			// and the result itself.
			result = that._process(operation);
			row += fromTemplate(that.CELL_TEMPLATE, {
				_colspan: 2,
				className: that.getClassName('result') + ' ' + operation.format.className,
				content: result
			});

			// finally if there are any columns left we have to provide a cell to fill in the space.
			// comparing index with count -> indexes start at zero!
			if (operation.index < columnCount - 1) {
				row += fromTemplate(that.CELL_TEMPLATE, {
					_colspan: columnCount - 1 - operation.index,
					className: '',
					content: ''
				});
			}

			html += fromTemplate(that.ROW_TEMPLATE, {content: row});
		}, that);

		// just make sure this view was already rendered…
		if(tfooter) {
			tfooter.setHTML(html);
		}

	}

}, {

});

}, '1.0.0', {"requires": ["view"]});
