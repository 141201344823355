// --------------------------------------------------------------------------------------------------------------------
// -----   spreadsheet onse modul   -----------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------------------
YUI.add('spreadsheets', function(Y) {

    "use strict";

    var FILTER_AREA_TITLE = '#case-editor-section-title-hidden',
        OVERLAY_HEADLINE_PREFIX = 'Anlage zu: ';

	var Base_create = Y.Base.create;
	var Spreadsheet = Y.spreadsheet.Spreadsheet;
	var SpreadsheetDefinitions = Y.spreadsheet.SpreadsheetDefinitions;
	var SpreadsheetOverlay = Y.spreadsheet.SpreadsheetOverlay;
	var UserCase = Y.UserCase;
	var Y_Array = Y.Array;


    Y.namespace("spreadsheet").Spreadsheets = Base_create("Spreadsheets", Y.Base, [], {

        initializer : function() {
            var instance = this;

            Y.on('*:openSheet', instance._openSheet, instance );
            Y.after('*:deleteSheet', instance._deleteSheet, instance );

            Y.after('*:updateState', instance._afterStateChange, instance);
        },

        _afterStateChange : function() {

            //hotfix for ONSE-9911: make sure sheet is closed when navigating away
            if(Y._currentSpreadsheetOverlay) {
                Y._currentSpreadsheetOverlay.set('ok', true);
                Y._currentSpreadsheetOverlay.set('modifed', false);
                Y._currentSpreadsheetOverlay.hide();
            }
        },

		_prepareSpreadsheetConfig: function( config ) {
			var cfg = config.spreadsheet;
			// TODO: clean up this mess.
			cfg.headline = cfg.caption = config.title;
			return cfg;
		},

        _isCenterOverlay: function() {
            return true;
        },

        _openSheet : function(e) {
            if(e.forceEvent) {
                e = e.forceEvent;
            }

            var instance = this,
                field =  e.resultField,
                refField = e.refField,
                data = UserCase.getSpreadsheetDataByField(field),
		        cfg = instance._prepareSpreadsheetConfig(e.sheetConfig),
                ov = instance._getOverlay(cfg),
                s = instance.createSpreadsheet(cfg, data),
	            headline = instance._getAreaTitle(OVERLAY_HEADLINE_PREFIX);

            if(Y.one('#ui-is-mobile').get('value') == 'true') {
                Y.one("#body").addClass("ui-is-open-spreadsheet");
                instance.fire('spreadsheetOpen');
            }

            // update our attributes
            instance.set('data', data);
            instance.set('spreadsheet', s);
            instance.set('resultField', field );
            instance.set('referenceField', refField );

	        ov.prepare(headline, s);
            if( instance._isCenterOverlay() ) {
                ov.set('centered', true);
            } else {
                ov.set('xy', [0,0] );
            }

            if(Y.one('#ui-is-mobile').get('value') == 'true') {
                ov.set('constrain', '#case-editor-main');
                ov.set('width', Y.one('#case-editor-main').getComputedStyle('width'));
                ov.set('height' ,null);
                ov.set('centered',null);
            }

            //console.log(ov);

            Y._currentSpreadsheetOverlay = ov;
            Y._currentSpreadsheetEvent = e;
            Y._currentSpreadsheetOverlay.set('modifed', false);

	        ov.show();

            var overlay = ov;

            //copied from app-overlays, can't figure out why we don't use showOverlay here
            Y.later(300, this, function () {
                Y.log("overlay position is x/y: "+overlay.get("x")+"/"+overlay.get("y"));

                //console.log(payload);

                var move = false;
                var x = overlay.get("x")
                var y = overlay.get("y")

                //we have some weird positionings in which mess with the calculation, 70 is the absolute minimum!
                if (y < 70) {
                    y = 70;
                    move = true;
                }

                if(x < 0) {
                    x = 0;
                    move = true;
                }

                if (move) {
                    overlay.move(x, y);
                    Y.log("overlay moved, position is x/y: "+overlay.get("x")+"/"+overlay.get("y"));
                }
            });

        },

	    _deleteSheet : function(e) {
			UserCase.removeSpreadsheetByField(e.field );
		    e.field.set('value', '', {src:'tool'});
		    e.field.set('sheet', UserCase.getSpreadsheetByField(e.field));
            e.field.touch();
		},

        _getOverlay : function(cfg) {
            var instance = this,
                ov = instance.get('overlay');

            if(ov) {
                instance.get('overlay').hide();
            }

            if( undefined === ov ) {
                ov = new SpreadsheetOverlay({centered: instance._isCenterOverlay()});
	            ov.render('body');
                ov.after('visibleChange', instance._handleVisibilityChange, instance);
                instance.set('overlay', ov);
            }
	        if(cfg.width) {
		        ov.set('width', cfg.width);
	        }

            return ov;
        },

	    _processHidingRules : function(definition) {
			var colDefs = definition.coldefs,
				colDef, rule, field,
				nameRegExp = /(\w*)\.(\w*)/, result,
				i = 0,
				j = colDefs.length;

		    for (; i < j; i++) {
			    colDef = colDefs[i];
			    if( colDef.hidingRule ) {
				    rule = colDef.hidingRule;
				    // the rule consists of the form and field name
				    // <form name>.<field name>
				    result = nameRegExp.exec(rule.field);
				    field = UserCase.getValueFromCase(result[2],result[1]);

				    //console.log(field);

                    var idx = Y._currentState.multiFieldIndex;

                    if(Y._overrideSheetMultiFieldIndex != undefined && Y._overrideSheetMultiFieldIndex != null) {
                        idx = Y._overrideSheetMultiFieldIndex;
                    }

				    var newVal = UserCase.getPotentialMultiFieldValueFromField(field, idx);

                    //console.log(field);

                    // we need a fallback here. If the field is not there we are going to hide the column anyway.
				    if( null === field ) {
					    colDef.hidden = true;
				    } else {
					    colDef.hidden = (rule.value === UserCase.realValue(newVal,field.format));
				    }
			    }

		    }
        },


        _copyHidingRules: function (colDefs, columnVisibility) {
            Y_Array.each(colDefs, function (colDef) {
                var key,visi;
                key = colDef.key;
                visi = columnVisibility[key];
                if (typeof visi != 'undefined') {
                    colDef.hidden = !visi;
                } else {
                    // this should not happen.
                    Y.log('no precomputed hiding rule found, defaulting to hidden');
                    colDef.hidden = true;
                }
            });
        },

        createSpreadsheet: function (definition, data, parentData) {
            var s;
            Y.log('creating spreadsheet', definition);

            if (parentData && parentData.columnVisibility) {
                // visbility has already been computed, we just have to copy to the coldef's
                this._copyHidingRules(definition.coldefs, parentData.columnVisibility);
            } else {
                this._processHidingRules(definition);
            }

            definition.data = data;

            if (definition.width) {
                delete definition.width;
            }

            if(data ) {
                definition.rowsPerPage = 50;

                if(Y.one('#ui-is-mobile').get('value') == 'true') {
                    definition.rowsPerPage = 10;
                }

                definition.paginatorLocation = ['header'];
            }

            s = new Spreadsheet(definition);

            s.addTarget(this);

            return s;
        },

        _getAreaTitle : function(prefix, postfix) {
            var el = Y.one(FILTER_AREA_TITLE);
	        prefix = prefix || "";
	        postfix = postfix || "";
            return prefix.concat(Y.Lang.trim( el.get('innerHTML')), postfix);
        },

        _handleVisibilityChange : function(e) {
            var instance = this,
		        ok,
	            sheet = instance.get('spreadsheet'),
		        mainResult, resultField, referenceField;


	        if (!e.newVal) {
		        // if the overlay was closed

		        resultField = instance.get('resultField');
		        referenceField = instance.get('referenceField');

                // determine if the click was a OK-click
                ok = instance.get('overlay').get('ok');

		        Y.log("Closing spreadsheet, ok: "+ok);

                if(!ok && Y._currentSpreadsheetOverlay.get('modifed')) {
                    Y.log("Unsaved changes found.");

                    if(confirm('Sie haben ungespeicherte Änderungen. Sollen diese jetzt gespeichert werden?')) {
                        ok = true;
                    }
                }

		        mainResult = sheet.isEmpty() ? '' : sheet.getMainResult(resultField.format);

		        if (ok) {
			        // first make sure that the caption is still in place:
			        sheet.set('caption', instance._getAreaTitle('', ' - '.concat(sheet.get('headline'))));

			        // sync field values
			        // undefined results means that this spreadsheet does not have any result.
			        if (Y.Lang.isUndefined(mainResult)) {
				        mainResult = "s.Anl.: " + sheet.get('caption');
			        }
			        resultField.set('value', mainResult, {src: 'tool'});
			        //TODO: handle this in field by src after refactoring
			        resultField.set('templateValueHadFocus', true);
			        resultField._removeShallow();
			        // save to the user case
			        UserCase.setSpreadsheetData(sheet, resultField, referenceField);
			        resultField.set('sheet', UserCase.getSpreadsheetByField(resultField));
			        resultField.touch();

                    resultField.markDirty = true;
		        }
		        sheet.destroy();

                Y.one("#body").removeClass("ui-is-open-spreadsheet");
                instance.fire('spreadsheetClosed');

		        if (true === sheet.get('recalculate') && !Y._spreadsheetIsReloading) {
			        instance.fire('calculate', {force: true});
		        }
	        }
        }
    },
    {
        NAME: 'Spreadsheets',

        ATTRS : {
            'definitions' : {
                value : new SpreadsheetDefinitions(),
                readOnly : true
            },
            'overlay' : {
                writeOnce : true
            },
            'spreadsheet' : {

            },
            'data' : {

            },
            'resultField' : {

            },
            'referenceField' : {

            }
        }
    });


	// create a global variable for all spreadsheets.
	Y.Spreadsheets = new Y.spreadsheet.Spreadsheets();

}, '1.0.0' ,{
	requires:[
		'base-build',
		'event-base',
		'event-custom',
		'interview-ref-field-plugin',
		'node',
		'spreadsheet',
		'spreadsheet-definitions',
		'spreadsheet-overlay',
		'user-case'
	],
	skinnable : false
});
