YUI.add('spreadsheet-editor', function (Y, NAME) {

	"use strict";
	var Lang = Y.Lang,
			fromTemplate = Lang.sub,
			toArray = Y.Array,
			eachArrayItem = toArray.each;

	function Editor() {}

	Y.mix(Editor.prototype, {

		// We need a submit button for our form.
		ADD_BUTTON_TEMPLATE: '<button id="ui-spreadsheet-add-btn" title="Zeile \u00FCbernehmen" ' +
				'class="{className} btn btn-s btn-hilited" type="button" tabindex="{tabindex}">' +
				'<i class="fa fa-fw fa-check"></i><span class="app-a11y-label">Zeile \u00FCbernehmen</span></button>',

		// We need a cancel button for our form.
		CANCEL_BUTTON_TEMPLATE: '<button id="ui-spreadsheet-cancel-btn" title="Abbrechen" ' +
				'class="{className} btn btn-s btn-destructive" type="button" tabindex="{tabindex}">' +
				'<i class="fa fa-fw fa-times"></i><span class="app-a11y-label">Abbrechen</span></button>',

		// whenever we edit a record its clientId will be kept here. This way we know what to do when the user
		// hits the save button. i.e. Add a new record or modify the current one.
		_modifiedRecordClientId : null,

		_clearModifyState: function () {
			var that = this,
					modifiedRow;
			if (that._modifiedRecordClientId) {
				modifiedRow = that.getRow(that._modifiedRecordClientId);
				if (modifiedRow) {
					modifiedRow.removeClass('case-interview-spreadsheet-row-is-being-modified');
				}
				that._modifiedRecordClientId = null;
			}
		},

		_reset: function () {
			var that = this,
					cb = Y.Node.getDOMNode(that.get('contentBox'));

			if (Y.Lang.isFunction(cb.reset)) {
				cb.reset();
			}
			eachArrayItem(that._editorFields, function (field) {
				field.resetFieldNode();
				field.set('error', null);
			});
			// back to normal.
			that._clearModifyState();
			that._focusFirst();
		},

		_runValidation: function () {
			var isValid = true;

			eachArrayItem(this._editorFields, function (f) {
                //Y.log("----------------"+f+": "+ f.get('name'));

                //ONSE-8631 ie10 is missing blur events sometimes
                f.set('value', f._fieldNode.get('value'), {
                    src: 'ui'
                });

				if (f.validateField() === false) {
					isValid = false;
				}
			});

			return isValid;
		},

		_scrollToBottom: function () {
			var that = this,
				scrollContainer;
			if (that._yScrollNode) {
				scrollContainer = Y.Node.getDOMNode(that._yScrollNode);
				scrollContainer.scrollTop = scrollContainer.scrollHeight;
			}
		},

		_saveRow: function () {
			var that = this,
				rowData = {},
				clientId = that._modifiedRecordClientId;

			// now we have to transform the german values into native objects.
			eachArrayItem(that._editorFields, function (it) {
				var format = it._editorFormat;

				var value = it.get('value');
				if(value) {
					value = value.replace(/"/g, '');
					value = value.replace(/\'/g, '');
					value = value.replace(/>/g, '');
					value = value.replace(/</g, '');
                    value = value.replace(/\$/g, 'Dollar');
				}

				rowData[it.get('name')] = format.parse ? format.parse(value) : Y.Escape.html(value);
			}, that);

			// we have to check if we are modifying any row or if we simply should add a new one.
			if (null !== clientId && that.getRecord(clientId)) {
				that.modifyRow(clientId, rowData);
			} else {
				that.addRow(rowData);

				// the paginator messes with other rendering runs. This is a quick fix for this issue.
				that.get('paginatorModel').set('page', 2);
				that.get('paginatorModel').set('page', 1);

				//ONSE-9769 scroll to bottom after adding a row
				this._scrollToBottom();
			}

			Y._currentSpreadsheetOverlay.set('modifed', true);
		},

		/**
		 * Editing a row is done in to simple steps. 1st
		 * @param e
		 * @private
		 */
		_editRow: function(e) {
			var that = this,
				clientId = e.target.ancestor('tr').getData('yui3-record'),
				record = that.getRecord(clientId),
				row = that.getRow(clientId);

			// just in case the user clicked the buttons to often…
			that._clearModifyState();

			// store this records clientId
			that._modifiedRecordClientId = clientId;
			// mark the dom node as being edited.
			row.addClass('case-interview-spreadsheet-row-is-being-modified');
			// now populate the fields with the editors values.
			eachArrayItem(that._editorFields, function (it) {
				var format = it._editorFormat,
						value = record.get(it.get('name'));
				// we have to focus the actual field first to make sure the form fields will validate the value correctly.
				it._fieldNode.focus();

				value = format.parse(value);
				// ok - here we process the current value to bring it into an editable form.
				value = format.prettify ? format.prettify(value) : value;
				// special treatment for formats with suffixes.
				if( format.prettifyerConfig && format.prettifyerConfig.suffix ) {
					value = value.replace(format.prettifyerConfig.suffix, '');
				}
				it.set('value', value);
			}, that);

			// now jump to the first field
			that._focusFirst();

			Y._currentSpreadsheetOverlay.set('modifed', true);

			// that's it. When the user hits the save button the corresponding method will recognize that there is
			// a record being edited and will modify this record instead of creating a new one.
		},

		_focusFirst: function () {
			if(Y.one('#ui-is-mobile').get('value') == 'true') {
				return;
			}

			var first = this._editorNode.one('input,select');
			if( first ) {
				first.focus();
			}
		},

		_focusFirstError: function () {
			toArray.some(this._editorFields, function (it) {
				if (null !== it.get('error') && it._fieldNode) {
					it._fieldNode.focus();
					return true;
				}
			});
		},

		_save: function () {
			if (this._runValidation()) {
				this._saveRow();
				this._reset();
			} else {
				// focus the first field with an error
				this._focusFirstError();
			}
		},

		_handleSave: function (e) {
			// return or the add button was hit. we will try to save the current row.
			e.halt();

			Y._currentSpreadsheetOverlaySaving = true;

			// we have to leave to current field to sync the actual value.
			if(e.target.blur) {
				e.target.blur();
			}
			this._save();

			Y._currentSpreadsheetOverlaySaving = true;
		},

		/**
		 * Live processing of inputs in the editor fields.
		 *
		 * @param e the event
		 */
		_handleKeyUp: function (e) {

			//console.log(e);

			//BI-5771: enhance the date so the vz does not have to be written each time
			Y.Array.each(this._editorFields, function(it) {
				if(it._editorFormat.name == "D" && it.key == e.target.get('name')) {
					if(it._fieldNode.get('value').match(/^[0-9]{4}$/)) {
						it._fieldNode.set('value', it._fieldNode.get('value')+Y._config.vz);
						it.set('value', it._fieldNode.get('value'), {
							src: 'ui'
						});
					} else if(it._fieldNode.get('value').match(/^[0-9]{2}(\.){1}[0-9]{2}(\.){1}$/)) {
						it._fieldNode.set('value', it._fieldNode.get('value')+Y._config.vz);
						it.set('value', it._fieldNode.get('value'), {
							src: 'ui'
						});
					}
				}
			});
		},

		initializer: function () {
			var that = this;
			that._editorFields =  [];
			that._editorHandlers = [];

			// we have to change the row editor template to add an edit-button to each row.
			that.set('rowActionTemplate', '<button type="button" title="Diese Zeile bearbeiten" ' +
					'class="btn btn-s ui-spreadsheet-edit-row">' +
					'<i class="fa fa-fw fa-pencil"></i><span class="app-a11y-label">Bearbeiten</span></button>'+
					'<button type="button" title="Diese Zeile entfernen" ' +
					'class="btn btn-s btn-destructive ui-spreadsheet-delete-row">' +
					'<i class="fa fa-fw fa-trash-o"></i><span class="app-a11y-label">Entfernen</span></button>');

			that._editorHandlers.push(
					that.after(['renderView', 'columnsChange'], that._uiRenderEditor)
			);
			// we have to create our own context home to drop in some listeners
			that._editorNode = that._createEditorNode();
			that._editorHandlers.push(
					that._editorNode.after('key', Y.bind(that._handleSave, that), '13')
			);
			that._editorHandlers.push(
					that._editorNode.delegate('click', Y.bind(that._handleSave, that), '#ui-spreadsheet-add-btn')
			);
			that._editorHandlers.push(
					that._editorNode.delegate('click', Y.bind(that._reset, that), '#ui-spreadsheet-cancel-btn')
			);

			//mobile has its own date input implementation which does not need enhancement
			if(Y.one('#ui-is-mobile').get('value') != 'true') {
				that._editorHandlers.push(
					that._editorNode.after('key', Y.bind(that._handleKeyUp, that), 'up:48,49,50,51,52,53,54,55,56,57,58,59,190,110,46')
				);
			}

			// add an extra handler for the edit-row clicks
			that._handlerCache.push(
					that.get('boundingBox').delegate('click', that._editRow, '.ui-spreadsheet-edit-row', that )
			);
		},


		destructor: function () {
			eachArrayItem(this._editorHandlers, function (it) {
				it.detach();
			});
			eachArrayItem(this._editorFields, function (it) {
				it.destroy();
			});
		},

		_createEditorNode: function () {
			return Y.Node.create(fromTemplate('<tbody class="notranslate {className}"></tbody>',
					{className: this.getClassName('editor')}));
		},

		_storeAndRenderField: function(field,format,cell,key){
			this._editorFields.push(field);
			// store the format for later use.
			field._editorFormat = format;
			field.key = key;
			field.render(cell);

            if(Y.one('#ui-is-mobile').get('value') == 'true') {
            	//moving the labels to the fields
				if(Y.one('.yui3-spreadsheet-col-'+key)) {
					if(cell.one('div')) {
						cell.insertBefore(Y.one('.yui3-spreadsheet-col-'+key).getHTML(), cell.one('div'));
						cell.setStyle('width','100%');
					}
				}
            }
		},

		_uiRenderEditor: function () {
			var that = this,
					table = that._tableNode,
					row = Y.Node.create('<tr></tr>'),
					editorNode = that._editorNode,
					colDefs = that.get('coldefs'),
					html;

			if (!that.view || !this._editable) {
				return;
			}

			// we have to use our own coldefs here as they are stored "as is". The columns and _displayColumns get
			// shrunk to their own properties only. This might be good for performance but it is breaking our formats
			// which use inheritance.
			// It's not ideal to store columns and coldefs but what the heck!
			eachArrayItem(colDefs, function (col, index) {
				var field, list, format, listId, cfg, pad = 10, w,
						cell = Y.Node.create(fromTemplate('<td class="{className}" {_id}></td>', {
							className: that.getClassName('editor') + ' ' + that.getClassName('editor', index),
							_id: 'data-yui3-col-id="'+col.key+'"'
						}));
				if (col && col.editable) {
					format = col.format;
					listId = col.listId;
					cfg = {
						name: col.key,
						required: col.mandatory,
						validateInline: true,
						validator: function (val, field) {
							// new formats don't normalize or validate
							if (format.isValid) {

								if(col.greaterZero) {
									var cVal = val;

									if(val && val.indexOf(",") >= 0) {
										cVal = val.replace(",",".")
									}

									if(!isNaN(cVal) && Number(cVal) <= 0) {
										field.set('error', "Dieser Wert muss größer als 0 sein.");

										return false;
									}
								}

								var normal = format.normalize ? format.normalize(val) : val;
								if (format.isValid(normal)) {


									if(Y.one('#ui-is-mobile').get('value') == 'true') {
										//ONSE-12709 was caused by async workaround due to mobile field collisions
										field.set('value', normal);
									} else {
										//ONSE-10440 IE timing fixes - we'll go out of our way to set normalized value and jump to next field correctly
										var node = null;

										try {
											var node = field._fieldNode.ancestor("td").next('td');

											if (node) {
												if (node.one('input')) node = node.one('input');
												else if (node.one('select')) node = node.one('select');
												else if (node.one('button')) node = node.one('button');
											}
										} catch (ex) {
											//console.log(ex);
										}

										//console.log(Y._currentSpreadsheetOverlaySaving);

										if (!Y._currentSpreadsheetOverlaySaving) {
											//only async when not saving, values would be shown again in editor there
											Y.later(30, this, function () {
												// set the normalized value if it is valid
												field.set('value', normal);

												if (node) {
													node.focus();
												}
											});
										} else {
											field.set('value', normal);
										}
										//end timing fixes
									}

									return true;
								}

								field.set('error', format.errorMsg);

								return false;
							} else {
								field.set('value', val);
								return true;
							}
						}
					};

					if(col.width) {
						cfg.width = col.width;
					}

					// choose between text and combo fields
					if (listId) {

						list = col.listConfig;
						if(list.get('forceSelection')){
							field = new Y.SelectField(cfg);
							field.set('choices', list.get('data'));

							pad = 2;
						} else {
							field = new Y.AutoCompleteField(cfg);
							field.set('source', list.get('data'));
							field.set('resultTextLocator', 'value');
							pad = 30;
						}

						// this will render the field
						that._storeAndRenderField(field,format,cell,col.key);

					} else {
						field = new Y.TextField(cfg);

						//console.log(col.format);
						//console.log(field);
						//console.log(col.key);

						that._storeAndRenderField(field,format,cell,col.key);

                        field._fieldNode.setAttribute("autocomplete", "off");
                    }

					if(col.width) {
						if(Y.Lang.isNumber(col.width)) {
							w = col.width;
						} else {
							w = col.width.replace('px', '');
							w = parseInt(w, 10);
						}
						w -=pad;
						field._fieldNode.setStyle('width', w+'px');
					}

				} else if (colDefs.length === index + 1) {
					html = fromTemplate(that.ADD_BUTTON_TEMPLATE, {
						className: that.getClassName('add', 'button'),
						tabindex: Y.FormField.tabIndex
					});

					Y.FormField.tabIndex++;

					html += fromTemplate(that.CANCEL_BUTTON_TEMPLATE, {
						className: that.getClassName('cancel', 'button'),
						tabindex: Y.FormField.tabIndex
					});
					Y.FormField.tabIndex++;

					cell.addClass('case-interview-spreadsheet-actions');
					cell.setHTML(Y.Node.create('<div>'+html+'</div>'));
				}
				row.append(cell);
			});

            if(Y.one('#ui-is-mobile').get('value') == 'true') {
            	//we've repositioned the labels already, hider header
            	Y.one('.yui3-spreadsheet-table').one('thead').setStyle('display', 'none');
            }

			editorNode.setHTML(row);

			// bring the node into the dom
			if (editorNode.get('parentNode') !== table) {
				table.insertBefore(editorNode, table.one('> tbody'));
			}

			that._focusFirst();
		}
	}, true);

	Y.namespace('spreadsheet').Editor = Editor;

}, '1.0.0', {"requires": ["event-key", "smart-form", "smart-form-choice-config", "smart-formats", 'node-event-simulate']});
