// collection of all views which handle the representation of any of the case model classes.
YUI.add('case-editor-views', function(Y) {

    "use strict";

    var Slang = Y.smst.Lang,
        FORMAT_CFG = {decimalPlaces:2, decimalSeparator:',',thousandsSeparator:'.',suffix:' €'};


    Y.CaseEditorCaseHeaderView = Y.Base.create('CaseEditorCaseHeaderView', Y.HandlebarsView, [], {

        // Provide a template that will be used to render the view. The template can
        // be anything we want.
        template: 'case-editor-header-case',
        partials : ['case-status'],

        _handler1 : null,
        _handler2 : null,
        _searchView : null,

        initializer : function() {
            var that = this;

            that._handler1 = that.after('cazeChange', that._afterCazeChanged, that);
            // for the initial creation we have to fake the caseChange event.
            that._afterCazeChanged({newVal:that.get('caze')});

            // to run the animation:
            Y.on('*:calculationNumChange', that._afterCalcChange, that);
            //that._handler3 = Y.after('*:assessmentValueChange', that._setAssessmentValue, that);

            if(Y.one('#ui-is-mobile').get('value') == 'true') {
                this.template = Y.HandlebarsTemplates.lookup('case-editor-header-case_mobile');
            }
            else {
                that._searchView = new Y.CaseEditorHeaderSearchView();
                that._searchView.addTarget(that);
            }
        },

        render : function() {
            var that = this,
                data = that.get('caze');
            //Y.log('##### render: '+data.get('calculationNum'), null, 'CaseEditorHeaderView');
            //console.log(data);

            that._render(data.toJSON());
            // fill in the search form.
            if(that._searchView) {
                that._searchView.render();
                that.get('container').one('#case-editor-controls').prepend(that._searchView.get('container'));
            }

            return that;
        },

        destructor : function() {
            var that = this;
            Slang.detachEventListener(that._handler1);
            Slang.detachEventListener(that._handler2);
            that._searchView && that._searchView.destroy() && (that._searchView = null);
        },

        /*
        _setAssessmentValue : function(e) {
            this.get('caze').set('calculation', e.calculation);
            this.render();
        },
        */

        _afterCazeChanged : function(e) {
            Slang.detachEventListener(this._handler2);
            if(e.newVal) {
                this._handler2 = e.newVal.after('change', this.render, this);
            }
        },

        _afterCalcChange : function(e) {
            var caze = this.get('caze'),
                start = e.prevVal,
                end = caze.get('calculationNum'),
                del = end - start,
                elem = Y.one('#case-editor-header #case-result-value'),
                endStr,
                that = this;

            //Y.log('##### afterCalcChange, end:'+end);
            if (!elem) {
                return;
            }

            var anim = new Y.Anim({
                iterations: 50,
                duration: 0.03,
                on: {
                    'iterationCountChange': function (e) {
                        var frame = e.prevVal,
                            iterations = this.get('iterations'),
                            currentValue = Y.Easing.easeBothStrong(frame, start, del, iterations),
                            cont;

                        cont = Y.Number.format(currentValue, FORMAT_CFG);

                        var curCase = that.get('caze');
                        if (caze !== curCase) {
                            //Y.log('##### aborting anim');
                            // our animation target has changed, our animation does not make sense anymore
                            // true means: go to last frame, thus just displaying the actual value
                            anim.stop(true);
                        }
                        /*
                        Stupid enough, this method might get called after the 'end' function
                         */
                        if (endStr) {
                            cont = endStr;
                        }
                        Y.one('#case-editor-header #case-result-value').setHTML(cont);
                    },
                    'end' : function(e) {
                        // we get everything fresh, the caze object might have changed, and
                        // also the dom elems
                        endStr = that.get('caze').get('calculation');
                        Y.one('#case-editor-header #case-result-value').setHTML(endStr);
                    }
                }
            }).run();
        }


    }, {
        // Specify attributes and static properties for your View here.
        ATTRS: {

            /**
             * this is the case which status we shall display.
             */
            caze : { },

            // Override the default container attribute.
            container: {
                valueFn: function () {
                    return Y.Node.create('<div id="case-editor-header" />');
                }
            }
        }
    });


    Y.CaseEditorMainHeaderView = Y.Base.create('CaseEditorMainHeaderView', Y.HandlebarsView, [], {

        template : 'case-editor-header-main',

        _eventHandlers : [],

        _updateTimer : null,

        // The initializer function will run when a view is instantiated.
        initializer: function () {
            this._eventHandlers.push(this.after('titleChange', this._delayedRender, this));
            this._eventHandlers.push(this.after('backOfficeChange', this._delayedRender, this));
            this._eventHandlers.push(this.after('imageChange', this._delayedRender, this));
            this._eventHandlers.push(this.after('helpChange', this._delayedRender, this));
            this._eventHandlers.push(Y.delegate('click', this._showFullHelp, 'body', '.ui-area-help-show-all', this));
        },

        events: {
            '.case-editor-interview-help-lexicon-link' : {
                click: '_handleLexiconLinkClicked'
            }
        },

        _handleLexiconLinkClicked : function (e) {
        },

        reset : function(title) {
            this.setAttrs({
                title: title,
                help: null,
                showFullHelp: false,
                image: null
            });
        },

        render : function() {
            var image = this.get('image'),
                container = this.get('container');
            if( image ) {
                container.addClass('has-header-image');
            } else {
                container.removeClass('has-header-image');
            }

            this.constructor.superclass.render.apply(this);
        },

        _delayedRender : function() {
            if( this._updateTimer ) {
                this._updateTimer.cancel();
                this._updateTimer = null;
            }
            this._updateTimer = Y.later(10, this, this.render);
        },

        _showFullHelp: function(e) {
            this.set('showFullHelp', true);
            this._delayedRender();
        },

        destructor: function () {
            Slang.detachEventListener(this._eventHandlers);
        }
    }, {
        ATTRS: {
            title: {
                value: "smartsteuer",
                getter: function(val) {

                    //TODO make this configurable if we keep using it
                    //alternative: mix-blend-mode: hard-light + text-shadow 2px 2px #e0e0e0
                    if(val === "Willkommen") {
                        return null;
                    }

                    return val;
                }
            },
            showFullHelp: {
		        value: false
            },
		    help:  {
		        value: null,
                getter: function(help) {
		            if(!help) {
		                return help;
                    }

		            //after a certian size show only the first paragraph, but add a link to expand it
                    if(Y.one('#ui-is-mobile').get('value') == 'true' //Y.one('#ui-is-phone')
                       && !this.get('showFullHelp')
                       && help.indexOf('iframe') < 0
                       && help.indexOf("</p>") < help.lastIndexOf("</p>")) {
                        return help.substring(0, help.indexOf("</p>")+"</p>".length)
                               +' <a href="javascript:void(0);" class="ui-help-link ui-area-help-show-all">...mehr anzeigen...</a>';
                    }

                    return help;
                }
            },
            image: { value: null },
            backOffice: { value: false },
            container: {
                valueFn: function () {
                    return Y.Node.create('<div id="case-editor-main-header" />');
                }
            }
        }
    });


}, '1.0.0', {
    requires:[
        'base-build',
        'case-editor-header-search-view',
        'event',
        'handlebars-view',
        'node',
        'smst-lang',
        'view',
        'anim'
    ]});