"use strict";

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol ? "symbol" : typeof obj; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

Object.defineProperty(exports, "__esModule", {
    value: true
});

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Observation = function () {
    function Observation(experimentIn, behaviourName, block, dateTimeProvider) {
        _classCallCheck(this, Observation);

        this._experiment = experimentIn;
        this._name = behaviourName;
        this._dateTimeProvider = dateTimeProvider;

        var now = this._dateTimeProvider.now();
        try {
            this._result = block();
            this._error = null;
        } catch (err) {
            this._error = err;
        }

        this._duration = (this._dateTimeProvider.now() - now) / 1000; //total seconds
        //console.log(`[Observation] duration ${this._duration}`);
    }

    /**
     *
     * @param other
     * @type {Observation}
     */

    _createClass(Observation, [{
        key: "isEquivalentTo",
        value: function isEquivalentTo(other) {
            var bothRaisedExceptions = other.error !== null && this._error !== null;
            var neitherRaisedExceptions = other.error === null && this._error === null;

            var valuesAreEqual = other.result === this._result;

            var exceptionsAreEquivalent = bothRaisedExceptions && _typeof(other.error) === _typeof(this._error) && other.error.message === this._error.message;

            //console.log(`(${neitherRaisedExceptions} && ${valuesAreEqual}) || (${bothRaisedExceptions} && ${exceptionsAreEquivalent})`);
            return neitherRaisedExceptions && valuesAreEqual || bothRaisedExceptions && exceptionsAreEquivalent;
        }
    }, {
        key: "experiment",
        get: function get() {
            return this._experiment;
        }
    }, {
        key: "name",
        get: function get() {
            return this._name;
        }
    }, {
        key: "result",
        get: function get() {
            return this._result;
        }
    }, {
        key: "duration",
        get: function get() {
            return this._duration;
        }
    }, {
        key: "error",
        get: function get() {
            return this._error;
        }
    }]);

    return Observation;
}();

exports.default = Observation;