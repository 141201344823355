/**
 * Widget to render the preview of a report.
 */
YUI.add('analysis-preview', function(Y) {

	// TODO: hier sollte das rendering der berichte und der anhänge vorgenommen werden. Nicht in analysis.js!
    var
        APPENDIX_PDF_DOWNLOAD_CLASS = 'appendix-pdf-download',
        REPORT_PDF_DOWNLOAD_CLASS = 'report-pdf-download';

    /**
     * The Constructor
     * @param cfg
     */
    var AnalysisPreview = function(cfg) {
        AnalysisPreview.superclass.constructor.apply(this,arguments);
    };

    /**
     * Static properties and methods
     */
    Y.mix( AnalysisPreview, {
        // name required by YUI
        NAME: 'analysisPreview',
        // our attributes
        ATTRS : {
            controlBox : {
                value : null, // if we set a value here (even if it's null), the setter will be called on initialization.
                setter : function(node) {
                    return this._setBox(null, node, this.CONTROL_TEMPLATE); // a widget method.
                }
            },
            // the Id of the currently previewed report.
            curId : {
                value : null
            },
            // The data of this preview
            data : {
                value : '',
	            setter : '_setData'
            }
        }
    });

    // now we will create the widget.
    Y.extend( AnalysisPreview, Y.Widget, {

        CONTROL_TEMPLATE : '<div></div>',
        PDF_DOWNLOAD_TEMPLATE : '<a href="#" class="btn display-none-in-ipad-app"><i class="fa fa-cloud-download"></i> PDF herunterladen</a>',

	    _handler: [],

	    _reportPreview : true,

        _pdfButton : null,

	    destructor : function() {
		    Y.Array.each( this._handler, function(it) {it.detach()});
	    },

        renderUI: function() {
            // now we will render the
            var instance = this,
                bb = instance.get('boundingBox'),
                doc = bb.get("ownerDocument"),
                ctrlBox = instance.get('controlBox'),
                buttonBox,
                pdfc = instance.getClassName('pdf-download'),
                pdfb;

            // classname for our box
            ctrlBox.addClass(instance.getClassName('control'));
            buttonBox = ctrlBox.one('.btn-group-fused');
            if (buttonBox == null) {
                buttonBox = Y.Node.create('<div class="btn-group-fused"></div>');
                ctrlBox.prepend(buttonBox);
            }

            // check for the pdf download button
            pdfb = ctrlBox.one('.' + pdfc);
            if (pdfb == null) {
                pdfb = Y.Node.create(instance.PDF_DOWNLOAD_TEMPLATE);
                buttonBox.prepend(pdfb);
            }
            pdfb.addClass(pdfc);
            instance._pdfButton = pdfb;

            // prepend it to the bounding box.
            if (!ctrlBox.inDoc(doc)) {
                bb.prepend(ctrlBox);
            }

        },

        bindUI: function() {
	        this._handler.push( this.after('dataChange', this._destroySpreadsheet, this));
        },

	    _syncPrintPreviewButton:function (){
		    var instance = this,
				id = instance.get('curId'),
		        hiddenClass = instance.getClassName('hidden');

		    if( null == id ) {
                this._pdfButton.addClass( hiddenClass );
		    } else {
                this._pdfButton.removeClass( hiddenClass );
                this._pdfButton.setData( 'id', id );
                this._pdfButton.setData('assessment-type', instance.get('curType'));
                this._pdfButton.setData('sessionId', instance.get('sessionId'));

			    if( this._reportPreview ){
                    // it is a report and not an appendix, this will trigger different handlers in print-preview.js
                    this._pdfButton.replaceClass(APPENDIX_PDF_DOWNLOAD_CLASS, REPORT_PDF_DOWNLOAD_CLASS);
			    } else {
				    // this will oben the appendix print preview.
                    this._pdfButton.replaceClass(REPORT_PDF_DOWNLOAD_CLASS,APPENDIX_PDF_DOWNLOAD_CLASS);
			    }
		    }
	    },

	    /**
	     * Updates the link under the buttons click event.
	     */
	    syncUI: function() {
		    this._syncPrintPreviewButton();
		    this.set('data', '<div id="case-editor-analysis-report-preview-body-placeholder">Wählen Sie eine Berechnungsliste, einen Musterbrief oder ' +
				    'eine Tabelle aus, um hier eine Vorschau zu erhalten.</div>');
        },


	    /**
         * Used to update this preview.
         * @param id
         * @param data
         * @param type
         */
        update : function( id, data, type ,sessionId) {
            this.set('curId', id);
            this.set('curType', type);
            this.set('data', data );
            this.set('sessionId', sessionId);
            this._syncPrintPreviewButton();
        },

	    wait: function() {
			this.set('data', '<h1 id="case-editor-waiting">Bitte warten…</h1>');
	    },

        /*
         * Helper method for setting host data
         */
        _setData: function(data) {
	        var box =  this.get('contentBox');
	        box.setContent('');
	        if( Y.Lang.isObject(data) && data.render ) {
		        this._reportPreview = false;
		        data.render(box);
	        } else {
		        this._reportPreview = true;
		        box.setContent(data);
	        }
	        return data;
        },

	    _destroySpreadsheet : function(e){
		    var prev = e.prevVal;
		    if( prev && prev.destroy )
			    prev.destroy();
	    }
    });

    // added to the right namespace
    Y.namespace("analysis").AnalysisPreview = AnalysisPreview;

}, '1.0.0', { requires:['widget', 'substitute']} );