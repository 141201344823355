YUI.add('smart-handlebars-loader', function (Y, NAME) {

"use strict";
function HandlebarsLoader() {
	// empty constructor
}

Y.mix(HandlebarsLoader.prototype, {
	_ioConfig: {
		// We have to sync this request to make sure the view can be rendered when the call to load returns.
		sync: true,
		on : {
			success : function(id, res, callback) {
				callback(res.responseText);
			},
			failure : function() {
				Y.error("Error fetching template.");
			}
		}
	},
	load: function(name, callback) {
		var path,
				config;
		path = this._resolve(name);
		config = Y.mix( {"arguments":callback}, this._ioConfig);
		Y.io(path, config);
	},

	_resolve: function(name) {
		return "hbs/raw/" + name;
	}
});

Y.HandlebarsLoader = HandlebarsLoader;


}, '1.0.0', {"requires": ["base-build", "handlebars-base", "handlebars-compiler", "io"]});
