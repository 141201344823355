// collection of all views which handle the representation of any of the case model classes.
YUI.add('case-editor-app-base', function(Y) {
    "use strict";

    var Slang = Y.smst.Lang,
		VALIDATION_REQUEST;

    (function(){
		/**
	     * Convinient base class for all apps used as views inside of the case editor.
	     */
	    Y.smst.CaseEditorAppBase = Y.Base.create('CaseEditorAppBase', Y.App, [], {

            initializer : function() {
                Y.once('smartsteuer:ready', function(config){this.config = config}, this);

                this.publish('sectionStateChanged');
                this.publish('smartCheckInitialized');
	            /**
	             * If an editor-app wants to activate a sub section to get it highlighted in the navigator and its
	             * label put into the headline, this event has to be fired.
	             * The only payload needed is the name of section to activate:
	             * e.g. {name:'filing2-process-' + area}
	             */
                this.publish('newSubSection');
            },

	        handleNavigation : function(navInfo) {
	            Slang.notImplementedMethod('handleNavigation');
	        },

            checkAndHandleNavigation : function(navInfo, section) {
	            // sub sections do not have thier own attributes here. They will inherit them from thier parent section.
                if(section && (section.get('fallbackOnError') || section.get('fallbackOnAssessment') || section.get('validate'))) {

	                if( VALIDATION_REQUEST && VALIDATION_REQUEST.isInProgress() ) {
		                VALIDATION_REQUEST.abort();
		                VALIDATION_REQUEST = null;
	                } else {
		                this.fire('waiting',{msg:'Bitte warten…'});
	                }

                    var cfg = {
             			    on: {
             				    success: function(id, o) {
                                    Y.log("validating case state...");
                                    var data = Y.JSON.parse(o.responseText);

									//hotfix: remove waitmask if necessary
                                    var node = Y.one('#render-wait-mask');
                                    if(node) {
                                        node.setStyle('display', 'none');
                                    }

                                    var ok = data.valid && (!section.get('fallbackOnAssessment') || !data.assessmentHasToBeSet);

                                    Y.log("ok: "+ok+": "+section.get('fallbackOnGvError')+"/"+data.gvWithErrors);
                                    //only throw updates if only validate is set
                                    ok = ok || (section.get('validate') && !section.get('fallbackOnAssessment') && !section.get('fallbackOnError'));

                                    if(ok && section.get('fallbackOnAnonymous') && data.anonymous) {
                                        ok = false;
                                    }

                                    Y.smartCheckOk = data.valid;

                                    Y.hasGvErrors = data.gvWithErrors;
                                    Y.gvErrors = data.gvErrors;

                                    var evt = {checkOk:data.valid, assessmentOk:!data.assessmentHasToBeSet, analysisOk: data.analysisOk, resultsOk: data.resultsOk};
                                    this.fire('sectionStateChanged', evt);

                                    if(data.reloadHints && section.get('name') != 'summary') { //ONSE-8806/ONSE-8782 smartCheck tests itself
                                        this.fire('smartCheckInitialized');
                                    }

			                        //ONSE-11472 we have to validate on assessment to prevent calculation changes but want to go there even if invalid
                                    if((section.get('name') == 'assessment' || section.get('name') == 'taxmeter') && !data.valid && this.config.boUser) {
                                    	if(confirm('Speziell für Backoffice-Nutzer: Der Fall ist nicht valide. Soll die Seite trotzdem angezeigt werden?')) {
                                    		ok = true;
										}
									}

                                    this.fire('removeWaiting');

                                    if(ok) {
                                        this.handleNavigation(navInfo);
                                    }
                                    else {
                                        Y.log("falling back to error state.");
                                        var template = Y.HandlebarsTemplates.lookup('case-editor-validation-state');

                                        data.link = (data.valid && !data.anonymous) ? Y.smst.CaseEditor.SECTIONS.getByName('assessment').get('link') : Y.smst.CaseEditor.SECTIONS.getByName('summary').get('link');

                                        data.boUser = this.config.boUser;

                                        this.get('container').setHTML(template(data));

                                        if(Y.one("#case-editor-main-controls")) {
                                            Y.one("#case-editor-main-controls").setStyle('display', 'none');
                                        }
                                    }
             				    },
             				    failure: function() {
             					    Y.log("Request failed or was aborted",'warn','Validation');
             				    }
             			    },
             			    context: this
             		    };

	                VALIDATION_REQUEST = Y.io("api/validation", cfg);
                }
                else {
                    this.handleNavigation(navInfo);
                }
   	        },

		    /**
		     * This is the default implementation of the handleNext method. It will only throw a 'nextSection' event
		     * which lets the case editor unload this app and load the next one. If an other behaviour is required you
		     * have to overwrite this method. (see case-editor-app-interview for a fullblown example.
		     */
	        handleNext : function() {
		        this.fire('nextSection');
	        },


		    /**
		     * default destructor to destroy any active views.
		     */
		    destructor : function() {
			    var v = this.get('activeView');
			    if(v) {
				    v.destroy();
			    }

		    }

	    }, {
	        ATTRS : {

	            viewContainer: {
	                getter: function(val) {
	                    // if a view container was set - use it
	                    if( val ) {
	                        return val;
	                    }
	                    // otherwise fall back to the container.
	                    return this.get('container');
	                }
	            },

	            // we don't want this app to do any routing accidentally.
	            // Therefor we are disabling the link selector so that it will never hit.
	            linkSelector : {
	                value: ''
	            }


	        }

	    });
    })();

}, '1.0.0', {
    requires:[
        'app',
        'base-build',
	    'smst-lang'
    ]});