'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _behaviourMissingError = require('./errors/behaviour-missing-error');

var _behaviourMissingError2 = _interopRequireDefault(_behaviourMissingError);

var _observation = require('./observation');

var _observation2 = _interopRequireDefault(_observation);

var _result = require('./result');

var _result2 = _interopRequireDefault(_result);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var BaseExperiment = function () {
    function BaseExperiment(name, randomProvider, dateTimeProvider) {
        _classCallCheck(this, BaseExperiment);

        this._name = name;
        this._runIf = true;
        this._publishers = [];

        this.Control = null; //Internal
        this.Candidate = null; //Internal
        this._percentageEnabled = 0;

        this._randomProvider = randomProvider;
        this._dateTimeProvider = dateTimeProvider;
    }

    _createClass(BaseExperiment, [{
        key: 'runIf',
        value: function runIf(_runIf) {
            this._runIf = _runIf;
        }
    }, {
        key: 'run',
        value: function run() {
            //console.log('[BASE EXPERIMENT] run');

            if (this.Control === null) {
                throw new _behaviourMissingError2.default(this, "Control");
            }

            var result = new _result2.default(this);
            var candidateObservation;
            var controlObservation;

            if (this._randomProvider.randomInt(0, 2) === 0) {
                controlObservation = this._getControlObservation();

                result.observations.push(controlObservation);

                candidateObservation = this._getCandidateObservation();
                if (candidateObservation !== null) {
                    result.observations.push(candidateObservation);
                }
            } else {
                candidateObservation = this._getCandidateObservation();
                if (candidateObservation !== null) {
                    result.observations.push(candidateObservation);
                }
                controlObservation = this._getControlObservation();
                result.observations.push(controlObservation);
            }

            this.__publish(_result2.default);
            return controlObservation.result;
        }
    }, {
        key: '_getCandidateObservation',
        value: function _getCandidateObservation() {
            var candidateObservation = null;
            if (this._experimentShouldRun()) {
                if (this._runIf === true) {
                    candidateObservation = new _observation2.default(this, "Candidate", this.Candidate, this._dateTimeProvider);
                }
            }
            return candidateObservation;
        }
    }, {
        key: '_getControlObservation',
        value: function _getControlObservation() {
            return new _observation2.default(this, "Control", this.Control, this._dateTimeProvider);
        }
    }, {
        key: '_experimentShouldRun',
        value: function _experimentShouldRun() {
            return this._percentageEnabled > 0 && this._randomProvider.randomInt(0, 100) < this._percentageEnabled;
        }

        ///internal

    }, {
        key: '__publish',
        value: function __publish(result) {
            for (var publisher in this._publishers) {
                publisher.publish(result);
            }
        }
    }, {
        key: 'percentageEnabled',
        get: function get() {
            return this._percentageEnabled;
        },
        set: function set(value) {
            this._percentageEnabled = value;
        }
    }, {
        key: 'publishers',
        get: function get() {
            return this._publishers;
        },
        set: function set(value) {
            this._publishers = value;
        }
    }, {
        key: 'name',
        get: function get() {
            return this._name;
        },
        set: function set(value) {
            this._name = value;
        }
    }]);

    return BaseExperiment;
}();

exports.default = BaseExperiment;