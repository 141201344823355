YUI.add('app-version', function(Y) {
	"use strict";

	Y.AppVersion = Y.Base.create('AppVersion', Y.Model, [Y.ModelSync.REST], {
		root: '/api/version'
	}, {
		ATTRS : {
			buildNumber : {},
			timestamp   : {}
		}
	});


	Y.AppVersionView = Y.Base.create('AppVersionView', Y.HandlebarsView, [], {

		template : 'app-version',

		initializer : function() {
			var that = this,
				version = that.get('appVersion');
			// load the current version
			version.load();
			// if the version get's loaded after we rendered the view out, we have to refresh the view.
			that._changeListener = version.on('change', that.render, that );
		},

		render: function() {
			return this._render( this.get('appVersion').toJSON() );
		},

		destructor : function() {
			if( this._changeListener ) {
				this._changeListener.detach();
				delete this._changeListener;
			}
		}

	}, {
		ATTRS: {
			appVersion : {
				valueFn : function() {
					return new Y.AppVersion();
				}
			},
			container : {
				valueFn : function() {
					return Y.Node.create('<div class="app-version"></div>');
				}
			}
		}
	});

}, '1.0.0', {
	requires:[
		'base-build',
		'handlebars-view',
		'model',
		'model-sync-rest'
	]});
