/**
 * Although it's called sidebar it's actually more of a general purpose class which glues certain components together.
 *
 * It updates the calculation result in the header and shows inline error widgets
 * after smartcheck mod is initialized.
 *
 */
YUI.add('case-editor-sidebar', function(Y) {

	"use strict";

	(function(){

		var Slang = Y.smst.Lang;

		Y.CaseEditorSidebar = Y.Base.create('CaseEditorSidebar', Y.View, [], {

			_eventHandlers : [],
			_currentRequest : null,
			_pageState : null,
			config : null,

			events : {
			},

			initializer: function () {
				var navigation = this._navigation = new Y.CaseEditorSidebarNavigator();
				navigation.addTarget(this);

				Y.once('smartsteuer:ready', function(config){this.config = config;}, this);

				//update smartcheck errors and calculation result
				this._eventHandlers.push(Y.after('CaseEditor:pageStateChange', this._updateIssues, this));
   				this._eventHandlers.push(Y.after('*:smartCheckInitialized', function() {this._updateIssues({}, true);}, this));
                this._eventHandlers.push(Y.on('*:assessmentValueChange', function() {this._updateIssues({}, true);}, this));
				this._eventHandlers.push(Y.after('interview:rendered', this._updateErrors, this));
                this._eventHandlers.push(Y.after('interview:multiOverviewRendered', this._updateErrors, this));
			},

			render : function() {
				var container = this.get('container');

                if(this.config.lexofficeAccount && Y.one('#ui-is-mobile').get('value') != "true") {
                    container.append(Y.Node.create(
                        '<div class="lexoffice-backlink">'+
                            '<a href="https://app.lexoffice.de/inbound?referrer=smst" target="_blank"><img src="/img/lexoffice_small.png" /> lexoffice starten</a>'+
                        '</div>'
                    ));
                }

				container.append(this._navigation.get('container'));
				this._navigation.render();

				return this;
			},

			destructor: function () {
				this._navigation.removeTarget(this);
				this._navigation.destroy();

				Slang.detachEventListener(this._eventHandlers);
			},

            /**
			 * WARNING: Although errors currently are not shown live the result is still updated from here!
			 *
             * @param e
             * @param force
             * @private
             */
			_updateIssues : function(e, force) {
				
				var that = this;
				that._pageState = e.newVal;

				if(force || that._pageState.caseChanged) {

					var cfg = {
						on: {
							success: function(id, o) {
								var data = Y.JSON.parse(o.responseText),
									e1 = Y.JSON.parse(data.errors),
									e2 = data.elsterErrors ? Y.JSON.parse(data.elsterErrors) : [],
                                    hints = Y.JSON.parse(data.hints),
                                    warnings = Y.JSON.parse(data.warnings);

								if(this.config.boUser && !(Y.one('#ui-is-mobile').get('value') == 'true')) {
									var errors = Y.one('#ui-app-debug-elster');

									if(e2.length > 0) {
										errors.setStyle('display', '');
									}
									else {
										errors.setStyle('display', 'none');
									}
								}

								Y.globalErrors = [];
								if(e1) {
									Y.globalErrors  = Y.globalErrors .concat(e1);
								}
								if(e2) {
									Y.globalErrors  = Y.globalErrors .concat(e2);
								}

                                Y.globalErrors = Y.globalErrors.concat(hints);
                                Y.globalErrors = Y.globalErrors.concat(warnings);

                                //TODO: this could be refactored in various ways.
								//for the time being we'll just change the result number for students
								//having zero est and loss carried forward here
                                var hasNonZeroEST = false;
                                var potential = 0;
                                var actualResult = data.curResult;

                                if(data.allResults) {
                                    Y.Array.each(
                                        data.allResults,
                                        function (it) {
                                            if(it.est && it.result !== "0,00") {
                                                hasNonZeroEST = true;
                                            }

                                            if(it.vv) {
                                                //console.log(it.result.replace(/,00/,'').replace(/[.]/g,''));

                                                potential += parseInt(it.result.replace(/,00/,'').replace(/[.]/g,''));
                                            }
                                        },
                                        this
                                    );
                                }

                                var isVV = !hasNonZeroEST && potential;

                                //console.log(!hasNonZeroEST+" / "+potential);

                                if(isVV) {
                                    actualResult = ""+potential;
                                }
                                //end zero est and loss carried forward code

								this.fire('result', {
									value:actualResult,
									allResults: data.allResults,
									isVV: isVV
								});
							},
							failure: function() {
								Y.log("Request failed or was aborted",'warn','CaseEditorIssues');
							}
						},
						context: that
					};

					if( that._currentRequest && that._currentRequest.isInProgress() ) {
						that._currentRequest.abort();
					}
					that._currentRequest = Y.io("api/hints", cfg);
				}
			},

			_checkMFAState : function(it) {
				var that = this;

                //ONSE-11482 don't highlight if index cannot be determined correctly
                if(it.forceMultiFieldIndex1) {
                    var skip = false;

                    if(that._pageState.isMultiFieldBase && it.index == -1) {
                        //ONSE-11761 reenabling the feature for selected cases
                    }
                    else {
                        Y.Array.each(it.forceMultiFieldIndex1, function (it3, idx2) {
                            if (it3) {
                                skip = true;
                            }
                        });

                        Y.Array.each(it.forceMultiFormIndex1, function (it3, idx2) {
                            if (it3) {
                                skip = true;
                            }
                        });
                    }

                    if(skip) {
                        //console.log("SKIP");
                        return false;
                    }
                }

                return true;
			},

            _checkAreaState : function(it, it2) {
                var that = this;

                //console.log("IDX: "+it.index);

                var ret = (it.areaId == that._pageState.area || it2 == that._pageState.area) &&
                    (that._pageState.multiFieldIndex >= 0 ? it.index == that._pageState.multiFieldIndex : true) &&
                    it.formIndex == that._pageState.formIndex &&
                    (!that._pageState.isMultiFieldBase || it.index < 0) &&
                    !that._pageState.isMultiFormBase;

				if(!that._checkMFAState(it)) {
					return false;
				}

                //console.log("NOSKIP: "+ret);

				return ret;
            },

			_updateErrors : function() {
				var that = this;

				Y.all('.ui-case-interview-inline-error').remove();

				if(!Y.globalErrors || !that._pageState || !Y.smartCheckMode) {
					return;
				}

				Y.Array.each(Y.globalErrors, function(it, idx) {

				   that._highlightTreeArea(it);

				   //console.log(it);

				   if(it.type != 'INTERVIEW_ERROR') {

					   if(that._checkAreaState(it)) {
                           //console.log("1");
						   that._showErrorMessage(it.message, it.type);
					   }
					   else if (it.areaIds) {
						   Y.Array.each(it.areaIds, function (it2, idx2) {
							   if (that._checkAreaState(it, it2)) {
                                   //console.log("2");
								   that._showErrorMessage(it.areaMessages[idx2] ? it.areaMessages[idx2] : it.message, it.type);
							   }

							   that._highlightTreeArea(it, it2);
						   });
					   }
				   }
				}, that);

			},

			_createLinkFromError : function(err, id) {
				return "/interview/"+(id ? id : err.areaId)+
					   "/"+err.formId+
					   "/"+err.formIndex+
					   "/"+(err.index != undefined ? err.index : '-1');
			},

			_highlightTreeArea : function(err, id) {
				var that = this;

				if(!err || !Y.smartCheckMode) return;

				Y.all('#ui-nav-tree a.openArea').each(function(it2) {

					if(!it2.hasClass('ui-nav-status') && it2.get('href').indexOf(that._createLinkFromError(err, id)) >= 0) {

                        var doHighlight = it2.get('href').indexOf("/false/false", it2.get('href').length - "/false/false".length) == -1;

                        if(doHighlight && that._checkMFAState(err)) {
                            it2.addClass('ui-nav-status');

                            if (err.type == 'PLAUSIBILITY_HINT') {
                                it2.addClass('nav-recommendation');
                            }
                            else if (err.type == 'PLAUSIBILITY_WARNING') {
                                it2.addClass('nav-warning');
                            }
                            else {
                                it2.addClass('nav-error');
                            }
                        }
					}
				});
			},

			_showErrorMessage : function(msg, type) {
				var that = this,
					node = Y.one('#case-interview-form');

				if(!node) {
					//fallback for overview pages
					node = Y.one('#case-interview-body');
				}

				if(node && msg) {

                    var additionalClass = "";

                    if(type == 'PLAUSIBILITY_HINT') {
                        additionalClass = "app-alert-hint";
                    }
                    else if(type == 'PLAUSIBILITY_WARNING') {
                        additionalClass = "app-alert-warning";
                    }
                    else {
                        additionalClass = "app-alert-problem";
                    }

					node.insert('<div class="ui-case-interview-inline-error app-alert '+additionalClass+'""><i class="fa fa-exclamation-circle"></i><div>'+msg+'</div></div>', 0);
				}
			},

			/**
			 * This will make the node glow for a second.
			 * @param node
			 * @param color
			 * @private
			 */
			_blink : function(node, color) {
				node.transition({
					delay: 0,
					duration: 0.1,
					backgroundColor: color,
					easing: 'ease-in'
				}, function() {
					this.transition({
						delay: 0,
						duration: 3.0,
						backgroundColor: '#fff',
						easing: 'ease-out'
					}, function() {
						// remove the inline styles!
						this.setStyles({
							backgroundColor:null
						});
					});
				});
			}


			// --- controller logics ----------------------------------------------------------------------------------

		}, {
			ATTRS: {
				container: {
					valueFn: function () {
						return Y.Node.create('<div id="case-editor-sidebar" />');
					}
				}
			}
		});
	})();

}, '1.0.0', {
	requires:[
		'base-build',
		'case-editor-sidebar-navigator',
		'case-editor-app-assessment',
		'event',
		'json-parse',
		'node',
		'view',
		'transition'
	]});
