// editor component for the current case
YUI.add('case-editor', function(Y) {
    "use strict";

	var Slang = Y.smst.Lang,
		NAV_DIRECTION_BACKWARD = -1,
		NAV_DIRECTION_STAY     = 0,
		NAV_DIRECTION_FORWARD  = 1;

    (function(){

        Y.Section = Y.Base.create('Section', Y.Model, [], {

	        _eventHandler : [],

	        _updateTreeNode: function (e) {
		        var that = this,
				    node = that.get('treeNode'),
			        yui3Node;

		        // The change events will bubble up if this is a sub section. We have to make sure, that only the
		        // current section will be marked complete.
		        if(node && e.target === that) {
			        yui3Node = Y.one('#' + node.contentElId);
			        if( yui3Node) {
				        if(e.newVal) {
					        yui3Node.one('a').addClass('nav-filled');
				        }
				        else {
					        yui3Node.one('a').removeClass('nav-filled');
				        }
			        }
		        }
	        },

	        _updateLink : function(parent) {
				var that = this,
					link = '';

		        if(parent) {
			        link = parent.get('link');
		        }
		        link += '/';
		        link += that.get('name');

		        that._set('link', link);
	        },

	        initializer: function() {
		        var that = this;
		        // we will listen to okChange because we have to modify the treeNode if it does.
		        that._eventHandler.push( that.after('okChange', that._updateTreeNode, that));
	        },

	        destructor : function() {
		        Slang.detachEventListener(this._eventHandler);
	        }

        }, {
            ATTRS : {
                active : {value:false},
                index: {value:-1},
                locked:{value:false},
                visible:{value:true},
                name: {},
                label:{},
                link : {
	                value:null,
	                // we do quite some jazz to create a correct link. We don't want anyone to destroy this.
	                readOnly: 'initOnly'
                },
	            parent: {
		            setter: function(val) {
			            this._updateLink(val);
			            return val;
		            }
	            },
	            sections: {
		            value : null,
		            setter: function(val){
			            var list;
			            if( val instanceof Y.SectionList ) {
				            list = val;
			            } else if (Y.Lang.isArray(val)) {
				            // convert to SectionList
				            list = new Y.SectionList();
				            list.add(val);
			            }
			            // if there is a list of child entries we want to get all the events.
			            // And we want them to know that they have a parent.
			            if( list ) {
				            list.addTarget(this);
				            list.invoke('set', 'parent', this);
			            }
			            return list;
	                }
	            },
	            /**
	             * Marks this section as complete.
	             */
	            ok: {
		            validator: Y.Lang.isBoolean
	            },
	            /**
	             * This is the YUI2-Treenode which represents this section in the navigation tree.
	             */
	            treeNode : {}
            }
        });

        Y.SectionList = Y.Base.create('SectionList', Y.ModelList, [], {

            model: Y.Section,

            _handlers : [],

            initializer : function() {
	            var that = this;

                that._handlers.push( Y.after('SMST:activeCaseChange', that._updateLinks, that));
                that._handlers.push( that.on('Section:activeChange', function(e) {
                    // before a new section gets activated we are silently deactivating any other.
                    if(e.newVal) {
                        that.invoke('_set', 'active', false);
                    }
                }));
            },

            destructor : function() {
                Slang.detachEventListener(this._handlers);
            },

            // Custom comparator to keep sections in order.
            comparator: function (model) {
                return model.get('index');
            },

            getByName : function( name ) {
                var result = [];
	            if( name ) {
		            this.each(function(sec){
			            var childResult,
					            sectionName = sec.get('name');
			            if(sectionName === name) {
				            result.push(sec);
			            } else if(0 === name.indexOf(sectionName) ){
				            childResult = sec.get('sections').getByName(name.substr(sectionName.length +1)); // name + dash
				            if( childResult ) {
					            result.push(childResult);
				            }
			            }
		            });
	            }
                return 0 < result.length ? result[0] : null;
            },

            getSection : function( name ) {
                var result = this.filter(function(sec){
                    if(sec.get('name') === name)
                        return sec;
                });
                return 0 < result.length ? result[0] : null;
            },

            getActive : function() {
                var activeSection = null;
	            this.each(function(it){
		            // check for any child sections first.
		            if( !activeSection && it.get('sections')) {
			            activeSection = it.get('sections').getActive();
		            }
                    // in case there is more than one active section (which would be an invalid state) we only
		            // care for the first one.
		            if (!activeSection && true === it.get('active')) {
			            activeSection = it;
		            }
                });
                return activeSection;
            },

            getNext : function( ) {
                var check = false,
		            index = 99,
		            visibleSections,
		            nextSection;


	            visibleSections = this.filter({asList:true},function(sec){
		            return true === sec.get('visible');
	            });

				// the sections are in order.
	            // we need to get the index of the current section and return the next one.
		        visibleSections.some( function(sec) {
			        if( check && sec.get('locked') === false && sec.get('index') > index ) {
				        // 1. wait for 'check' to get turned on.
				        // 2. check that this section is not locked and that its index is greater than the one of the
				        //    active section.
				        //    (This way we prevent sections with an index of -1 to slip in.
				        nextSection = sec;
				        return true;
			        }
			        if(sec.get('active') === true) {
				        check = true;
			        }
			        index = sec.get('index');
		        });
                return nextSection;
            },

	        /**
	         * Updates the links with correct case id in the path.
	         *
	         * @param e
	         * @private
	         */
            _updateLinks : function(e) {
                Y.log('Updating section links', 'DEBUG', 'SectionList');

	            if(e.newVal ) {

		            //This works recursive, so we only want it to run from the top level.
		            this.each(function(it){
			            var parent = it.get('parent'),
					        name = it.get('name');

			            // if we have a parent we can take its link as our starting point
			            if( parent ) {
							it._set('link', parent.get('link') + '/' + it.get('name'));
			            }  else {
				            // this is a top level section. We need to compute the link to include the id of the
				            // current case.
				            it._set('link',
						            Y.Lang.sub('/case/{id}/{name}', {id:e.newVal.get('id'),name:name})
				            );
			            }
		            });
	            }
            },

            populateStaticEntries : function() {

                this.add([
                    {
                        active:true,
                        index:1,
                        locked:false,
                        name:'interview',
                        label:'Interview'
                    },{
                        index:2,
                        locked:false,
                        name:'summary',
                        label:'smartCheck',
                        validate: 'true'
                    },{
                        index:3,
                        locked:false,
		                visible : false,
                        name:'assessment',
                        label:'Veranlagungsart',
                        fallbackOnError: true,
                        fallbackOnAnonymous: true,
                        validate: true
                    },{
                        index:4,
                        locked:false,
                        name:'taxmeter',
                        label:'Alle Steuerbeträge',
                        fallbackOnError: true,
                        fallbackOnAssessment: true,
                        fallbackOnAnonymous: true,
                        validate: true
                    },{
                        index:5,
                        locked:false,
                        name:'analysis',
                        label:'Auswertung',
                        fallbackOnError: false,
                        fallbackOnAssessment: false,
                        fallbackOnAnonymous: true,
                        validate : true
	                },{
		                index:6,
		                locked:false,
		                name:'filing2-process',
		                label:'Abgabe',
		                fallbackOnError: true,
		                fallbackOnAssessment: true,
                        fallbackOnAnonymous: true
	                }
                    ,{
                        index:7,
                        locked:false,
                        name:'decree',
                        label:'Steuerbescheid',
                        fallbackOnError: false,
                        fallbackOnAssessment: false,
                        fallbackOnAnonymous: true,
                        sections: [
                            {
                                index:1,
                                locked:false,
                                name:'decree-mc',
                                label:'Einkommensteuer'
                            }, {
                                index:2,
                                locked:false,
                                name:'decree-fc',
                                label:'Einkommensteuer'
                            }, {
                                index:3,
                                locked:false,
                                name:'decree-objection',
                                label:'Abschließende Informationen'
                            }
                        ]
                    },{
		                index:-1, // This section is not part of the normal flow - the index is -1
		                locked:false,
		                name:'checkout-process',
		                label:'Bezahlen und abgeben'
	                },{
                        index:-1, // This section is not part of the normal flow - the index is -1
                        locked:false,
                        name:'errors',
                        label:'Fehler'
                    },{
                        index:-1, // This section is not part of the normal flow - the index is -1
                        locked:false,
                        name:'hints',
                        label:'Empfehlungen'
                    },{
		                index:-1, // This section is not part of the normal flow - the index is -1
		                locked:false,
		                name:'warnings',
		                label:'Warnungen'
	                },{
		                // This section is always the last section in every sub case! We take a ridiculous high index
		                // here.
		                index:99,
                        locked:false,
                        name:'back-to-est',
                        label:'Zurück zur Einkommensteuer'
                    },{
                        index:-1,
                        name:'search',
                        label:'Suche',
                        text:''
            		}
                ]);
                return this;
            }

        }, { });

    })();


    (function() {

        Y.namespace("smst").CaseEditor = Y.Base.create('CaseEditor', Y.App, [Y.AppOverlays, Y.AppHeader], {

            _handlers : [],

	        _printPreview : null,

	        _curCase : null,

            headers: {
                openCase : Y.CaseEditorCaseHeaderView
            },

            views: {
                interview : {
                    type : Y.CaseEditorAppInterview
                },
                summary : {
                    type : Y.CaseEditorAppSummary
                },
                assessment : {
                    type : Y.CaseEditorAppAssessment
                },
                analysis : {
                    type : Y.CaseEditorAppAnalysis
                },
                taxmeter : {
                    type : Y.CaseEditorAppTaxMeter
                },
                back : {
                    type : Y.CaseEditorAppBackToEst
                },
	            filing2 : {
		            type : Y.CaseEditorAppFiling2
	            },
                decree : {
   		            type : Y.CaseEditorAppDecree
   	            },
	            checkout : {
		            type : Y.CaseEditorAppCheckout
	            },
                search : {
                    type : Y.CaseEditorAppSearch
                }
            },

            events : {
                '.ui-case-editor-next' : {click:'handleNext'},
                '.ui-case-editor-next-end' : {click:'handleNextEnd'},
                '.ui-case-editor-next-and-smartcheck' : {click:'handleNextAndSmartCheck'},
                '.ui-case-editor-calc' : {click:'handleCalc'},
                '.ui-case-editor-back' : {click:'handleBack'}
            },

	        overlays : {
		        unsavedChangesOnUnload : {
			        header:"Ungesicherte Änderungen",
			        footer:'<button type="button" class="btn" id="unsaved-discard">Verwerfen</button>' +
					        '<button type="button" class="btn btn-hilited" id="unsaved-save">Übernehmen</button>',
			        template: 'case-editor-interview-overlay-unsaved',
			        events : {
				        '#unsaved-save' : {
					        click : '_saveOnUnload',
					        keypress : '_saveOnUnload'
				        },
				        '#unsaved-discard' : {
					        click : '_forceUnload',
					        keypress : '_forceUnload'
				        }
			        },
			        // don't add escape listeners and buttons
			        notEscapable : true
		        }
	        },

            initializer : function() {

                var that = this,
                    sidebar = that._sidebar = new Y.CaseEditorSidebar(),
		            boUser = that._boUser = that.get('config.boUser'),
                    mainHeader = that._mainHeader = new Y.CaseEditorMainHeaderView({ backOffice:boUser}),
	                registration = that._registration = new Y.Registration(),
	                login = that._login = new Y.Login(),
                    focus = that._focus = new Y.FocusInterview(),
                    errors = that._errors = new Y.Errors(),
					help = that._help = new Y.HelpInterview(),
                    spreadsheets = that._spreadsheets = Y.Spreadsheets,
					linkViewer = that._linkViewer = new Y.LinkViewer();

                if(Y.one('#ui-is-mobile').get('value') != 'true') {
                	//OSNE-12805: android keyboard does not handle keycodes, which we need
					var taxnumberdecorator = that._taxnumberdecorator = new Y.TaxnumberDecorator();
				}

                // --- some navigation plumbing ---
                sidebar.addTarget(that);
                mainHeader.addTarget(that);
                errors.addTarget(that);
                spreadsheets.addTarget(that);
	            registration.addTarget(that);
	            login.addTarget(that);
				//taxnumberdecorator.addTarget(that);
				//linkViewer.addTarget(that);

	            // for analysis and filing we need the print preview.
	            // it is enough to create it as it will register click listeners.
	            that._printPreview = new Y.PrintPreview();

                // fixme: move that outa here
                focus.addTarget(that);
                help.addTarget(that);

                if(boUser) {
                    //FIXME: does not work, see ONSE-6956. dev-reports needs correct dependencies
	                //YUI().use('dev-reports', function(Y){
		                that._devReports = new Y.DevReports();
		                that._devReports.addTarget(that);
	                //});
                }

	            that._handlers.push( Y.on('*:waiting', that._showWaitingMask, that) );
                that._handlers.push( Y.on('*:removeWaiting', that._hideWaitingMask, that) );
	            that._handlers.push( that.on('*:nextSection', that._handleNextSection, that) );
	            that._handlers.push( that.on('*:newSubSection', that._handleNewSubSection, that) );
	            that._handlers.push( that.on('*:sectionMeta', that._handleSectionMeta, that) );
	            that._handlers.push( that.after('*:updateState', that._afterChildAppStateChange, that) );
	            that._handlers.push( that.after('pageStateChange', that._afterPageStateChange, that) );
	            that._handlers.push( Y.after('SMST:activeCaseChange', that._resetAfterCaseChanged, that));
				that._handlers.push( Y.on('*:nextPage', that.handleNext, that) );
	            that._handlers.push( Y.on('*:navigate', that._prepareUnload, that) );

	            /**
	             * This event will gets fired whenever the case editor navigates away from the interview. At this point
	             * it is important that we do a final check for unsaved changes.
	             */
	            that.publish('unload', {
		            preventable : true,
		            preventedFn : that._unloadPrevented
	            });

	            that._navDirection = NAV_DIRECTION_STAY;
	            // default internal navigation status
	            that._navStepsCount = 0;
            },

            render : function() {
                var that = this;

                // first call our super class to get the scaffolding done.
                that.constructor.superclass.render.call(that);

	            // the sidebar
	            that._sidebar.render();
                that._bodyNode.appendChild(that._sidebar.get('container'));

	            // and the app header with title and area image
	            that._mainHeader.render();
	            that.get('container').one('#case-editor-main').prepend(that._mainHeader.get('container'));
            },

	        /**
	         * This will get called the first time the view container is requested and will render
	         *
	         * @return {*}
	         * @private
	         */
	        _renderScaffold : function() {
                var that = this,
                    body, initialId,
                    sidebar = that._sidebar,
                    container = that.get('container'),
                    scaffolding = new Y.HandlebarsView({template:'case-editor-scaffolding'});


                initialId = 'case-editor-body-with-sidebar';

                scaffolding._render({'initial-body-class':initialId});
                container.appendChild(scaffolding.get('container').getHTML());

                body = that._bodyNode = container.one('#'+initialId);

                // destroy the scaffolding
                scaffolding.destroy();
                return container.one('#case-editor-main-body');
            },

            // time for some clean ups.
            destructor : function() {

                this._sidebar && this._sidebar.destroy() && (this._sidebar = null);
                this._mainHeader && this._mainHeader.destroy() && (this._mainHeader = null);
                this._focus && this._focus.destroy() && (this._focus = null);
                this._errors && this._errors.destroy() && (this._errors = null);
                this._help && this._help.destroy() && (this._help = null);
                this._devReports && this._devReports.destroy() && (this._devReports = null);
                this._spreadsheets && this._spreadsheets.destroy() && (this._spreadsheets = null);
                this._decreeOverlay && this._decreeOverlay.destroy() && (this._decreeOverlay = null);
                this._taxnumberdecorator && this._taxnumberdecorator.destroy() && (this._taxnumberdecorator = null);
	            this._registration && this._registration.destroy() && (this._registration = null);
	            this._login && this._login.destroy() && (this._login = null);
	            this.linkViewer && this.linkViewer.destroy() && (this.linkViewer = null);

                Slang.detachEventListener(this._handlers);
            },

	        // --- global handlers -------------------------------------------------------------------------------------


	        _showWaitingMask : function(e) {
		        var container = e.container || Y.one('#case-editor-main'),
				    node = Y.Node.create('<h1 id="case-editor-waiting">'+ e.msg+'</h1>');

		        if(container.hasChildNodes()) {
			        container.get('childNodes').addClass('ui-invisible');
		        }
		        container.prepend(node);

		        window.scrollTo(0,0);
	        },

            _hideWaitingMask : function(e) {
   		        var container = e.container || Y.one('#case-editor-main');

                var node = Y.one('#case-editor-waiting');
                if(node) {
					if(node.ancestor('div').hasChildNodes()) {
						node.ancestor('div').get('childNodes').removeClass('ui-invisible');
					}
					//FIXME: we might have two waitings after submitting the last page and showing the smartcheck
					//not sure how to handle this best. this is safer than letting the first page after the interview
					//handle it, because that may change
					Y.all('#case-editor-waiting').remove(true);
                }

                if(container.hasChildNodes()) {
   			        container.get('childNodes').removeClass('ui-invisible');
   		        }
   	        },

	        /**
	         * This is called when ever one of our child Views/Apps fires a "updateState" event. This is happening when
	         * some one else is doing a request which changes the server side state of the user case.
	         * @param e
	         * @private
	         */
	        _afterChildAppStateChange : function(e) {
		        Y.log('_afterChildAppStateChange', 'DEBUG', 'CaseEditor');
		        this._updateState(e.raw, e.src, e.navInfo, e.activateSmartCheck);
	        },

	        //ONSE-6444 disable separate assessment if needed
	        _afterPageStateChange : function (e) {
				var pageState = e.newVal;

		        var _toggleSeparateAssessment = function () {
			        var assessment = Y.smst.CaseEditor.SECTIONS.getByName('assessment');

			        if (pageState && pageState.hasSeparateAssessment) {
				        assessment.set('locked', false);
				        assessment.set('visible', true);
			        } else {
				        assessment.set('locked', true);
				        assessment.set('visible', false);
			        }
		        };
		        _toggleSeparateAssessment();

	        },

	        _updateState : function(rawData, src, nextPageNavInfo, activateSmartCheck) {
		        var that = this,
				    state = new Y.CaseState(),
				    data, navInfo;

		        Y.log('_updateState', 'DEBUG', 'CaseEditor');
		        state.parseAndUpdate(rawData);
		        data = state.get('parsed');

                //ONSE-8957 show Exception for bo-user
                if(that._boUser) {
                    var node = Y.one('#ui-app-debug-exception');
                    if(node && state.get('parsed').caseChanged) {
                        if(state.get('parsed').calculationException) {
                            node.setStyle('display','');
                        }
                        else {
                            node.setStyle('display', 'none');
                        }
                    }
                }

		        // here we have some updated information which we can store in the active case.
		        if( that._curCase ) {
			        that._curCase.set('percentComplete', data.percentComplete);
		        }

		        // finally check if the requested area is visible otherwise we will alert this and stop processing.
		        if( true === data.invisible ) {
			        that.fire('alert', {msg:'Diese Seite kann nicht angezeigt werden.'});
		        } else {
			        // store the new state
			        // this will trigger our parent app to open the nextPageNavInfo  or the next section what ever is
			        // requested.
			        // Also the tree and the hints will update their inner states.
			        navInfo = (true === data.exit ? {link:that._getNextSectionLink()} :nextPageNavInfo);


			        if(activateSmartCheck) {
                        navInfo = {link: Y.smst.CaseEditor.SECTIONS.getByName('summary').get('link')}
                    }

			        var opts = {src: src};
			        if( navInfo ) {
				        opts.navInfo = navInfo;
			        }
			        that._set('pageState', data, opts);
		        }
	        },

	        _resetAfterCaseChanged : function() {
		        var activeView = this.get('activeView');
		        Y.log('New case was loaded. Resetting status…', 'DEBUG', 'CaseEditor');
		        if( activeView ) {
			        activeView.destroy({remove: true});
		        }

		        this._navDirection = NAV_DIRECTION_STAY;
		        this._navStepsCount = 0;
		        this._activeTopLevelSection = null;

                Y.globalErrors = [];
                Y.smartCheckMode = false;
	        },

	        /**
	         * This will check and disable the back button if there are no steps left.
	         * @private
	         */
	        _checkBackButton : function() {
		        if(1 >= this._navStepsCount ) {
			        Y.all('.ui-case-editor-back').addClass('btn-disabled').setAttribute('disabled','disabled');
		        } else {
			        Y.all('.ui-case-editor-back').removeClass('btn-disabled').removeAttribute('disabled');
		        }
	        },

	        // ---

            handleNavigation : function(req, callback) {
                var that = this,
		            caze = that._curCase = req.activeCase,
                    pageInfo = that._mapPageInfo(req),
	                section = that._activeTopLevelSection,
                    activeView = that.get('activeView'),
		            cb = callback,
		            // On the first request - after loading a case - the initial state is part of the request.
		            // We have to check for this one and store it.
		            // TODO: Use the CaseState object everywhere in the app.
		            initialState = req.initialState && req.initialState.get('parsed');

	            // now bring in the header.
	            that.showHeader('openCase', {caze:caze}, {update:true,render:true});

                // We have to keep track of the current direction the user is heading.
                pageInfo._navDirection = that._navDirection || NAV_DIRECTION_FORWARD;
	            // We need to be able to disable the back button. For that we have to count each navigation step.
	            if( NAV_DIRECTION_BACKWARD !== that._navDirection ) {
		            that._navStepsCount++;
	            }

	            // We have to disable the back button if we are at the first page. This task is a little tricky as
	            // we don't always know when the page is rendered completely. To compensate that, we are checking
	            // several times for the button and its status.
	            // Only if the _navStepsCount is 1 the button has to be disabled;
	            // ONSE-8678: One addition for our backoffice users.
	            if( 1 === that._navStepsCount || that._boUser ) {
		            // we are wrapping the callback in an extra callback for this task.
		            cb = Y.bind(function() {
			            // in the interview there is an event which will get fired when everything is in its place.
			            that.onceAfter('interview:rendered', that._checkBackButton, that);
			            // for any other situation there is no solution ATM.
			            // There is a hack which will disable the back button lazily in (handleBack) but this is
			            // - as said - only a hack.
			            // To fix this the case-editor would have to render the back and next buttons on its own but
			            // this is going to produce other errors… (will do - but later!)

			            // call the original callback
			            callback();
		            }, that);
	            }

	            // restore the navigation direction.
	            that._navDirection = NAV_DIRECTION_FORWARD;

	            if( initialState ) {
		            that._set('pageState', initialState, {src:'navigation'});

                    Y._currentState = initialState;

					//ONSE-10823 show exception even after initial case change
					if(that._boUser && Y.one('#ui-is-mobile').get('value') != 'true') {
						Y.io('/groovy/hasInitialException',{
							on: {
								success: function(id, o) {
									if(o.responseText == "true") {
										Y.one('#ui-app-debug-exception').setStyle('display','');
									}
								}
							}
						});
					}
	            }

                // save the new section - but only if it has changed...
                if(that._activeTopLevelSection && pageInfo.section === that._activeTopLevelSection.get('name')) {
                    Y.log('Section "' + pageInfo.section + '" is active already. Normal flow...', 'DEBUG', 'CaseEditor');

	                activeView.checkAndHandleNavigation(pageInfo, Y.smst.CaseEditor.SECTIONS.getByName(pageInfo.section));

                    cb();

                } else {
	                Y.log( 'Switching to section "' + pageInfo.section + '".', 'DEBUG', 'CaseEditor');

	                that.showView(this._normalizedName(pageInfo.section), null, {callback:function(){
		                // activate and store the new section.
		                that._activeTopLevelSection = that._activateSection(pageInfo.section);
		                // ok - we brought in the correct sub-app now it's their turn...
                        that.get('activeView').checkAndHandleNavigation(pageInfo, Y.smst.CaseEditor.SECTIONS.getByName(pageInfo.section));

		                cb();
	                }});
                }

            },

            _normalizedName : function(name) {
                if( -1 == name.indexOf('-')) return name; // shortcut.
                return name.substr(0,name.indexOf('-'));
            },

	        _activateSection : function( section ) {
	            // if we only get the name of a section here - we have to fetch its instance.

	            if(!(section instanceof Y.Section)) {
		            section = Y.smst.CaseEditor.SECTIONS.getByName(section);
	            }

	            if( section ) {
	                section.set('active', true);
		            this._mainHeader.reset(section.get('label'));
		            this._set('activeSection', section);
	            }
	            return section;
            },

	        /**
	         * When ever a sub app wants to active a new section it is able to fire 'newSubSection' with the
	         * sections name as payload. We will handle this here.
	         *
	         * @param e {section: section-name | section}
	         * @private
	         */
	        _handleNewSubSection : function(e) {
		        this._activateSection(e.section);
	        },

	        /**
	         * This will store the new meta information for the current page. Any sub app/view is able to change the
	         * title, help text or backlogimage shown by the editor. The Editor itself does not know/care which title
	         * to show - it just does the plumbing.
	         * @param e
	         * @private
	         */
	        _handleSectionMeta : function(e) {
		        this._mainHeader.setAttrs(e);
	        },


	        /**
             * This method is called every time any one of the next-buttons inside of this editor was clicked.
             * We are simply dispatching to the activeView (a.k.a. subApp).
             */
            handleNext : function(e) {
                e.halt();

                this.get('activeView').handleNext();
            },

            _createLink : function(e) {

                if(e.command && e.command != '') {
                    return e.formIndex+"/"+ e.command;
                }

                return Y.smst.CaseEditor.SECTIONS.getByName('interview').get('link') +
                       "/"+e.area+
                       "/"+e.formId+
                       "/"+e.formIndex+
                       "/"+(e.multiFieldIndex != undefined ? e.multiFieldIndex : '-1')+
                       "/" + e.hmfas +
                       "/" + e.hmfoas;
            },

            handleNextEnd : function(e) {
                var link = Y._savedNavState ? this._createLink(Y._savedNavState) :
		                Y.smst.CaseEditor.SECTIONS.getByName('summary').get('link');

                e.halt();

                Y._errorMode = false;
                Y._hintsMode = false;
                Y._warningsMode = false;
                Y._savedNavState = null;

                this.fire('open', {link: link});
            },

            handleNextAndSmartCheck : function(e) {
                e.halt();
                this.get('activeView').handleNextAndSmartCheck();
            },

	        /**
	         * This will get the next section (if one exists) and return the link to navigate to that section.
	         *
	         * @return A string containing the link or null if no next section exists.
	         * @private
	         */
	        _getNextSectionLink: function () {
		        var sec = Y.smst.CaseEditor.SECTIONS.getNext();

		        // special treatment for EÜR
                if(this.get('pageState').parentSessionURL) {
                    if(!sec || sec.get('name') == 'back-to-est') {
                        return this.get('pageState').parentSessionURL;
                    }
                    else if(sec.get('name') == 'filing2-process' ) {
                        sec = Y.smst.CaseEditor.SECTIONS.getByName('back-to-est');
                    }
                }

		        return sec ? sec.get('link') : null;
	        },

	        /**
             * Called when one of our sub apps is fireing 'nextSection'. We are going to open the next section. Which
             * means that the current view is unloaded an the view/app for the next section is loaded.
             */
            _handleNextSection : function(e) {
                e && e.halt();
		        var link = this._getNextSectionLink();
		        if(link) {
		            this.fire('open', {link:link});
	            } else {
		            this.fire('alert', {msg:'Unable to open next section.'});
	            }
            },

            /**
             * This method is called every time any one of the next-buttons inside of this editor was clicked.
             * We are simply dispatching to the activeView (a.k.a. subApp).
             */
            handleCalc : function(e) {
                e.halt();
                this.fire('calculate');
            },

            /**
             * This method is called every time any one of the back-buttons inside of this editor was clicked.
             * Not much to do here - simply call history.back().
             */
            handleBack : function(e) {
	            var that = this;
                e.halt();

	            that._navStepsCount--;
	            if(0 < that._navStepsCount) {
		            that._navDirection = NAV_DIRECTION_BACKWARD;
		            Y.config.win.history.back();
	            } else {
		            // just make sure that the counter won't go below zero.
		            that._navStepsCount++;
		            that._checkBackButton();
	            }
            },

	        // TODO:
            // this could move to an NavInfo class constructor
	        // or into the editor-apps as they are the only once who know how to handle the requests.
            _mapPageInfo : function(req) {
                var params = req.params,
		            pageInfo;

	            // the base request allways looks like this:
	            //      /case/<caseId>/<section>/
	            pageInfo = {
		            caseId:req.activeCase.get('id'),
		            section:params[2]
	            };
	            // from there on the information differs:
	            switch(pageInfo.section) {
		            // search:
		            //      /searchTerm
		            case 'search':
			            pageInfo.searchTerm = params[3];
		            break;
		            case 'filing2-process':
			            Y.mix(pageInfo, {
				            area:params[3],
				            formId:params[4], // kept here for backwards compatibility
				            subArea:params[4]
			            });
			            if( pageInfo.area === "buyingProcess" ) {
				            pageInfo.shopOutcome = params[4];
			            }

			            break;
		            case 'checkout-process':
			            pageInfo.paymentOutcome = params[3];
			        break;
		            // interview and (default):
		            //      /<area>/<formId>/<formIndex>/<mfaIndex>/<hmfas>/<hmfoas>
		            default:
			            Y.mix(pageInfo, {
				            area:params[3] || req.activeCase.get('currentAreaId'),
				            formId:params[4] || -1,
				            formIndex:params[5] || -1,
                            multiFieldIndex:params[6] || -1,
				            hmfas:params[7] || false,
				            hmfoas:params[8] || false
			            });
	            }
	            return pageInfo;
            },

	        // --- handling unloading of sections and of this editor. -----------------------------------------------

	        /**
	         * When ever our parent app is navigating we take the chance and check for unsaved changes.
	         *
	         * @param e The navigation event.
	         * @private
	         */
	        _prepareUnload:function (e) {
		        var that = this,
				    next, cur, active;

		        next = that.removeRoot(e.url);
		        active = that._activeTopLevelSection;
		        cur = active && active.get('link');

		        if(cur && -1 === next.indexOf(cur)) {
			        Y.log('Unloading section…', 'DEBUG', 'CaseEditorAppInterview');
			        that.fire('unload', {navigationEvent:e});
		        }
	        },

	        _unloadPrevented : function(e) {
		        e.navigationEvent.preventDefault();
		        this.showOverlay('unsavedChangesOnUnload', {targetLink:this.removeRoot(e.navigationEvent.url)});
	        },

	        /**
	         * This function is called when the users wants to discard unsaved changes and open an other page.
	         *
	         * @param e The dom event which triggered the overlay to call this function. click or keypress
	         * @param overlayPayload Contains the initial load event.
	         * @private
	         */
	        _forceUnload : function(e, overlayPayload) {
		        // return early if return wasn't hit.
		        if( e.keyCode && 13 !== e.keyCode ) {
			        return;
		        }
		        e.halt();
		        overlayPayload.overlay.hide();
		        Y.log('Forcing unload...', 'INFO', "CaseEditorAppInterview");
		        // unload the current view and remove the current section.
		        this.showView(null, null, {callback:function(){
			        this._set('activeSection', null );
			        this.fire('open', {link:overlayPayload.targetLink});
		        }});
	        },


	        _saveOnUnload : function(e, overlayPayload) {
		        var view;
		        // return early if return wasn't hit.
		        if( e.keyCode && 13 !== e.keyCode ) {
			        return;
		        }
		        e.halt();
		        overlayPayload.overlay.hide();
		        Y.log('Save on unload...', 'INFO', "CaseEditorAppInterview");
				// we do not know how to save. This is handled by our sub apps. We only provide some navigation
				// information.
		        view = this.get('activeView');
		        if( view && view.save ) {
			        view.save({link:overlayPayload.targetLink});
		        }
	        }


        }, {

            ATTRS: {
                // configuration of the whole app.
                config : { },

	            // This is the place to store the page state for the whole editor - not just the interview.
	            // The Navigation and all other components will listen to CaseEditor:pageStateChange events to
	            // update their views.
	            pageState : {
		            readOnly : true
	            },

                activeSection : {
                    readOnly : true
                },

	            container: {
                    valueFn: function () {
                        return Y.Node.create('<div id="case-editor"/>');
                    }
                },

                viewContainer : {
                    getter : function(val) {
                        if( !val ) {
                            val = this._renderScaffold();
                            this._set('viewContainer', val);
                        }
                        return val;
                    }
                },

                // we don't want this app to do any routing accidentally.
                // Therefor we are disabling the link selector so that it will never hit.
                linkSelector : {
                    value: ''
                }
            },

            SECTIONS: new Y.SectionList().populateStaticEntries()
        });

    })();

}, '1.0.0', {
    requires:[
        'app',
        'app-header',
        'app-overlays',
        'base-build',
        'case-editor-app-analysis',
		'case-editor-app-taxmeter',
        'case-editor-app-assessment',
        'case-editor-app-back-to-est',
        'case-editor-app-decree',
	    'case-editor-app-filing2',
	    'case-editor-app-checkout',
        'case-editor-app-interview',
        'case-editor-app-search',
        'case-editor-app-summary',
        'case-editor-sidebar',
        'case-editor-views',
		'case-editor-app-interview-link-viewer',
        'dev-reports',
        'interview-errors',
        'interview-focus',
        'interview-help',
	    'login',
	    'print-preview',
	    'registration',
        'smst-config',
        'smst-overlays',
        'spreadsheets',
        'taxnumberdecorator'
    ]
});