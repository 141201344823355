YUI.add('case-editor-app-taxmeter', function (Y) {
	"use strict";

	Y.CaseEditorAppTaxMeter = Y.Base.create('CaseEditorAppTaxMeter', Y.smst.CaseEditorAppBase,
			[Y.AppOverlays], {

		_handler:[],

		handleNavigation:function (navInfo) {
			var that = this;

			Y.log('handleNavigation', 'DEBUG', 'CaseEditorAppTaxMeter');

			//console.log("Starting tax meter...");

            var cfg = {
                on: {
                    success: function(id, o) {
                        var data = Y.JSON.parse(o.responseText);

                        var template = Y.HandlebarsTemplates.lookup('case-editor-taxmeter-main');
                        template = Y.Node.create(
                        	template(
                        		{
									result:data.curResult,
									allResults: data.allResults,
                                    mayShowSmartPay: Y._currentState.mayShowSmartPay,
									isVerified: data.isVerified
                        		}
                        	)
						);

                        that.get('container').setHTML(template);

                        if(Y.one("#case-editor-main-controls")) {
                            Y.one("#case-editor-main-controls").setStyle('display', 'none');
                        }

                    },
                    failure: function() {
                        Y.log("Request failed or was aborted",'warn','CaseEditorAppTaxMeter');
                    }
                },
                context: that
            };

            Y.io("api/hints", cfg);
		},

		destructor:function () {
            Y.smst.Lang.detachEventListener(this._handler);
		},

	}, {
		ATTRS:{

			container:{
				valueFn:function () {
					return Y.Node.create('<div id="case-editor-taxview"/>');
				}
			}
		}
	});


}, '1.0.0', {
	requires:[
		'app',
		'base-build',
		'case-editor-app-base',
		'io-base',
		'node',
		'smst-lang'
	]});