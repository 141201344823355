YUI.add('analysis-category-tab', function(Y) {

    var
            REPORTS_REPORTS_CLASS = '.case-editor-analysis-report-selector-reports-list',
            REPORTS_CATEGORIES_CLASS = '.case-editor-analysis-report-selector-categories-list',
            REPORTS_NO_CATEGORIES = 'case-editor-analysis-report-selector-no-categories';

    var CategoryTab = function( cfg ) {
        CategoryTab.superclass.constructor.apply(this, arguments);
    };

    Y.mix( CategoryTab, {
        NAME: "categorytab",
        ATTRS: {
            categories: {
                value: [],
                validator: Y.Lang.isArray
            },
            reports: {
                value: [],
                validator: Y.Lang.isArray
            }
        }
    });

    Y.extend( CategoryTab, Y.Tab, {

        _handler: [],

        _listTemplate: '<ul></ul>',

        _listEntryTemplate: '<li data-id="{id}" data-type="{type}" data-sessionId="{sessionId}"><span>{label}</span></li>',

        _listEntryEmptyReport: '<li class="case-editor-analysis-report-selector-reports-list-empty-note">Diese Kategorie enth\u00E4lt keine Berichte.</li>',

        renderUI : function() {
            var instance = this,
                panel;

	        CategoryTab.superclass.renderUI.apply(instance);

            panel = instance.get('panelNode');
	        panel.appendChild('<ul class="case-editor-analysis-report-selector-categories-list"></ul>');
	        panel.appendChild('<ul class="case-editor-analysis-report-selector-reports-list"></ul>');
        },

        /**
         * Overwrite to listen to category and report changes.
         */
        bindUI : function() {
            var instance = this;

	        CategoryTab.superclass.bindUI.apply(instance);

	        instance._handler.push( instance.after('categoriesChange', instance._afterCategoriesChanged, instance ));
            instance._handler.push( instance.after('reportsChange', instance._afterReportsChanged, instance ));
        },

        destructor : function() {
            var instance = this;
            while( 0 < instance._handler.length ) {
                instance._handler.pop().detach();
            }
        },

        _afterCategoriesChanged: function(e) {
            var instance = this,
                list = instance.get('categories'),
                targetNode = instance.get('panelNode').one(REPORTS_CATEGORIES_CLASS),
                reportNode = instance.get('panelNode').one(REPORTS_REPORTS_CLASS);

            instance._updateListNode('categories', list, targetNode);

            // ONSE-1523 if there are no categories mark the report node.
            if( 0 == list.length ) {
                reportNode.addClass( REPORTS_NO_CATEGORIES );
            } else {
                reportNode.removeClass( REPORTS_NO_CATEGORIES );
            }

            if(!Y.CaseEditorAppAnalysis.firstReportRendered && !Y.one('.active')) {
                try {
                    Y.CaseEditorAppAnalysis.allowOneSimulatedClick = true;
                    Y.one('.case-editor-analysis-report-selector-categories-list').one('li').simulate('click');
                }
                catch(err) {
                    Y.log("No default category found", 'warn', 'CategoryTab');
                }
                //Y._reportsInitialized = true;
            }

        },


        _afterReportsChanged: function(e) {
            var instance = this,
				targetNode = instance.get('panelNode').one(REPORTS_REPORTS_CLASS),
                list = instance.get('reports');

            this._updateListNode('reports', list, targetNode);

            // if there are no reports add a placeholder
            if( 0 == list.length ) {
                //top-categories provide helptext if selected

                var hasActiveSubCategory = false;
                Y.Array.each(instance.get('categories'), function(c) {
                    if( c.active ) {
                        if(c.id > 0) {
                            hasActiveSubCategory = true;
                        }
                    }
                });
                if(hasActiveSubCategory) {
                    targetNode.append( instance._listEntryEmptyReport );
                }
                else {

                    var contentNode = Y.one('#content_'+instance.get('label'));
                    if(contentNode != null) {
	                    targetNode.append( contentNode.get('innerHTML') );
                    }
                }
            }
            else if(!Y.CaseEditorAppAnalysis.firstReportRendered) {
                try {
                    Y.CaseEditorAppAnalysis.allowOneSimulatedClick = true;
                    Y.one('.case-editor-analysis-report-selector-reports-list').one('li').simulate('click');
                }
                catch(err) {
                    Y.log("No default report found", 'warn', 'CategoryTab');
                }
            }
        },


        _updateListNode: function(listName, list, node) {
            var ctx = this;

            node.setContent('');
            ctx._renderList(list, node, listName);
        },

        _renderList : function(list, node, listName) {
            Y.Array.each(list, function(c) {

                var entry = Y.Node.create( Y.substitute(this._listEntryTemplate, c) );
                if( c.active ) {
                    entry.addClass('active');
                }
	            if( c.mandatory ) {
		            entry.addClass('case-editor-analysis-mandatory');
	            }
                node.append( entry );
                if( c.open && c[listName] ) {
                    if( 0 < c[listName].length ) {
                        entry.addClass('open')
                    }
                    var innerNode = Y.Node.create(this._listTemplate);
                    entry.append( innerNode );
                    this._renderList(c[listName], innerNode, listName);
                }
            }, this);
        },

        updateCategories : function( result, catId ) {
            var ctx = this,
                categories = ctx.get('categories');

            // tab content changed
            if( -1 == catId ) {
                ctx.set('categories', result);
            } else {
                // sub category updated.
                ctx._updateList( categories, result, catId, 'categories');
                ctx._afterCategoriesChanged();
            }
        },

        updateReports : function( result ) {
            this.set('reports', result);
        },

        _updateList : function( curList, newList, id, param) {
            Y.Array.each( curList, function(c){
                c.active = false;
                if(c.id == id) {
                    c.active = true;
                    c.open = !c.open;
                    c[param] = newList;
                } else if ( c[param] ) {
                    this._updateList( c[param], newList, id, param );
                }
            }, this);
        }

    });

    Y.namespace("analysis").CategoryTab = CategoryTab;


// --------------------------------------------------------------------------------------------------------------------

    /**
     * Plugin for pulling the data for each Tab in and render it.
     */
    var CategoryTabDataPlugin = function( cfg ) {
	    // Chain the constructors
	    CategoryTabDataPlugin.superclass.constructor.apply(this, arguments);
	};

    Y.mix( CategoryTabDataPlugin, {
        NAME: "categoryTabData",
        NS: 'data',
        ATTRS : {
            datasource: {
                value : null
            },
            catId : {
                validator: Y.Lang.isNumber
            },
            schema_reports : {
                value : null
            }
        }
    });

    Y.extend( CategoryTabDataPlugin, Y.Plugin.Base, {

        update : function(id) {
            var ctx = this,
                src = ctx.get('datasource'),
                host = ctx.get('host'),
                mainId =  ctx.get('catId'),
                requestId =  id || mainId,
                schema = ctx.get('schema_reports');

            src.once("response", function(e, schema, cid){
                var ctx = this,
                    data = e,
                    catId = data.response.meta.categoryId == cid ? -1 : data.response.meta.categoryId;

                ctx.updateCategories(data.response.results, catId); // automatisch geparsed
                ctx.updateReports( Y.DataSchema.JSON.apply( schema, data.data.responseText).results, catId );

                if(Y.CaseEditorAppAnalysis.firstReportRendered) {
                    Y.one('#case-editor-analysis').setStyle('pointer-events', 'auto');
                    Y.one('#case-editor-analysis').setStyle('opacity', '1.0');
                }

            }, host, schema, mainId);

            if(Y.CaseEditorAppAnalysis.firstReportRendered) {
                Y.one('#case-editor-analysis').setStyle('pointer-events', 'none');
                Y.one('#case-editor-analysis').setStyle('opacity', '0.2');
            }

            src.sendRequest({request:'categoryId='+requestId});
        }
    } );

    Y.namespace("analysis").CategoryTabDataPlugin = CategoryTabDataPlugin;



}, '1.0.0', { requires:[
	'base-build',
    'datasource-io',
	'datasource-jsonschema',
    'node-event-simulate',
	'tabview',
	'substitute'
]} );