
YUI.add('case-editor-app-decree', function(Y) {
    "use strict";

	Y.CaseEditorAppDecreeIntroductionView = Y.Base.create('CaseEditorAppDecreeIntroductionView', Y.HandlebarsView, [], {

		template : 'case-editor-decree-introduction'

	}, {
		ATTRS: {
			container: {
				valueFn : function(){
					return Y.Node.create('<div id="case-editor-decree-preview" />');
				}
			}
		}
	});

	Y.CaseEditorAppDecreeView = Y.Base.create('CaseEditorAppDecreeView', Y.HandlebarsView, [], {

		template : 'case-editor-decree-view'

	}, {
		ATTRS: {
			container: {
				valueFn : function(){
					return Y.Node.create('<div id="case-editor-decree-view" />');
				}
			},
            dataList: {},
            boUser: {}
		}
	});

	Y.CaseEditorAppDecreeObjectionView = Y.Base.create('CaseEditorAppDecreeObjectionView', Y.HandlebarsView, [], {

		template : 'case-editor-decree-objection'

	}, {
		ATTRS: {
			container: {
				valueFn : function(){
					return Y.Node.create('<div id="case-editor-decree-objection" />');
				}
			},
            smartsteuer: {
                getter: function() {
                    return Y._config.smartsteuer;
                }
            }
		}
	});

    Y.CaseEditorAppDecreePaywallView = Y.Base.create('CaseEditorAppDecreePaywallView', Y.HandlebarsView, [], {

        template : 'case-editor-decree-paywall'

    }, {
        ATTRS: {
            container: {
                valueFn : function(){
                    return Y.Node.create('<div id="case-editor-decree-paywall" />');
                }
            },
            dataList: {},
            boUser: {}
        }
    });



    Y.CaseEditorAppDecree = Y.Base.create('CaseEditorAppDecree', Y.smst.CaseEditorAppBase, [Y.AppOverlays], {

        _runningRequests : [],
        _config: null,

	    views: {
		    introduction: {type:'CaseEditorAppDecreeIntroductionView'},
		    view : {type:'CaseEditorAppDecreeView'},
		    objection : {type:'CaseEditorAppDecreeObjectionView'},
            paywall : {type:'CaseEditorAppDecreePaywallView'}
	    },
	    overlays : {
	    },
	    events: {
		    '.ui-case-editor-decree-intro-next' : {click: function() {
			    this.fire('open', {link:Y.smst.CaseEditor.SECTIONS.getByName('decree-decree-mc').get('link')});
		    }},
            '.ui-case-editor-decree-view-mc-next' : {click: function() {
                this.fire('open', {link:Y.smst.CaseEditor.SECTIONS.getByName('decree-decree-fc').get('link')});
            }},
            '.ui-case-editor-decree-view-fc-next' : {click: function() {
                this.fire('open', {link:Y.smst.CaseEditor.SECTIONS.getByName('decree-decree-objection').get('link')});
            }},
            '.ui-case-editor-decree-open-analysis' : {click: function() {
                this.fire('open', {link:Y.smst.CaseEditor.SECTIONS.getByName('analysis').get('link')});
            }},

            '.ui-case-editor-decree-open-fa-text' : {click: function() {
                this._toggleNode(Y.one("#ui-case-editor-decree-open-fa-text-container"));
            }},

            '.ui-case-editor-decree-open-temporary-notes' : {click: function() {
                this._toggleNode(Y.one("#ui-case-editor-decree-open-temporary-notes-container"));
            }},

            '.ui-temporary-explanation-toggle' : {click: function(e) {
                this._toggleNode(e.target.ancestor('li').one(".ui-temporary-explanation"));
            }},

            '.ui-toggle-level' : {click: function(e) {
                this._toggleLevel(e);
            }},
            '#ui-print-decree' : {click: function(e) {
                this._print(e);
            }},
            '.ui-open-decree-shop' : {
                click : function(e) {
                    if (window.runningInWebView) {
                        // this means we are running inside of the ipad app and should use IAP, and not redirect in our
                        // store
                        // the control is returned to the app by the click handler below
                        var c = 'premium';
                        window.nativeBridge.getCreditFor(c);
                    } else {
                        var url = Y._config.shopURL+"?year="+Y._config.vz+"&category=premium"+
                            "&shop13ErrorUrl="+encodeURIComponent(window.location.href)+
                            "&shop13ReturnUrl="+encodeURIComponent(window.location.href);

                        window.location.href=url;

                    }
                }
            },
            '#ui-handle-premium-receipt' : { click: function(e) {
                // this 'click' event is triggered by the nativebridge when the IAP handling is done
                this._handlePremiumReceipt(e);
            }},
            '#ui-objection-cta' : { click: function(e) {
                this._fileObjection(e);
            }},
            '#ui-objection-template-select' : { click: function(e) {
                Y.one('#ui-objection-details').setContent(Y.one('#ui-objection-template-select').get('value'));
            }}

	    },

	    initializer : function() {
		    var that = this;

            Y.once('smartsteuer:ready', function(config){this.config = config}, this);

		    that.publish('newSubsection', {
			    context: that,
			    emitFacade: true
		    });

		    // we are going to cancel any running requests when ever a new section is activated.
		    that.on('newSubsection', that._cancelRunningRequests, that);
	    },

        destructor : function() {
        },

        render: function () {
            this.constructor.superclass.render.apply(this);

            return this;
        },

        _handlePremiumReceipt : function (e) {
            var nb = window.nativeBridge;
            if (!nb) return;
            nb.log('nativeBridge: handlePremiumReceipt');
            e.halt();

            // we check that we are on the correct page
            var elem = Y.one('#ui-handle-premium-receipt');
            if (!elem) return;

            // we grab the params we need and call the controller
            var r = nb.receipt;
            var prod = nb.productType;
            if (!r) return;
            var cfg = {
                sync : true,
                method : 'POST',
                data : 'receipt='+r+'&productType='+prod,
                timeout : 5000
            };
            // goes to ElsterController.java/handleReceipt(...)
            // handling means: if ok, increase credit on user2
            var url = this.config.secureURI('elster/handleReceipt');
            var r8 = r.substring(0,7); // a short version of the receipt for logging
            nb.log('nativeBridge: sending request, receipt: '+r8+'...');
            var req = Y.io(url,cfg);
            // as this was a synchronous request, we get the results here
            if (window.runningInWebView) {
                nb.log('nativeBridge: request returned:');
                nb.log(req);
            }
            var status = req.status;
            var text = req.responseText;
            if (status === 200) {
                nb.outcome = text;
                return false;
            }
            nb.outcome = 'tryagain';
            return false; // supress browser default handler

        },

        _toggleNode : function(node) {
            if(node) {
                if(node.getStyle('display') == 'none') {
                    node.setStyle('display', 'block');
                }
                else {
                    node.setStyle('display', 'none');
                }
            }
        },

        _cancelRunningRequests: function () {
   		    var that = this,
   				requests = that._runningRequests,
   				request;

   		    // reset the global field to not interfere with any ongoing async action.
   		    that._runningRequests = [];

   		    // now clean and cancel any running requests.
   			if(requests) {
   				while(0 < requests.length) {

   					request = requests.pop();
   					// make sure that this is cancelable.
   					request.abort && request.isInProgress() && request.abort();
   				}
   			}
   	    },

	    /**
	     * This will publish new active sections to the case-editor. The information is needed to sync the navigation
	     * accordingly. Also this app and any sub-apps (at this time only case-editor-app-filing2-documents) will cancel
	     * any running requests to speed things up a little and to only show the most recent data.
	     *
	     * @param navInfo
	     * @private
	     */
	    _activateSubSection : function(navInfo){

            if(navInfo.area == "decree-mc" || navInfo.area == "decree-fc" || navInfo.area == "decree-objection") {
                this.fire('newSubSection', {section:'decree-' + navInfo.area});
            }
            else {
                this.fire('newSubSection', {section:'decree'});
            }
	    },

	    // this is our new 'openArea'
        handleNavigation : function(navInfo) {
            Y.log('handleNavigation', 'DEBUG', 'CaseEditorAppDecree');

            var that = this;

            that._activateSubSection(navInfo);

            if(!Y._currentState.maySeeDecreeData) {
                that.showPaywall();
            }
            else if(navInfo.area == "decree-mc") {
                that.show(true);
            }
            else if(navInfo.area == "decree-fc") {
                that.show(false);
            }
            else if(navInfo.area == "decree-objection") {
                that.showObjections();
            }
            else {
                that.showView('introduction');
            }

            if(Y.one("#case-editor-main-controls")) {
                Y.one("#case-editor-main-controls").setStyle('display', 'none');
            }

        },

        showPaywall : function(id,res) {

            this.showView('paywall', {});
        },


        showObjections : function() {

            // before we show the objection view, we have to collect some data
            // so we do some ajax calls and hand the results over to _dataLoaded eventually
            // there more complicated stuff is done before really showing the view
	        Y.io('api/decree/loadObjectionInfo', {
                headers : {
                    "Accept" : "application/json"
                },
                on : {
                    success : function(id,res) {
                        var objectionInfo = Y.JSON.parse(res.responseText);
                        this._loadFilingState(objectionInfo);
                    }
                },
                context: this
            });
        },

        _fileObjection : function() {
	        var data = {
                title : Y.one('#ui-objection-title').get("value").replaceAll(/>/g, '').replaceAll(/</g, ''),
                details : Y.one('#ui-objection-details').get("value").replaceAll(/>/g, '').replaceAll(/</g, '')
            };

	        if(!data.details) {
	            this.fire('alert', {msg: "Bitte geben Sie eine Begründung für den Einspruch ein."});

	            return;
            }

	        //ask for preview
            Y.io('api/decree/objectionPreview', {
                on : {
                    success : function(id1,res1) {
                        //confirm data
                        if(!confirm(
                            "Sind Sie sicher dass Sie den Einspruch abschicken wollen?"
                            +"\n\n"
                            +"Wir übermitteln folgende Informationen an das Finanzamt:"
                            +"\n\n"
                            +res1.responseText.replace(/\\n/g, "\n")
                        )) {
                            return;
                        }

                        //do the actual filing
                        Y.io('api/decree/fileObjection', {
                            headers : {
                                "Accept" : "application/json"
                            },
                            on : {
                                success : function(id,res) {
                                    window.location.reload(true);
                                },
                                failure : function(id, res) {
                                    alert("Es ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.");
                                }
                            },
                            method: "POST",
                            context: this,
                            data : data
                        });
                    },
                    failure : function(id, res) {
                        alert("Es ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.");
                    }
                },
                method: "POST",
                context: this,
                data : data
            });
        },

        _loadFilingState : function(objectionInfo) {
            Y.io('elster/getFilingState', {
                headers : {
                    "Accept" : "application/json"
                },
                on : {
                    success : function(id, res) {
                        var filingState = Y.JSON.parse(res.responseText);
                        this._loadDecreeData(objectionInfo,filingState);
                    }
                },
                context: this
            });
        },

        _loadDecreeData : function(objectionInfo,filingState) {
            Y.io('api/decree/load?showMale=true', {
                headers : {
                    "Accept" : "application/json"
                },
                on : {
                    success : function(id, res) {
                        this._dataLoaded(id, res, objectionInfo, filingState);
                    }
                },
                context: this
            });
        },


        show: function(showMaleCase) {

            this._nextIsFemaleCase = !showMaleCase && Y._currentState.transferDecreeData2;

	        Y.io('api/decree/load?showMale='+showMaleCase, {
                headers : {
                    "Accept" : "application/json"
                },
                on : {
                    success : Y.bind(this._dataLoaded, this)
                }
            });
        },

        _dataLoaded : function(id, res, objectionInfo, filingState) {
            // 'res' is response from api/decree/load

            //the default view has to set the correct header and content
            if(!objectionInfo) {
                this.fire('sectionMeta', {
                    title: 'Einkommensteuer ' +
                    (this._nextIsFemaleCase ? Y._currentState.femaleName : Y._currentState.maleName),
                    help: null,
                    image: null
                });

                if (res.responseText == "") {
                    Y.one('#case-decree').setContent('<p>Dieser Bescheid liegt (noch) nicht vor.</p>');
                    return;
                }
                else {
                    Y.one('#case-decree').setContent('');
                }
            }

            var data = Y.JSON.parse(res.responseText), temporary = [];

            Y.Array.each(data, function(it, idx) {
                if(it.label == "DATE") {
                    this._createDate = it.value;
                    this._idxD = idx;
                }
            }, this);

            data.splice(this._idxD, 1);

            Y.Array.each(data, function(it, idx) {
                if(it.label == "ASSESSMENT") {
                    this._assessment = null;

                    if(it.onseValue == "gv") {
                        this._assessment = "Der Bescheid wird auf Basis der Einzelveranlagung für Ehegatten mit individueller Aufteilung verglichen.";
                    }
                    else if(it.onseValue == "gv50") {
                        this._assessment = "Der Bescheid wird auf Basis der Einzelveranlagung für Ehegatten mit 50%-Regel verglichen.";
                    }
                    else {
                        this._assessment = "Der Bescheid wird bei Verheirateten auf Basis der Zusammenveranlagung verglichen, bei Ledigen auf Basis der Einzelveranlagung.";
                    }

                    this._idxD = idx;
                }
            }, this);

            data.splice(this._idxD, 1);

            Y.Array.each(data, function(it, idx) {
                if(it.label == "EXPLANATION") {
                    this._explanation = it.value;
                    this._idxD = idx;
                }
            }, this);

            data.splice(this._idxD, 1);

            //Y.log(this._explanation);

            Y.Array.each(data, function(it, idx) {

                if(it.label == "ADDRESS") {
                    this._faData = it.value;
                    this._idxF = idx;
                }

                if(it.sortIndex >= 1000) {
                    if(it.sortIndex % 1000 != 0) {
                        it.level2 = true;
                    }
                    else {
                        Y.Array.each(data, function(it2) {
                            if(it2.sortIndex > it.sortIndex && it2.sortIndex < (it.sortIndex+1000)) {
                                it.openLevel = true;
                            }
                        }, this);
                    }
                }

                /*
                if(it.number == '0650000613') {
                    it.diff = "-111.00";
                }
                */

                if(it.diff && it.diff != 0) {
                    it.hasDiff = true;
                }

                it.hasMZIMismatch = (it.onseValue &&
                                     it.onseValue.indexOf("[") == 0 &&
                                     it.onseValue.indexOf("]") > 0);

                if(it.number != "0650000946" && (it.value || it.onseValue)) {

                    //TODO: unit tests
                    if(it.number == "0680000850") {
                        //it.value = "-100,11"; it.onseValue = "-110,11"; it.diff = "10,00"; //payback, negative (red)
                        //it.value = "-110,11"; it.onseValue = "-100,11"; it.diff = "10,00"; //payback, positive (green)
                        //it.value = "100,11"; it.onseValue = "110,11"; it.diff = "10,00"; //no payback, negative (green)
                        //it.value = "110,11"; it.onseValue = "100,11"; it.diff = "10,00"; //no payback, positive (red)
                        //it.value = "0,00"; it.onseValue = "0,00"; it.diff = "0,00"; //neutral (green)
                    }

                    if(it.value && it.onseValue) {
                        if(Y.Number.parse(it.value.replace(',', '.')) > Y.Number.parse(it.onseValue.replace(',', '.'))) {
                           it.diffPositive = !it.diffPositive;
                        }
                    }
                    else if(it.onseValue) {
                        it.diffInvalid = true;
                    }

                    //console.log(it);

                    if(it.value) {
                        this._origValue = Y.Number.parse(it.value.replace(',', '.'));

                        it.value = Y.Number.format(Y.Number.parse(it.value.replace(',', '.')), {decimalPlaces: 2, decimalSeparator: ',', thousandsSeparator: '.', suffix: ''});
                    }

                    if(it.onseValue) {
                        it.onseValue = Y.Number.format(Y.Number.parse(it.onseValue.replace(',', '.')), {decimalPlaces: 2, decimalSeparator: ',', thousandsSeparator: '.', suffix: ''});
                    }

                    if(it.diff) {
                        it.diff = Y.Number.format(Y.Number.parse(it.diff.replace(',', '.')), {decimalPlaces: 2, decimalSeparator: ',', thousandsSeparator: '.', suffix: ''});

                        if(it.diff == "0,00") {
                            it.diff = "-";
                        }
                    }

                    if(it.number == "0680000850") {
                        this._isPayback = this._origValue < 0;

                        it.endResult = true;
                        it.red = false;

                        this._faCalculation = it.value != null ? it.value.replace('-', '') : null;

                        if(it.diff && it.diff != "0,00" && it.diff != "-") {
                            this._hasDifference = true;

                            if(it.diffPositive) {
                                this._smstIsMore = false;
                                this._calculationDiff = it.diff;
                                this._smstCalculation = it.onseValue.replace('-', '');
                            }
                            else {
                                this._smstIsMore = true;
                                this._calculationDiff = it.diff;
                                this._smstCalculation = it.onseValue.replace('-', '');

                                it.red = true;
                            }
                        }
                        else {
                            this._hasDifference = false;
                            this._smstIsMore = false;
                        }
                    }


                    if(it.onseField == "VORL" && (it.value == "1,00" || it.value == "2,00" || it.value == "3,00")) {
                        temporary.push(it);
                    }

                    if(it.openCategory == 'LEER') {
                        it.emptyCategory = true;
                    }

                }

            }, this);

            data.splice(this._idxF, 1);

            //default view
            if(!objectionInfo) {
                this.showView('view', {
                    dataList: data,
                    payback: this._isPayback,
                    calculationDiff: this._calculationDiff,
                    smstCalculation: this._smstCalculation,
                    faCalculation: this._faCalculation,
                    smstIsMore: this._smstIsMore,
                    boUser: this.config.boUser,
                    nextIsFemaleCase: this._nextIsFemaleCase,
                    createDate: this._createDate,
                    faData: this._faData,
                    bavarianFa : this._faData.indexOf("Bayerisches Landesamt") >= 0,
                    hasDifference: this._hasDifference,
                    temporary: temporary,
                    explanation: this._explanation,
                    assessment: this._assessment
                });
            }
            else {
                //objection page
                //console.log(!this._hasDifference || !this._smstIsMore);

                var contains = function(data, str) {
                    return Y.Array.some(data, function(it) {
                        if(it == str) {
                            return it;
                        }
                    }, this);
                };

                var currentTicketId = null;

                Y.Array.some(objectionInfo, function(it) {
                    if(it.indexOf("OBJECTION_FILED_") == 0) {
                        currentTicketId = it.substring("OBJECTION_FILED_".length);
                    }
                }, this);


                this.showView('objection', {
                    hasSparzulage: contains(objectionInfo, 'EXT_SPARZULAGE'),
                    hasZinsen: contains(objectionInfo, 'EXT_ZINSEN'),
                    hasSaeumnis: contains(objectionInfo, 'EXT_ZUSCHLAG_SAEUMNIS'),
                    hasVerspaetung: contains(objectionInfo, 'EXT_ZUSCHLAG_VERSPAETUNG'),
                    hasVorlaeufigkeit: contains(objectionInfo, 'VORL'),
                    showNPS: (!this._hasDifference || !this._smstIsMore),
                    showObjection: this._hasDifference && contains(objectionInfo, "OBJECTION_POSSIBLE"),
                    objectionId: currentTicketId,
                    vz: Y._config.vz,
                    filingState: filingState
                });

                if(Y.one('#ui-decree-objection-download')) {
                    Y.one('#ui-decree-objection-download').set('href', Y._config.secureURI("api/decree/downloadObjection"));
                }
            }
        },

        _toggleLevel : function(e) {
            var idx = parseInt(e.target.ancestor('tr').getAttribute('data-sortIndex'));

            Y.all('tr.level2').each(function(it) {
                var si = parseInt(it.getAttribute('data-sortIndex'));

                if(si > idx && si < (idx+1000)) {

                    if(it.getStyle('display') == 'none') {
                        it.setStyle('display', 'table-row');
                    }
                    else {
                        it.setStyle('display', 'none');
                    }
                }
            }, this);
        },

        _print : function(e) {

            e.halt();

            var sheet = "<style type=\"text/css\">"+
                ".tooltip { display: none; }"+
                "body {font-family: Arial, Helvetica, Sans-Serif;}"+
                "body .btn {display: none;}"+
                ".case-editor-tax-assessment-tooltip {display: none;}"+
                "table {border-spacing: 0; border-collapse: collapse;}"+
                "table td, table th {padding: 8px;}"+
                ".case-editor-tax-assessment-result-type {position: relative; width: 40px;}"+
                ".case-editor-tax-assessment-result-type .fa {position: absolute; top: 50%; -moz-transform: translateY(-50%); -ms-transform: translateY(-50%); -webkit-transform: translateY(-50%);transform: translateY(-50%);left:0;right:0;display:inline-block;vertical-align:top;line-height:100%;}"+
                ".case-editor-tax-assessment-temporary-notes__item {margin-top: 12px; line-height: 1; min-height: 0 !important;}"+
                ".case-editor-tax-assessment-temporary-notes__item h3 {margin: 0; font-weight: normal;}"+
                "table, td, th {border: 1px solid #b3b8bc;}"+
                "#ui-print-decree, .ui-case-editor-decree-open-fa-text {display: none;}"+
                ".case-editor-tax-status {background: #eef1f4; border: 1px solid #dedede; padding: 20px; font-weight: bold; line-height: 1.45;}"+
                ".case-editor-tax-status em {font-style: normal;}"+
                ".case-editor-tax-assessment-temporary-notes {margin: 0 0 30px; padding: 0 0 0 40px; font-size: 13px;}"+
                ".case-editor-tax-assessment-temporary-notes .btn {display: none;}"+
                "tr var {display: none;}"+
                ".case-editor-tax-basic-calculation {margin-top: 35px;}"+
                "caption, th { text-align: left; }"+
                ".case-editor-tax-assessment-result-type span {display: initial;}"+
                ".level2 div { margin-left: 30px; }"+
                "h2 + p {margin-top: 0;}"+
                "h2 {margin-bottom: 5px;}"+
                "tbody th { font-weight: normal; }"+
                "tbody th ~ td {text-align: center;}"+
                ".hasDiff { color: #FF1500; }"+
                ".noDiff { color: #88CC00; }"+
                "a { text-decoration: normal; color:#000000; }"+
                "</style>";

            window.frames["ui-print-frame"].document.write(sheet);
            window.frames["ui-print-frame"].document.write(Y.one('#ui-case-editor-tax-assessment-print-content').get('innerHTML'));
            window.frames["ui-print-frame"].document.close();
            window.frames["ui-print-frame"].window.focus();

            var result = window.frames["ui-print-frame"].document.execCommand("print", false, null);
            if(!result) {
                window.frames["ui-print-frame"].print();
            }

        }

    }, {
        ATTRS : {

            container: {
                valueFn: function(){
                    return Y.Node.create('<div id="case-decree"/>');
                }
            },
	        caseId      : { readOnly: true }

        }
    });


}, '1.0.0', {
    requires:[
        'app',
        'base-build',
	    'smart-handlebars-templates',
        'case-editor-app-base',
        'io-base',
		'json-parse',
        'node',
        'smst-lang'
    ]});
