// collection of all views which handle the representation of any of the case model classes.
YUI.add('case-editor-app-filing2-documents', function(Y) {
    "use strict";

    Y.CaseEditorAppFiling2DocumentsDownloadView = Y.Base.create('CaseEditorAppFiling2DocumentsDownloadView',
        Y.HandlebarsView, [], {

        template: 'case-editor-filing2-documents-download',
        partials : ['case-editor-filing2-notSentWarning',
            'case-editor-filing2-notSentViaMailWarning',
            'case-editor-filing2-receiptsNotOkWarning',
            'case-editor-filing2-notSignedWarning',
            'case-editor-filing2-elsterNotAvailable']
    }, {
        ATTRS: {
            filingState: {},
	        hasEuer: {
		        getter: function () {
	                return Y._currentState && Y._currentState.hasEuer;
			    }
	        }
        }
    });

    // this one inherits from the above one
    Y.CaseEditorAppFiling2DocumentsPrintView = Y.Base.create('CaseEditorAppFiling2DocumentsPrintView',
        Y.CaseEditorAppFiling2DocumentsDownloadView, [], {

        template: 'case-editor-filing2-documents-print',
        param : 'printed',

        initializer: function() {
            this.get('container').delegate('click',this._handleClick,'input',this);
        },

	    _handleClick : function(e) {
            var cont = this.get('container');
            var tmpNode = cont.one('input');

            var checked = tmpNode.get('checked');
            var button = cont.one('#ui-go-to-filing2-sign');
            var pathFilled = true;

            if(!button) {
                button = cont.one('#ui-go-to-filing2-receipts');
                if(!this.get('filingState').documentsPrinted) pathFilled = false;
            }
            if(!button) {
                button = cont.one('#ui-go-to-filing2-send');
                if(!this.get('filingState').documentsPrinted) pathFilled = false;
                if(!this.get('filingState').documentsSigned) pathFilled = false;
            }
            if(!button) {
                button = cont.one('#ui-go-to-filing2-final');
                if(!this.get('filingState').documentsPrinted) pathFilled = false;
                if(!this.get('filingState').documentsSigned) pathFilled = false;
                if(!this.get('filingState').receiptsOk) pathFilled = false;
            }

            if(!button) {
                button = cont.one('#ui-go-to-filing2-smartpay');
                if(!this.get('filingState').documentsPrinted) pathFilled = false;
                if(!this.get('filingState').documentsSigned) pathFilled = false;
                if(!this.get('filingState').receiptsOk) pathFilled = false;
            }

		    if (checked && pathFilled) {
                Y.io('elster/saveInterviewSessionParam?' + this.param + '='+checked, {context:this});

			    button.removeClass('btn-disabled');
		    } else {
			    button.addClass('btn-disabled');
		    }
	    }
    });

    Y.CaseEditorAppFiling2DocumentsSignView = Y.Base.create('CaseEditorAppFiling2DocumentsSignView',
        Y.CaseEditorAppFiling2DocumentsPrintView, [], {
        // we extend the print view (above) and just use another template
        template: 'case-editor-filing2-documents-sign',
        param : 'signed'

    });

    Y.CaseEditorAppFiling2DocumentsReceiptsView = Y.Base.create('CaseEditorAppFiling2DocumentsReceiptsView',
        Y.CaseEditorAppFiling2DocumentsPrintView, [], {
        // we extend the print view (above) and just use another template
        template: 'case-editor-filing2-documents-receipts',
        param : 'receiptsOk',

        initializer: function() {
            this.get('container').delegate('click',this._print,'#ui-print-receipts',this);
        },

        _print : function(e) {

            e.halt();

            window.frames["ui-print-frame"].document.write("<h3>Belegliste</h3>");
            window.frames["ui-print-frame"].document.write(Y.one('#ui-case-editor-receipts-print-content').get('innerHTML'));
            window.frames["ui-print-frame"].document.close();
            window.frames["ui-print-frame"].window.focus();

            var result = window.frames["ui-print-frame"].document.execCommand("print", false, null);
            if(!result) {
                window.frames["ui-print-frame"].print();
            }

        }
    }, {
        ATTRS: {
            receipts: {
                getter: function () {
                    var text = Y.UserCase.getValueFromCase("OF0011199", "Standard", 1);
                    var receipts = [];

                    if(text && text.value && text.value != "") {
                        receipts = text.value.split("\n\n");
                    }

                    return receipts;
                }
            },
            receiptsPresent: {
                getter: function () {
                    var text = Y.UserCase.getValueFromCase("OF0011199", "Standard", 1);

                    return text && text.value && text.value != "";
                }
            }
        }
    });

    Y.CaseEditorAppFiling2DocumentsSendView = Y.Base.create('CaseEditorAppFiling2DocumentsSendView',
        Y.CaseEditorAppFiling2DocumentsPrintView, [], {
        // we extend the print view (above) and just use another template
        template: 'case-editor-filing2-documents-send',
        param : 'sent'
    });


    Y.CaseEditorAppFiling2Documents = Y.Base.create('CaseEditorAppFiling2Documents',
        Y.smst.CaseEditorAppBase, [Y.AppOverlays], {
	    views: {
            download : {type:Y.CaseEditorAppFiling2DocumentsDownloadView},
            print : {type: Y.CaseEditorAppFiling2DocumentsPrintView},
            sign : {type: Y.CaseEditorAppFiling2DocumentsSignView},
            receipts : {type: Y.CaseEditorAppFiling2DocumentsReceiptsView},
            send : {type: Y.CaseEditorAppFiling2DocumentsSendView}
	    },

        getLink : function (name) {
             return Y.smst.CaseEditor.SECTIONS.getByName(name).get('link');
        },


        events: {
            '#ui-go-to-filing2-print': {click: function (e) {
                this.isDisabled(e) || this.fire('open', {link:this.getLink('filing2-process-documents/print')});
            }},
            '#ui-go-to-filing2-sign': {click: function (e) {
                var that = this;

                if(that.isDisabled(e)) {
                    return;
                }

                if(!that.get('filingState').sentWithElsterII) {
                    //normal flow
                    that.fire('open', {link: this.getLink("filing2-process-documents/sign")});
                }
                else {
                    //skip
                    Y.io('elster/saveInterviewSessionParam', {
                        context: this,
                        on: {
                            success: function (id, o) {
                                that.fire('open', {link: this.getLink("filing2-process-documents/receipts")});
                            }
                        },
                        data: {
                            printed: 'true',
                            signed: 'true',
                            receiptsOk: 'false',
                            sent: 'false'
                        }
                    });
                }

            }},
            '#ui-go-to-filing2-receipts': {click: function (e) {
                this.isDisabled(e) || this.fire('open', {link: this.getLink("filing2-process-documents/receipts")});
            }},
            '#ui-go-to-filing2-send': {click: function (e) {
                this.isDisabled(e) || this.fire('open', {link:this.getLink("filing2-process-documents/send")});
            }},
            '#ui-go-to-filing2-smartpay': {click: function (e) {
                this.isDisabled(e) || this.fire('open', {link:this.getLink("filing2-process-smartpay")});
            }},
            '#ui-go-to-filing2-final': {click: function (e) {
                this.isDisabled(e) || this.fire('open', {link:this.getLink("filing2-process-final")});
            }}
        },

        isDisabled : function(e) {
            if(e.currentTarget.hasClass('btn-disabled')) {
                this.fire('alert', {msg: "Bitte führen Sie alle Schritte in der angegebenen Reihenfolge durch und setzen Sie die entsprechenden Haken."});
                return true;
            }
            else {
                return false;
            }
        },

        overlays: {
        },

        handleNavigation : function(navInfo) {
            var app = this;
            var formId = navInfo.formId;
            var data = {
                filingState : app.get('filingState'),
                filingLink :app.getLink('filing2-process-filing'),
                receiptsLink : app.getLink('filing2-process-documents/receipts'),
                signLink : app.getLink('filing2-process-documents/sign'),
                sendLink : app.getLink('filing2-process-documents/send'),
                mayShowSmartPay : Y._currentState.mayShowSmartPay
            };

            app.fire('removeWaiting');
            app.fire('waiting', {msg: 'Bitte warten...'});

            if (formId == 'print') {
                app.showView('print', data);
            } else if (formId == 'sign') {
                app.showView('sign', data);
            } else if (formId == 'receipts') {
                app.showView('receipts', data);
            } else if (formId == 'send') {
                app.showView('send', data);
            } else {
                app.showView('download',data);
            }
            app.fire('removeWaiting');
	    }
    }, {
        ATTRS : {

            container: {
                valueFn: function(){
                    return Y.Node.create('<div id="case-filing-documents"/>');
                }
            },
	        filingState : {}
        }
    });


}, '1.0.0', {
    requires:[
        'app',
        'base-build',
	    'smart-handlebars-templates',
        'case-editor-app-base',
        'io-base',
		'json-parse',
        'node',
        'smst-lang'
    ]});
