/**
 * This will display all required appendix of the current case.
 */
YUI.add('analysis-appendix-tab', function(Y) {

	"use strict";

	var protos, statics,
		NAME = 'AppendixTab',
		LI_TEMPLATE = '<li><a data-id="{id}" data-payload="{data}">{caption}</a></li>';


	statics = {
		NAME: NAME,
		ATTRS: {

		}
	};

	protos = {

		_handler:null,

		initializer: function() {
			this.set('label', 'Tabellen');
		},

		bindUI: function() {
			this.constructor.superclass.bindUI.apply(this,arguments);
			this._handler = this.after('selectedChange', function(e){
				if(e.newVal) {
					this._renderSpreadsheets();
				}
			}, this);
		},

		_renderSpreadsheets: function() {
            var cfg = {
                on: {
                    success: function(id, o) {
                        var content;
                        var sheets = Y.UserCase.getSpreadsheets();

                        Y.UserCase.setEUERSpreadsheets(Y.JSON.parse(o.responseText));

                        sheets = sheets.concat(Y.UserCase.getEUERSpreadsheets());

                        if( 0 < sheets.length ) {
                            var list = Y.Node.create('<ul class="case-editor-analysis-report-selector-appendix-list"></ul>');
                            Y.Array.each( sheets, function(sheet){
                                this.append(Y.Lang.sub(LI_TEMPLATE, sheet));
                            },list);
                            content = list;
                        } else {
                            // fallback
                            content = '<div id="case-editor-analysis-report-selector-appendix-list-empty-note">Dieser Steuerfall enth\u00E4lt keine Tabellen.</div>'
                        }

                        this.set('content',content);
                        this.get('panelNode').show();
                    },
                    failure: function() {
                        Y.log("Request failed or was aborted",'warn','Appendix');
                    }
                },
                context: this
            };

            Y.io("reports/euersheets", cfg);
		},

		destructor: function() {
			this._handler && this._handler.detach();
		}
	};

	Y.namespace('analysis').AppendixTab = Y.Base.create(NAME, Y.Tab, [], protos, statics );

}, '1.0.0' ,{
	requires:[
		'node',
		'recordset-base',
		'tabview',
		'user-case'
	],
	skinnable : false // skinning takes place via onse-analysis-css
});


